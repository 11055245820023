import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { createStyles, makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./AddUser.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { TextField, MenuItem, FormControl } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
import { connect } from "react-redux";
import {
  createUserDataAPICall,
  getAddUserDataAPICall,
  getManagerDataAPICall,
  setTargetDataAPICall,
  updateUserDataAPICall,
} from "../../actions";
import { dashboardEntity, dashboardUI } from "../../reducers";
import EditUser from "./EditUser";
import SetTarget from "./SetTarget";
import { toast } from "react-toastify";

const AddUser = (props: any) => {
  const {
    createUserDataAPICall,
    getAddUserDataAPICall,
    getAddUserData,
    isGettingAddUserData,
    isCreateUserDataDone,
    isUpdateUserDataDone,
    isSetTargetDataDone,
    getManagerNameDataAPICall,
    getManagerNameData,
    createUserData,
    updateUserData,
    setTargetData,
    resetCreateUser,
    resetUpdateUser,
    resetSetTarget,
  } = props;

  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [isTargetDialogOpen, setIsTargetDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState("");
  const role = localStorage.getItem("role");
  useEffect(() => {
    getAddUserDataAPICall();
    getManagerNameDataAPICall();
  }, [isCreateUserDataDone, isUpdateUserDataDone, isSetTargetDataDone]);

  useEffect(() => {
    if (isCreateUserDataDone) {
      if (createUserData?.error?.length) {
        toast.error(createUserData?.message);
      } else {
        toast.success(createUserData?.message);
      }
      setIsAddUserDialogOpen(false);
      resetCreateUser();
      formik.resetForm();
    } else if (isUpdateUserDataDone) {
      if (updateUserData?.error?.length) {
        toast.error(updateUserData?.message);
      } else {
        toast.success(updateUserData?.message);
      }
      setIsEditUserDialogOpen(false);
      resetUpdateUser();
    } else if (isSetTargetDataDone) {
      if (setTargetData?.error?.length) {
        toast.error(setTargetData?.message);
      } else {
        toast.success(setTargetData?.message);
      }
      setIsTargetDialogOpen(false);
      resetSetTarget();
    }
  }, [isCreateUserDataDone, isUpdateUserDataDone, isSetTargetDataDone]);

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      datepicker: {
        margin: "0px 0 0px 10px !important",
        width: "170px",
        borderRadius: "20px",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#add8e6",
        margin: "5px",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "100% !important",
        wordBreak: "break-word",
      },
      progressBar: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      // password: "",
      target: "",
      user_role: "",
      manager: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string(),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // password: Yup.string()
      //   .min(6, "Password must be at least 6 characters")
      //   .required("Password is required"),
      target: Yup.number()
        .typeError("Target must be a number")
        .min(0, "Target must be a positive number")
        .required("Target is required"),
      user_role: Yup.string(),
      manager: Yup.string(),
    }),
    onSubmit: (values, event: any) => {},
  });

  const handleAddUserDialogOpen = () => {
    setIsAddUserDialogOpen(true);
  };

  const handleAddUserDialogClose = () => {
    setIsAddUserDialogOpen(false);
    formik.resetForm();
    setSelectedRole("");
  };

  const handleEditUserDialogOpen = (user: any) => {
    setSelectedUser(user);
    setIsEditUserDialogOpen(true);
  };

  const handleEditUserDialogClose = () => {
    setIsEditUserDialogOpen(false);
  };

  const handleTargetDialogOpen = (user: any) => {
    setSelectedUser(user);
    setIsTargetDialogOpen(true);
  };

  const handleTargetDialogClose = () => {
    setIsTargetDialogOpen(false);
  };

  const handleSubmit = () => {
    let Userdata = {
      first_name: formik.values?.first_name,
      last_name: formik.values?.last_name,
      email: formik.values?.email,
      target: formik.values?.target,
      user_role: formik.values?.user_role,
      managers_id:   formik.values?.manager,
    };
    if (!formik.isValid || formik.isSubmitting) {
      createUserDataAPICall(Userdata);
      handleAddUserDialogClose();
    }
    formik.resetForm();
  };

  const handleRoleChange = (event: any) => {
    const role = event.target.value;
    setSelectedRole(role);
    formik.handleChange(event);
  };

  return (
    <Paper sx={{ margin: "10px", minHeight: "300px" }}>
      {isGettingAddUserData && <div className="overlay" />}
      {isGettingAddUserData && (
        <CircularProgress color="primary" className={classes.progressBar} />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          className={classes.headingtxt}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          User Details
        </Typography>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="primary"
            className={classes.addLead}
            sx={{ borderRadius: "20px", margin: "10px" }}
            onClick={() => handleAddUserDialogOpen()}
          >
          <AddOutlinedIcon sx={{ height: '60%' }} />  
          Add User
          </Button>
        </div>
      </div>
      <div
        style={{
          margin: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1",
          minHeight: "360px",
        }}
      >
        <TableContainer sx={{ marginBottom: "10px", width: "100%" }}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
            >
              <TableRow>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Name</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Email</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Target</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Role</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Status</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getAddUserData?.data?.user?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data Available
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                getAddUserData?.data?.user?.map((user: any, index: any) => (
                  <TableRow
                    key={index}
                    sx={{ background: "#ffffff" }}
                  >
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "0px 16px !important" }}
                    >
                      {user.name ? user.name : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "0px 16px !important" }}
                    >
                      {user.email ? user.email : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "0px 16px !important" }}
                    >
                      {user.target ? "$" : ""}
                      {user.target ? user.target : "-"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "0px 16px !important" }}
                    >
                      {user.user_role ? user.user_role : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "0px 16px !important" }}
                    >
                      <span style={{ color: user.active ? "green" : "red" }}>
                        {user.active ? "Active" : "Inactive"}
                      </span>
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "0px 16px !important" }}
                    >
                      <button
                        onClick={() => handleEditUserDialogOpen(user)}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <Tooltip title="Edit User">
                          <EditOutlinedIcon
                            sx={{ marginTop: "5px", color: "#4CAF50" }}
                            className={classes.actionbtn}
                          />
                        </Tooltip>
                      </button>
                      <button
                        onClick={() => handleTargetDialogOpen(user)}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <Tooltip title="Set Target">
                          <CrisisAlertOutlinedIcon
                            sx={{ marginTop: "5px", marginLeft: "5px" }}
                            className={classes.actionbtn}
                          />
                        </Tooltip>
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        open={isAddUserDialogOpen}
        onClose={handleAddUserDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "40%",
            maxWidth: "40%",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          Add User
          <IconButton
            aria-label="close"
            onClick={handleAddUserDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div>
                  <TextField
                    fullWidth
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    sx={{ marginTop: "10px", marginBottom: "15px" }}
                    InputLabelProps={{ style: { top: "-6px" } }}
                    InputProps={{ sx: { borderRadius: 20 } }}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <TextField
                    fullWidth
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    required={false}
                    sx={{ marginTop: "10px", marginBottom: "15px" }}
                    InputLabelProps={{ style: { top: "-6px" } }}
                    InputProps={{ sx: { borderRadius: 20 } }}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                </div>
              </Grid>
            </Grid>

            <div>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                sx={{ marginTop: "0", marginBottom: "15px" }}
                InputLabelProps={{
                  style: { top: "-6px" },
                }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            {/* <div>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                sx={{ marginTop: "0", marginBottom: "15px" }}
                InputLabelProps={{
                  style: { top: "-6px" },
                }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </div> */}
            {role === "admin" && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.user_role &&
                        Boolean(formik.errors.user_role)
                      }
                    >
                      <TextField
                        select
                        label="Role"
                        id="user_role"
                        name="user_role"
                        sx={{ marginTop: "0", marginBottom: "15px" }}
                        InputLabelProps={{
                          style: { top: "-6px" },
                        }}
                        InputProps={{ sx: { borderRadius: 20 } }}
                        value={formik.values.user_role}
                        onChange={handleRoleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                        <MenuItem value="Sales Executive">
                          Sales Executive
                        </MenuItem>
                      </TextField>
                      {formik.touched.user_role && formik.errors.user_role && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.user_role}
                        </div>
                      )}
                    </FormControl>
                  </div>
                </Grid>
                {selectedRole === "Sales Executive" && (
                  <Grid item xs={6}>
                    <div>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.user_role &&
                          Boolean(formik.errors.user_role)
                        }
                      >
                        <TextField
                          select
                          label="Select Manager"
                          id="manager"
                          name="manager"
                          sx={{ marginTop: "0", marginBottom: "15px" }}
                          InputLabelProps={{
                            style: { top: "-6px" },
                          }}
                          InputProps={{ sx: { borderRadius: 20 } }}
                          value={formik.values.manager}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {getManagerNameData?.data?.map((data: any) => (
                            <MenuItem key={data.id} value={data.id}>
                              {data.managerName}
                            </MenuItem>
                          ))}
                        </TextField>
                        {formik.touched.manager && formik.errors.manager && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {formik.errors.manager}
                          </div>
                        )}
                      </FormControl>
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Button
                className={classes.cancelbtn}
                // variant='contained'
                color="primary"
                onClick={() => handleAddUserDialogClose()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  borderRadius: "20px",
                  fontFamily: "Montserrat-SemiBold",
                  fontSize: "14px",
                }}
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isEditUserDialogOpen}
        onClose={handleEditUserDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "40%",
            maxWidth: "40%",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          Edit User
          <IconButton
            aria-label="close"
            onClick={handleEditUserDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <EditUser
            user={selectedUser}
            handleEditUserDialogClose={handleEditUserDialogClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isTargetDialogOpen}
        onClose={handleTargetDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "30%",
            maxWidth: "30%",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          {/* Set Target  */}
          {selectedUser
            ? `Set Target for ${selectedUser.first_name}`
            : "Set Target"}
          <IconButton
            aria-label="close"
            onClick={handleTargetDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <SetTarget
            user={selectedUser}
            handleTargetDialogClose={handleTargetDialogClose}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAddUserData: dashboardEntity.getDashboard(state).getAddUserData,
    isGettingAddUserData: dashboardUI.getDashboard(state).isGettingAddUserData,
    createUserData: dashboardEntity.getDashboard(state).createUserData,
    updateUserData: dashboardEntity.getDashboard(state).updateUserData,
    setTargetData: dashboardEntity.getDashboard(state).setTargetData,
    getManagerNameData: dashboardEntity.getDashboard(state).getManagerData,
    isCreateUserDataDone: dashboardUI.getDashboard(state).isCreateUserDataDone,
    isUpdateUserDataDone: dashboardUI.getDashboard(state).isUpdateUserDataDone,
    isSetTargetDataDone: dashboardUI.getDashboard(state).isSetTargetDataDone,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    createUserDataAPICall: (data: any) =>
      dispatch(createUserDataAPICall.request(data)),
    getAddUserDataAPICall: (data: any) =>
      dispatch(getAddUserDataAPICall.request(data)),
    getManagerNameDataAPICall: (data: any) =>
      dispatch(getManagerDataAPICall.request(data)),
    resetCreateUser: () => dispatch(createUserDataAPICall.reset()),
    resetUpdateUser: () => dispatch(updateUserDataAPICall.reset()),
    resetSetTarget: () => dispatch(setTargetDataAPICall.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
