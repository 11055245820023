import { Box, createStyles, makeStyles } from '@material-ui/core'
import BounceStatus from '../components/EmailDashboard/BounceStatus'

const useStyles = makeStyles(() => {
  return createStyles({
    mainContainer: {
      height: '95%',
      width: '100%',
    },
  })
})

const EmailDashboard = () => {
  const classes = useStyles()
  return (
    <>
      <Box className='pages-container'>
        <BounceStatus />
      </Box>
    </>
  )
}

export default EmailDashboard
