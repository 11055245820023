import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  RESET,
  GET_CONTACTS_DATA,
  CREATE_CONTACTS_DATA,
  GET_CONTACT_DETAILS_DATA,
  UPLOAD_CONTACTS_FILE
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE,  } = actionTypes

const ui = () => {
  const isGettingContactsData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_CONTACTS_DATA[SUCCESS]:
        return false
      case GET_CONTACTS_DATA[REQUEST]:
        return true
      case GET_CONTACTS_DATA[FAILURE]:
        return false
      case GET_CONTACTS_DATA[RESET]:
        return false
      default:
        return state
    }
  }
  const isGettingContactsDetailsData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_CONTACT_DETAILS_DATA[SUCCESS]:
        return false
      case GET_CONTACT_DETAILS_DATA[REQUEST]:
        return true
      case GET_CONTACT_DETAILS_DATA[FAILURE]:
        return false
      case GET_CONTACT_DETAILS_DATA[RESET]:
        return false
      default:
        return state
    }
  }
    const isCreateContactsDataDone = (state = false, action: actions) => {
        switch (action.type) {
          case CREATE_CONTACTS_DATA[SUCCESS]:
            return true
          case CREATE_CONTACTS_DATA[FAILURE]:
          case CREATE_CONTACTS_DATA[REQUEST]:
          case CREATE_CONTACTS_DATA[RESET]:  
            return false
          default:
            return state
        }
      }
      const isCreatingContactsData = (state = false, action: actions) => {
        switch (action.type) {
          case CREATE_CONTACTS_DATA[SUCCESS]:
            return false
          case CREATE_CONTACTS_DATA[REQUEST]:
            return true
          case CREATE_CONTACTS_DATA[FAILURE]:
            return false
          case CREATE_CONTACTS_DATA[RESET]:
            return false
          default:
            return state
        }
      }

      const isUploadContactsFileDone = (state = false, action: actions) => {
        switch (action.type) {
          case UPLOAD_CONTACTS_FILE[SUCCESS]:
            return true
          case UPLOAD_CONTACTS_FILE[FAILURE]:
          case UPLOAD_CONTACTS_FILE[REQUEST]:
          case UPLOAD_CONTACTS_FILE[RESET]:  
            return false
          default:
            return state
        }
      }
      const isUploadingContactsData = (state = false, action: actions) => {
        switch (action.type) {
          case UPLOAD_CONTACTS_FILE[SUCCESS]:
            return false
          case UPLOAD_CONTACTS_FILE[REQUEST]:
            return true
          case UPLOAD_CONTACTS_FILE[FAILURE]:
            return false
          case UPLOAD_CONTACTS_FILE[RESET]:
            return false
          default:
            return state
        }
      }

  return combineReducers({
    isCreateContactsDataDone,
    isCreatingContactsData,
    isUploadContactsFileDone,
    isUploadingContactsData,
    isGettingContactsData,
    isGettingContactsDetailsData,
  })
}

export default ui

export const getContacts = (state: RootState) => state.ui.contact
