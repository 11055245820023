import * as React from 'react'
import {
  Dialog,
  CircularProgress,
} from '@mui/material'
import TrackEmail from '../Leads/Leads'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { loaderProps } from '../Common/CommonStyles'
import { RootState } from '../../configureStore'

function MasterSetUp({ loaderState }: any) {
  
  const navigate = useNavigate()

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div style={{ width: '100%', backgroundColor:'#D3D3D3' }}>
          <div style={{ padding: '12px' }}>
              <TrackEmail/>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    // homePageDetails: dashboardEntity.getDashboard(state).getHomePageDetails,
    // loaderState: dashboardUI.getDashboard(state).isHomePageData,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    // getHomeDetails: () => {
    //   dispatch(getHomePageDetails.request())
    // },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterSetUp)
