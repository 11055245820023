import { combineReducers } from 'redux'
import entities, * as Entities from './entities'
import ui, * as UI from './ui'

export const leadsDataEntity = Entities.leadsState
export const analyticsDataEntity = Entities.analyticsState
export const campaignDataEntity = Entities.campaignState
export const contactDataEntity = Entities.contactState

export const dashboardEntity = Entities.dashboardState

export const userAuthenticationEntity = Entities.userAuthenticationState
export const loginUserEntity = Entities.loginUserState

export const dashboardUI = UI.dashboardState
export const leadDataUI = UI.leadsState
export const analyticsDataUI = UI.analyticsState
export const campaignDataUI = UI.campaignsState
export const contactDataUI = UI.contactsState
export const userAuthenticationUI = UI.userAuthenticationState
export const loaderStateUI = UI.loaderState
export const loginUserUI = UI.loginUserState



const reducers = () =>
  combineReducers({
    entities,
    ui,
  })
export default reducers
