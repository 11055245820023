import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  FETCH_USERS,
  SEND_EMAIL,
  SEND_EMAIL_CAMPAIGN,
  UPLOAD_FILE,
  CREATE_LEAD_DATA,
  UPDATE_LEAD_DATA,
  GET_FOLLOW_UP_DATA_BY_ID,
  CREATE_FOLLOW_UP_DATA,
  GET_EMAIL_TRACK_DATA,
  CREATE_USER_DATA,
  UPDATE_USER_DATA,
  CHANGE_PASSWORD_DATA,
  GET_ADD_USER_DATA,
  GET_MANAGER_DATA,
  EVENT_CALENDAR_DATA,

  GET_EMAIL_DETAILS_DATA,
  GET_EMAIL_DASHBOARD_DATA,
  GET_EMAIL_GRAPH_DATA,

  RESET,
  BULK_EMAIL_DATA,
  SET_TARGET_DATA,
} from '../../actions/actiontypes'
const { SUCCESS, REQUEST, FAILURE, GET_USER_DATA } = actionTypes

const entity = () => {

  const getUserData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_USERS[SUCCESS]:
        return action.payload
      case FETCH_USERS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const createUserData = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_USER_DATA[SUCCESS]:
        return action.payload
      case CREATE_USER_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const updateUserData = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_USER_DATA[SUCCESS]:
        return action.payload
      case UPDATE_USER_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const changePasswordData = (state = [], action: actions) => {
    switch (action.type) {
      case CHANGE_PASSWORD_DATA[SUCCESS]:
        return action.payload
      case CHANGE_PASSWORD_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const eventCalendarData = (state = [], action: actions) => {
    switch (action.type) {
      case EVENT_CALENDAR_DATA[SUCCESS]:
        return action.payload
      case EVENT_CALENDAR_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getAddUserData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_ADD_USER_DATA[SUCCESS]:
        return action.payload
      case GET_ADD_USER_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getManagerData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_MANAGER_DATA[SUCCESS]:
        return action.payload
      case GET_MANAGER_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const setTargetData = (state = [], action: actions) => {
    switch (action.type) {
      case SET_TARGET_DATA[SUCCESS]:
        return action.payload
      case SET_TARGET_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const sendEmailData = (state = {}, action: actions) => {
    switch (action.type) {
      case SEND_EMAIL[SUCCESS]:
        return action.payload
      case SEND_EMAIL[REQUEST]:
      case SEND_EMAIL[RESET]:
        return {}
      case SEND_EMAIL[FAILURE]:
        return action.payload
      default:
        return state
    }
  }

  const sendEmailCampaignData = (state = {}, action: actions) => {
    switch (action.type) {
      case SEND_EMAIL_CAMPAIGN[SUCCESS]:
        return action.payload
      case SEND_EMAIL_CAMPAIGN[REQUEST]:
      case SEND_EMAIL_CAMPAIGN[RESET]:
        return {}
      case SEND_EMAIL_CAMPAIGN[FAILURE]:
        return action.payload
      default:
        return state
    }
  }

  const getSendBulkEmailData = (state = {}, action: actions) => {
    switch (action.type) {
      case BULK_EMAIL_DATA[SUCCESS]:
        return action.payload
      case BULK_EMAIL_DATA[REQUEST]:
      case BULK_EMAIL_DATA[RESET]:
        return {}
      case BULK_EMAIL_DATA[FAILURE]:
        return action.payload
      default:
        return state
    }
  }

  const uploadFile = (state = {}, action: actions) => {
    switch (action.type) {
      case UPLOAD_FILE[SUCCESS]:
        return action.payload
      case UPLOAD_FILE[REQUEST]:
      case UPLOAD_FILE[RESET]:
      case UPLOAD_FILE[FAILURE]:
        return {}
      default:
        return state
    }
  }

  const createLeadData = (state = {}, action: actions) => {
    switch (action.type) {
      case CREATE_LEAD_DATA[SUCCESS]:
        return action.payload
      // case CREATE_LEAD_DATA[REQUEST]:
      // case CREATE_LEAD_DATA[RESET]:
      // case CREATE_LEAD_DATA[FAILURE]:
      //   return {}
      default:
        return state
    }
  }

  const updateLeadData = (state = {}, action: actions) => {
    switch (action.type) {
      case UPDATE_LEAD_DATA[SUCCESS]:
        return action.payload
      // case CREATE_LEAD_DATA[REQUEST]:
      // case CREATE_LEAD_DATA[RESET]:
      // case CREATE_LEAD_DATA[FAILURE]:
      //   return {}
      default:
        return state
    }
  }

  const getFollowUpDataById = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_FOLLOW_UP_DATA_BY_ID[SUCCESS]:
        return action.payload
      case GET_FOLLOW_UP_DATA_BY_ID[REQUEST]:
      case GET_FOLLOW_UP_DATA_BY_ID[RESET]:
      case GET_FOLLOW_UP_DATA_BY_ID[FAILURE]:
        return {}
      default:
        return state
    }
  }

  const isUpdateFollowUpDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_FOLLOW_UP_DATA[SUCCESS]:
        return true
      case CREATE_FOLLOW_UP_DATA[FAILURE]:
      case CREATE_FOLLOW_UP_DATA[REQUEST]:
        return false
      case CREATE_FOLLOW_UP_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isCreateFollowUpData = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_FOLLOW_UP_DATA[SUCCESS]:
        return true
      case CREATE_FOLLOW_UP_DATA[REQUEST]:
      case CREATE_FOLLOW_UP_DATA[RESET]:
      case CREATE_FOLLOW_UP_DATA[FAILURE]:
        return false
      default:
        return state
    }
  }

  const getEmailTrackData = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_EMAIL_TRACK_DATA[SUCCESS]:
        return action.payload
      case GET_EMAIL_TRACK_DATA[REQUEST]:
      case GET_EMAIL_TRACK_DATA[RESET]:
      case GET_EMAIL_TRACK_DATA[FAILURE]:
        return {}
      default:
        return state
    }
  }

  const getEmailDetailsData = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_EMAIL_DETAILS_DATA[SUCCESS]:
        return action.payload
      case GET_EMAIL_DETAILS_DATA[REQUEST]:
      case GET_EMAIL_DETAILS_DATA[RESET]:
      case GET_EMAIL_DETAILS_DATA[FAILURE]:
        return {}
      default:
        return state
    }
  }

  const getEmailDashboardData = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_EMAIL_DASHBOARD_DATA[SUCCESS]:
        return action.payload
      case GET_EMAIL_DASHBOARD_DATA[REQUEST]:
      case GET_EMAIL_DASHBOARD_DATA[RESET]:
      case GET_EMAIL_DASHBOARD_DATA[FAILURE]:
        return {}
      default:
        return state
    }
  }
  const getEmailGraphData = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_EMAIL_GRAPH_DATA[SUCCESS]:
        return action.payload
      case GET_EMAIL_GRAPH_DATA[REQUEST]:
      case GET_EMAIL_GRAPH_DATA[RESET]:
      case GET_EMAIL_GRAPH_DATA[FAILURE]:
        return {}
      default:
        return state
    }
  }

  return combineReducers({
    getUserData,
    setTargetData,
    createUserData,
    updateUserData,
    changePasswordData,
    eventCalendarData,
    getAddUserData,
    getManagerData,
    sendEmailData,
    uploadFile,
    getFollowUpDataById,
    isUpdateFollowUpDataDone,
    isCreateFollowUpData,
    createLeadData,
    updateLeadData,
    getEmailTrackData,
    getEmailDetailsData,
    getEmailDashboardData,
    getEmailGraphData,
    getSendBulkEmailData,
    sendEmailCampaignData,
  })
}

export default entity

export const getDashboard = (state: RootState) => state.entities.dashboard
