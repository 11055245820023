import { put } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import { addAuthToken, addOAuthJSONToken } from '../services/rest'
import { toast } from 'react-toastify'
const { SUCCESS, FAILURE } = actionTypes

export function* sendPayload(apiResponse: any, event: { [x: string]: any }) {
  if (
    (event[SUCCESS] === 'LOGIN_USER_SUCCESS' || event[SUCCESS] === 'GET_ACCESS_TOKEN_SUCCESS') &&
    apiResponse.data.token
  ) {
    localStorage.setItem('token', apiResponse.data.token)
    localStorage.setItem('userId', apiResponse.data.user.id)
    localStorage.setItem('name', apiResponse.data.user.name)
    localStorage.setItem('role', apiResponse.data.user.user_role)
    localStorage.setItem('preference', JSON.stringify(apiResponse.data.user.preference))
    // localStorage.setItem('refresh_token', apiResponse.data.token)
    // let expireTime = apiResponse.data.data.expires_in
    // localStorage.setItem('token_expire_time', JSON.stringify([Date.now(), expireTime]))
    addAuthToken()
    addOAuthJSONToken()
    // setTimeout(() => {
    //   const event = new CustomEvent('Access_Token_Event', {
    //     detail: { message: 'Please request for access token.' },
    //   })
    //   document.dispatchEvent(event)
    // }, expireTime * 1000)
  } else{
  }
  // if (event[SUCCESS] === 'USER_INFO_SUCCESS') {
  //   localStorage.setItem('is_assignable', apiResponse.data.data.is_assignable)
  // }
  yield put({
    type: apiResponse ? event[SUCCESS] : event[FAILURE],
    payload: apiResponse ? (apiResponse ? apiResponse.data : apiResponse.data.error) : {},
  })
}

export function* sendPayloadFailure(error: any, event: { [x: string]: any }) {
  if (error?.response?.status === 401) {
    toast.error(error.response.data.message ?? 'UnAuthorized User')
    setTimeout(function () {
      localStorage.clear()
      window.location.href = '/'
    }, 4000)
  }
  if (error?.response) {
    toast.error(error?.response?.data?.detail ?? error?.response?.data.message)

    yield put({
      type: event[FAILURE],
      payload: error.response ? error.response : {},
    })
  } else {
    if (error.error) {
      yield put({
        type: event[FAILURE],
        payload: error.error,
      })
    } else {
      toast.error('Something went wrong')
      setTimeout(function () {
        // window.location.reload()
      }, 4000)
    }
  }
}
