import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { connect } from "react-redux";
import {
  getAddUserDataAPICall,
  getManagerDataAPICall,
  setTargetDataAPICall,
} from "../../actions";
import { dashboardEntity } from "../../reducers";

const SetTarget = (props: any) => {
  const { setTargetDataAPICall, user, handleTargetDialogClose } = props;  

  const currentMonth = moment().month(); // Get the current month (0-indexed)
  let financialYearStartMonth = 3; // Financial year starts in April (0-indexed)
  let financialYearStartYear = moment().year();
  if (currentMonth < financialYearStartMonth) {
    // If the current month is before April, financial year starts in the previous year
    financialYearStartYear -= 1;
  }

  const financialYearStartDate = moment()
    .year(financialYearStartYear)
    .month(financialYearStartMonth)
    .startOf("month")
    .format("YYYY-MM-DD");

  const financialYearEndDate = moment()
    .year(financialYearStartYear + 1)
    .month(financialYearStartMonth - 1)
    .endOf("month")
    .format("YYYY-MM-DD");

  const [selectedFromDate, setSelectedFromDate] = useState(
    financialYearStartDate
  );
  const [selectedToDate1, setSelectedToDate] = useState(financialYearEndDate);
  const [userId, setUserId] = useState(user?.id || "");

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      disabled: {
        cursor: 'not-allowed'
    },
      datepicker: {
        width: "170px",
        borderRadius: "20px",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#add8e6",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 4% !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "10% !important",
        wordBreak: "break-word",
      },
    });
  });

  const classes = useStyles();

  const currentDate = selectedFromDate
    ? new Date(selectedFromDate)
    : new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDateSelected = dayjs(formattedCurrentDate);

  const toDateToday = selectedToDate1 ? new Date(selectedToDate1) : new Date();
  const formattedToDateToday = toDateToday.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDateSelected = dayjs(formattedToDateToday);

  const handleFromDateChange = (e: any) => {
    setSelectedFromDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleToDateChange = (e: any) => {
    setSelectedToDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const formik = useFormik({
    initialValues: {
      target: user.target || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      target: Yup.number()
        .typeError("Target must be a number")
        .min(0, "Target must be a positive number")
        .required("Target is required"),
    }),
    onSubmit: (values, event: any) => {},
  });

  const handleSubmit = () => {
    let Userdata = {
      startDate: selectedFromDate,
      endDate: selectedToDate1,
      target: formik.values?.target,
      usersId: userId,
      user_role: user.user_role,
    };
    if (Userdata) {
      setTargetDataAPICall(Userdata);
    }
  };

  const getErrorText = (error: any): string | undefined => {
    return typeof error === "string" ? error : undefined;
  };

  return (
    <>
      <div
        className={classes.datepickercontainer}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {/* <Typography className={classes.subheading}>From: </Typography> */}
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box className={classes.datepicker}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": { height: "20px" }
                  }}
                // sx={{borderRadius:'20px'}}
                  value={fromDateSelected}
                  label="From Date"
                  onChange={handleFromDateChange}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        {/* <Typography className={classes.subheading}>To: </Typography> */}
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box className={classes.datepicker}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": { height: "20px" }
                  }}
                  label="To Date"
                  value={toDateSelected}
                  onChange={handleToDateChange}
                  minDate={fromDateSelected}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{marginTop:'5px'}}>
              <TextField
                fullWidth
                id="target"
                name="target"
                label="Target ($)"
                sx={{ marginTop: "10px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.target}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.target && Boolean(formik.errors.target)}
                helperText={
                  formik.touched.target
                    ? getErrorText(formik.errors.target)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
                className={classes.cancelbtn}
                // variant='contained'
                color="primary"
                onClick={() => handleTargetDialogClose()}
              >
                Cancel
              </Button>
              <span style={{ cursor: "not-allowed" }}>
          <Button
            sx={{
              borderRadius: "20px",
              fontFamily: "Montserrat-SemiBold",
              fontSize: "14px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          </span>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAddUserData: dashboardEntity.getDashboard(state).getAddUserData,
    getManagerNameData: dashboardEntity.getDashboard(state).getManagerData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    setTargetDataAPICall: (data: any) =>
      dispatch(setTargetDataAPICall.request(data)),
    getManagerNameDataAPICall: (data: any) =>
      dispatch(getManagerDataAPICall.request(data)),
    getAddUserDataAPICall: (data: any) =>
      dispatch(getAddUserDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetTarget);

