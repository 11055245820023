import { combineReducers } from 'redux'
import * as leadsState from '../entities/Leads'
import * as analyticsState from '../entities/analytics'
import * as campaignState from '../entities/campaign'
import * as contactState from '../entities/contact'
import * as dashboardState from './dashboard'
import * as userAuthenticationState from './userAuthentication'
import * as loginUserState from './loginUser'



export {

  leadsState,
  analyticsState,
  campaignState,
  contactState,
  dashboardState,
  userAuthenticationState,
  loginUserState,

}

export default combineReducers({

  dashboard: dashboardState.default(),
  leads: leadsState.default(),
  analytics: analyticsState.default(),
  campaign: campaignState.default(),
  contact: contactState.default(),
  userAuthentication: userAuthenticationState.default(),
  loginUser: loginUserState.default(),

})
