import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Dashboard from './components/Dashboard/Dashboard'
import ProtectedRoute from './auth/ProtectedRoute'
import Login from './components/Pages/Login'
import { Box } from '@mui/material'
import 'react-toastify/dist/ReactToastify.css'
import '../src/style.css'
import ForgotPassword from './components/Pages/ForgotPassword'
import MasterSetUp from './components/Dashboard/MasterSetUp'
import EmailDashboard from './Pages/EmailDashboard'
import TrackEmail from './Pages/TrackEmail'
import Addlead from './components/Leads/Addlead'
import EditLead from './components/Leads/EditLead'
import DealsClosed from './components/DealsClosed/DealsClosed'
import Analytics from './components/Analytics/Analytics'
import AddUser from './components/AddUser/AddUser'
import Campaign from './components/Campaign/Campaign'
import Contacts from './components/Contacts/Contacts'

function App() {

  const location = useLocation()
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const role = localStorage.getItem('role');
  const path = location.pathname

  //This useEffect Is an event lister that is responsible for page reload when user clears local storage.
  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === null) {
        window.location.reload()
      }
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (path === '/' && token) {
      navigate('/home/dashboard')
    }
  }, [path])

  return (
    <Box height={'100%'} width={'100%'}>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/home' element={<ProtectedRoute component={<Dashboard />} />}>          

          
          <Route path='/home' element={<MasterSetUp />} />
          <Route path='dashboard' element={<MasterSetUp />} />
          <Route path='dashboard/addlead' element={<Addlead />} />
          <Route path='dashboard/editlead/:id' element={<EditLead />} />
          <Route path='dealClosed' element={<DealsClosed />} />          
          <Route path='emailStatus' element={<TrackEmail />} />
          <Route path='bounceStatus' element={<EmailDashboard />} />
          <Route path='contacts' element={<Contacts/>} />
          <Route path='analytics' element={<Analytics />} />
          <Route path='campaign' element={<Campaign />} />                
          {/* {(role === 'sales_manager' || role === 'admin') && <Route path='analytics' element={<Analytics />} />} */}
          {(role === 'sales_manager' || role === 'admin') && <Route path='addUser' element={<AddUser />} />}
          <Route path='*' element={<Navigate to="/home" />} />

        </Route>
        <Route path='/site/forgotPassword' element={<ForgotPassword />} />
      </Routes>
    </Box>
  )
}

export default App
