import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const colorStops = [
  [0.1, '#ff0000'],  // Red
  [0.2, '#ff3300'],  // Orange-Red
  [0.3, '#ff6600'],  // Orange
  [0.4, '#ff9900'],  // Yellow-Orange
  [0.5, '#ffcc00'],  // Yellow
  [0.6, '#ccff00'],  // Yellow-Green
  [0.7, '#99ff00'],  // Lime
  [0.8, '#66ff00'],  // Bright Lime
  [0.9, '#33ff00'],  // Bright Green
  [1.0, '#00ff00']   // Green
];

const TargetChart = (props: any) => {
  const {
    chartId, 
    data,             
  } = props;

  useEffect(() => {
    const myChart = echarts.init(document.getElementById(chartId));
    
    const option = {
      tooltip: {
        formatter: function(params:any) {
            return `
                <div>
                <p style="font-size: 12px; margin:0px">
                <span style=
                "color: #000000;
                font-family: Montserrat-SemiBold;
                ">Target Achieved:</span> 
                <span style=
                "color: #000000;
                font-family: Montserrat-Medium;
                ">$${data?.dealSize}</span></p>
                <p style="font-size: 12px; margin: 0px">
                <span style=
                "color: #000000;
                font-family: Montserrat-SemiBold;
                ">Target:</span> 
                <span style=
                "color: #000000;
                font-family: Montserrat-Medium;
                ">$${data?.target}</span></p>
                </div>
            `;
        }
      },
      series: [
        {
          type: 'gauge',
          max: data?.target,
          axisLine: {
            lineStyle: {
              width: 10,
              color: colorStops,
            }
          },
          pointer: {
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            distance: -30,
            length: 8,
            lineStyle: {
              color: '#fff',
              width: 0
            }
          },
          splitLine: {
            distance: -30,
            length: 30,
            lineStyle: {
              color: '#fff',
              width: 4
            }
          },
          axisLabel: {
            color: 'black',
            distance: -50,
            fontSize: 11,
            formatter: function(value:any) {
              return `$${Math.round(value)}`; 
            }
          },
          detail: {
            valueAnimation: true,
            fontSize: 18,
            formatter: function(value:any) {
              return `$${value}`; 
            },
            color: 'inherit',
          },
          data: [
            {
              value: data?.dealSize
            }
          ]
        }
      ]
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [chartId, data]);

  return <div id={chartId} style={{ width: '100%', height: '280px', marginTop: '20px' }}></div>;
};

export default TargetChart;
