import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { 
  GET_CONTACTS_DATA,
  CREATE_CONTACTS_DATA,
  UPLOAD_CONTACTS_FILE,
  GET_CONTACT_DETAILS_DATA,
 } from '../actions/actiontypes'

const { REQUEST,  } = actionTypes


//write sagas function

  function* handleGetContactsDataAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getContactData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_CONTACTS_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_CONTACTS_DATA)
    }
  }

  function* handleCreateContactsDataAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.createContactsData,
        data.data,
      )
      yield sendPayload(apiResponse, CREATE_CONTACTS_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, CREATE_CONTACTS_DATA)
    }
  }

  function* handleUploadContactsFileAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getUploadContactsFile,
        data.data,
      )
      yield sendPayload(apiResponse, UPLOAD_CONTACTS_FILE)
    } catch (e) {
      yield sendPayloadFailure(e, UPLOAD_CONTACTS_FILE)
    }
  }
  function* handleGetContactDetailsDataAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getContactDetailsData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_CONTACT_DETAILS_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_CONTACT_DETAILS_DATA)
    }
  }

export const sagas = {
  //watcher come here
  watchGetContactsDataAPI: takeLatest(actionTypes.GET_CONTACTS_DATA[REQUEST], handleGetContactsDataAPI),
  watchCreateContactsDataAPI: takeLatest(actionTypes.CREATE_CONTACTS_DATA[REQUEST], handleCreateContactsDataAPI),
  watchUploadContactsFileAPI: takeLatest(actionTypes.UPLOAD_CONTACTS_FILE[REQUEST], handleUploadContactsFileAPI),
  watchGetContactDetailsDataAPI: takeLatest(actionTypes.GET_CONTACT_DETAILS_DATA[REQUEST], handleGetContactDetailsDataAPI),
}
