import * as Yup from 'yup'
const phoneRegex = /^(?:(\+91[ -]?)|)(?!0{10})[0-9]{10}$/
const digitsOnly = (value: any) => /^\d+$/.test(value)
// const digitsOnlyFinanceAcceptMinus = (value:any) => /^-?\d+$/.test(value);
const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
const panNumber = (value: any) => panRegex.test(value)

export const newLeadsFormSchema: any = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name!')
    .required('Please enter your name!'),
  email: Yup.string().email('Invalid email!').required('Please enter your email!'),
  mobile: Yup.string()
    .matches(phoneRegex, 'Please provide valid phone number!')
    .required('Please enter phone number!'),
  watsupNo: Yup.string()
    .matches(phoneRegex, 'Please provide valid whatsapp number!')
    .required('Please enter whatsapp number!'),
  dateOfBirth: Yup.string().required('Please select your date of birth!'),
  contactPerson: Yup.string()
    .matches(phoneRegex, 'Please provide valid phone number!')
    .required('Please enter phone number!'),
  addressLine1: Yup.string().required('Please enter applicant permanent address!'),
  addressLine2: Yup.string().required('Please enter applicant permanent address!'),
  addressLine3: Yup.string().required('Please enter applicant permanent address!'),
  state: Yup.string().required('Please select state!'),
  city: Yup.string().required('Please select city!'),
  pinCode: Yup.string()
    .required('Please enter pin code!')
    .matches(/^\d{6}$/, 'Pincode must be a 6-digit numeric code')
    .nullable(),
  companyName: Yup.string().required('Please enter company name!'),
  profession: Yup.string().required('Please select profession!'),
  isPrefered: Yup.string().required('Please select is prefered!'),
  monthlyIncome: Yup.string()
    .required('Please enter property agreement value!')
    .test('Digits only', 'Enter numbers only!', digitsOnly),

  mainStatus: Yup.string().required('Please select main status!'),
  subStatus: Yup.string().required('Please select sub status!'),
  assignTo: Yup.string().required('Please select assign to field!'),

  brokerName: Yup.string().required('Please select broker name!'),
  bank: Yup.string().required('Please select bank!'),
  remark: Yup.string().required('Please enter remark!'),
  designation: Yup.string().required('Please select designation!'),
  panNo: Yup.string()
    .test('Digits only', 'Please enter valid applicant pan card number!', panNumber)
    .required('Please enter pan number!'),
  leadType: Yup.string().required('Please select load type!'),
  product: Yup.string().required('Please select product!'),
  source: Yup.string().required('Please select source!'),
  loanAmount: Yup.string()
    .test('Digits only', 'Enter numbers only!', digitsOnly)
    .required('Please enter loan amount!'),
  expDisbursalDate: Yup.string().required('Please select exp. disbursal date!'),
  demo: Yup.string().required('Please select demo!'),
  callType: Yup.string().required('Please select call type!'),
  nextDate: Yup.string().required('Please select next date!'),
})

export const newUserFormSchema: any = Yup.object().shape({
  empcode: Yup.string()
    .test('Digits only', 'Enter numbers only!', digitsOnly)
    .required('Please enter emp code!'),
  name: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name!')
    .required('Please enter your name!'),
  email: Yup.string().email('Invalid email!').required('Please enter your email!'),
  department: Yup.string().required('Please select department!'),
  designation: Yup.string().required('Please select designation!'),
  company: Yup.string().required('Please select company!'),
  reporting_auth: Yup.string().required('Please select reporting auth!'),
  address: Yup.string().required('Please enter applicant address!'),
  state: Yup.string().required('Please select state!'),
  city: Yup.string().required('Please select city!'),
  pinCode: Yup.string()
    .required('Please enter pin code!')
    .matches(/^\d{6}$/, 'Pincode must be a 6-digit numeric code')
    .nullable(),
  ctc: Yup.string()
    .test('Digits only', 'Enter numbers only!', digitsOnly)
    .required('Please enter your CTC!'),
  varibale: Yup.string().required('Please enter applicant variable!'),
  mobileno: Yup.string()
    .matches(phoneRegex, 'Please provide valid mobile number!')
    .required('Please enter mobile number!'),
  dob: Yup.string().required('Please your date of birth!'),
  date_of_joinig: Yup.string().required('Please select date of joining!'),
  short_designation: Yup.string().required('Please select short designation!'),

  password: Yup.string().required('Please enter password!'),
})

export const mscLeadsFormSchema: any = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name!')
    .required('Please enter your name!'),

  mobile: Yup.string()
    .matches(phoneRegex, 'Please provide valid phone number!')
    .required('Please enter phone number!'),
  state: Yup.string().required('Please select state!'),
  city: Yup.string().required('Please select city!'),
})

export const emailformSchema: any = Yup.object().shape({
  To: Yup.string()
    .required('Please enter at least one email address!')
    .test('valid-emails', 'Invalid email format!', function (value: string) {
      if (!value) return true

      const emails = value.split(',').map((email: string) => email.trim())
      const isValid = emails.every((email: string) => {
        const isValidEmail = Yup.string().email().isValidSync(email)
        const endsWithDotAnything = email.match(/\..+$/)
        return isValidEmail && endsWithDotAnything
      })

      return isValid
    }),

  Subject: Yup.string().trim().required('Please provide subject!'),
  Cc: Yup.string().test('valid-emails', 'Invalid email format!', function (value: any) {
    if (!value) return true

    const emails = value.split(',').map((email: string) => email.trim())
    const isValid = emails.every((email: string) => {
      const isValidEmail = Yup.string().email().isValidSync(email)
      const endsWithDotAnything = email.match(/\..+$/)
      return isValidEmail && endsWithDotAnything
    })
    return isValid
  }),
  Bcc: Yup.string().test('valid-emails', 'Invalid email format!', function (value: any) {
    if (!value) return true

    const emails = value.split(',').map((email: string) => email.trim())
    const isValid = emails.every((email: string) => {
      const isValidEmail = Yup.string().email().isValidSync(email)
      const endsWithDotAnything = email.match(/\..+$/)
      return isValidEmail && endsWithDotAnything
    })
    return isValid
  }),
  HtmlBody: Yup.string()
    .required('Please provide email body!')
    .test('validate-html-body', 'Please provide email body!', (value: any) => {
      const contentWithoutTags = value?.replace(/<[^>]*>/g, '')
      return contentWithoutTags?.trim() !== ''
    }),
})

export const createAccountsFormSchema: any = Yup.object().shape({
  accountName: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name!')
    .required('Please enter your name!'),
})
