import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { 
  ANALYTICS_GRAPH_DATA,
  ANALYTICS_NAME_DATA,
  ANALYTICS_BAR_GRAPH_DATA,
  TARGET_GAUGE_DATA,
  ANALYTICS_PIE_CHART_DATA,

 } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, } = actionTypes
const entity = () => {
  const getAnalyticsGraphData = (state = [], action: actions) => {
    switch (action.type) {
      case ANALYTICS_GRAPH_DATA[SUCCESS]:
        return action.payload
      case ANALYTICS_GRAPH_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getAnalyticsBarGraphData = (state = [], action: actions) => {
    switch (action.type) {
      case ANALYTICS_BAR_GRAPH_DATA[SUCCESS]:
        return action.payload
      case ANALYTICS_BAR_GRAPH_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getAnalyticsNameData = (state = [], action: actions) => {
    switch (action.type) {
      case ANALYTICS_NAME_DATA[SUCCESS]:
        return action.payload
      case ANALYTICS_NAME_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getTargetGaugeData = (state = [], action: actions) => {
    switch (action.type) {
      case TARGET_GAUGE_DATA[SUCCESS]:
        return action.payload
      case TARGET_GAUGE_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getTemperatureData = (state = [], action: actions) =>{    
    switch (action.type) {
      case ANALYTICS_PIE_CHART_DATA[SUCCESS]:
        return action.payload
      case ANALYTICS_PIE_CHART_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    getAnalyticsGraphData,
    getAnalyticsNameData,
    getAnalyticsBarGraphData,
    getTargetGaugeData,
    getTemperatureData,
  })
}

export default entity
export const getAnalyticsGraph = (state: RootState) => state.entities.analytics
