import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import InstaLogo from "../../assets/images/instagram.png";
import LinkedInLogo from "../../assets/images/linkedin.png";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import { getDownloadLeadFile } from "../../actions";
import { leadDataUI, leadsDataEntity } from "../../reducers";

const LeadDetails = (props: any) => {
  const { 
    getLeadsDetailsById, 
    DownloadableLeadFile,
    isGettingLeadsDataById,
    isDownloadableLeadFile,
    leadObjectId, 
    getDownloadLeadFileURL, 
    resetDownloadLeadFile,
} = props;

const [open, setOpen] = useState(false);
const [selectedEmail, setSelectedEmail] = useState("");
const [selectedSubject, setSelectedSubject] = useState("");
const [commentModalOpen, setCommentModalOpen] = useState(false);
const [selectedComment, setSelectedComment] = useState('');

useEffect(() => {
    if (isDownloadableLeadFile) {
      const link = document.createElement("a");
      link.href = DownloadableLeadFile?.downloadableUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resetDownloadLeadFile();
    }
  }, [isDownloadableLeadFile]);

  const useStyles = makeStyles(() => {
    return createStyles({
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      headingtooltip: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "12px!important",
        fontWeight: "bold",
        wordBreak: "break-word",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginLeft: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  const classes = useStyles();

  const handleInstagramClick = (instaId: any) => {
    if (instaId) {
      window.open(`${instaId}`, "_blank");
    }
  };

  const handleLinkedInClick = (linkedInId: any) => {
    if (linkedInId) {
      window.open(`${linkedInId}`, "_blank");
    }
  };

  const handleOpen = (email: { HtmlBody: any; Subject: any }) => {
    setSelectedEmail(email.HtmlBody);
    setSelectedSubject(email.Subject);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEmail("");
  };

  const handleCommentModalOpen = (comment:any) => {
    setSelectedComment(comment);
    setCommentModalOpen(true);
  };

  const handleCommentModalClose = () => {
    setCommentModalOpen(false);
    setSelectedComment('');
  };

  const handleDownloadClick = (fileName: any) => {
    let data = {
      id: leadObjectId,
      fileName: fileName,
    };
    getDownloadLeadFileURL(data);
  };

  function convertTextToHTML1(html: string) {
    const div = document.createElement("div");
    div.innerHTML = html;
    let text = div.textContent || div.innerText || "";

    text = text
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");

    text = text.replace(/\s+/g, " ").trim();

    return <div>{text}</div>;
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, "<br>");

    htmlText = htmlText.replace(/\*([^*]+)\*/g, "<strong>$1</strong>");

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />;
  }

  const truncateText = (text: any, wordLimit: any) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <>
    {isGettingLeadsDataById && (
        <CircularProgress color="primary" className={classes.progressBar} />
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography className={classes.titletext}>Lead Details</Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          {getLeadsDetailsById?.data?.lead_detail?.lead_deal_size && (
            <Typography className={classes.headingtext}>
              Deal Size:
              <span className={classes.headingtext}>
                ${getLeadsDetailsById?.data?.lead_detail?.lead_deal_size}
              </span>
            </Typography>
          )}
          {getLeadsDetailsById?.data?.lead_detail?.leads_instaId && (
            <img
              src={InstaLogo}
              alt="Instagram Icon"
              style={{
                height: "20px",
                width: "20px",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleInstagramClick(
                  getLeadsDetailsById?.data?.lead_detail?.leads_instaId
                )
              }
            />
          )}
          {getLeadsDetailsById?.data?.lead_detail?.linkedIn_url && (
            <img
              src={LinkedInLogo}
              alt="LinkedIn Icon"
              style={{
                height: "20px",
                width: "20px",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkedInClick(
                  getLeadsDetailsById?.data?.lead_detail?.linkedIn_url
                )
              }
            />
          )}
        </div>
      </div>
      <Card
        sx={{
          marginBottom: "10px",
          marginTop: "10px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <CardContent
          sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>Name</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>
                Phone no
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>
                Designation
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>
                Source
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>Owner</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>
                Status
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.lead_detail?.name || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.lead_detail?.leadPhone || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.lead_detail?.lead_designation ||
                  "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.lead_detail?.source || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.lead_detail?.assignee || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.lead_detail?.status || "NA"}
              </Typography>
            </Grid>
          </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography
                  className={classes.headingtext}
                  sx={{ paddingTop: "0 !important", marginTop: "5px" }}
                >
                  Email id
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={classes.headingtext}
                  sx={{ paddingTop: "0 !important", marginTop: "5px" }}
                >
                  Secondary Email id
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography className={classes.subheadingtext}>
                  {getLeadsDetailsById?.data?.lead_detail
                    ?.lead_email || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.subheadingtext}>
                  {getLeadsDetailsById?.data?.lead_detail
                    ?.secondary_email || "NA"}
                </Typography>
              </Grid>
            </Grid>
          {getLeadsDetailsById?.data?.lead_detail?.lead_description && (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{paddingTop:'0px!important', marginTop:'16px'}}>
                <Typography
                  className={classes.headingtext}
                  sx={{ paddingTop: "0 !important", marginTop: "5px" }}
                >
                  Description
                </Typography>
              </Grid>
            </Grid>
          )}
          {getLeadsDetailsById?.data?.lead_detail?.lead_description && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.subheadingtext}>
                  {getLeadsDetailsById?.data?.lead_detail
                    ?.lead_description || "NA"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Typography className={classes.titletext}>Company Details</Typography>
      <Card
        sx={{
          marginBottom: "10px",
          marginTop: "10px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <CardContent
          sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>Name</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Phone no
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Website
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Address
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Domain
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.company_detail?.company || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.company_detail?.companyPhone ||
                  "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography
                className={classes.subheadingtext}
                sx={{ wordBreak: "break-word" }}
              >
                {getLeadsDetailsById?.data?.company_detail?.website || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.company_detail
                  ?.company_address || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getLeadsDetailsById?.data?.company_detail?.domain || "NA"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card sx={{ backgroundColor: 'rgba(242, 248, 254, 1)', boxShadow: 'none' }}>
                <CardContent>
                  <Typography className={classes.headingtext}>Company Strength's</Typography>
                  <ul style={{ margin: '5px' }}>
                    <li className={classes.subheadingtext}>NA</li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ backgroundColor: 'rgba(242, 248, 254, 1)', boxShadow: 'none' }}>
                <CardContent>
                  <Typography className={classes.headingtext}>
                    Company Specialization
                  </Typography>
                  <ul style={{ margin: '5px' }}>
                    <li className={classes.subheadingtext}>NA</li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid> */}
      <Card
        sx={{
          marginTop: "15px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <Typography
          className={classes.titletext}
          sx={{ margin: "1% 0 0 2%" }}
        >
          Lead Activity Log
        </Typography>
        <CardContent
          sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
        >
          <TableContainer>
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
              >
                <TableRow>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Comment</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Followed date</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Next FollowUp date</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Status</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getLeadsDetailsById?.data?.activity_log.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} className="tablebodycell">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Data Available
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  getLeadsDetailsById?.data?.activity_log.map(
                    (activity: any, index: any) => (
                      <TableRow
                        key={index}
                        sx={{ cursor: "pointer", background: "#ffffff" }}
                        onClick={() => handleCommentModalOpen(activity.comment)}
                      >
                        <TableCell
                          className="tablebodycellcomment"
                          sx={{
                            maxWidth: "500px",
                            wordBreak: "break-word",
                          }}
                        >
                          {/* {activity.comment ? activity.comment : "NA"} */}
                          {activity.comment ? (
                          <>
                            {convertTextToHTML1(activity.comment.length > 70
                              ? activity.comment.substring(0, 70) + '...'
                              : activity.comment)}
                          </>
                        ) : "NA"}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {activity.followUpsDate
                            ? moment(activity.followUpsDate).format(
                                "MM/DD/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {activity.followUpsNextDate
                            ? moment.utc(activity.followUpsNextDate).format(
                                "MM/DD/YYYY, h:mm:ss A"
                              )
                            : "NA"}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {activity.status ? activity.status : "NA"}
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Card
        sx={{
          marginTop: "15px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <Typography
          className={classes.titletext}
          sx={{ margin: "1% 0 0 2%" }}
        >
          Email Log
        </Typography>
        <CardContent
          sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
        >
          <TableContainer>
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
              >
                <TableRow>
                  <TableCell
                    sx={{ padding: "6px 16px", maxWidth: "500px" }}
                  >
                    <span className="mail-info">Email</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Sender</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Date</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getLeadsDetailsById?.data?.emailReply?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} className="tablebodycell">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Data Available
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  getLeadsDetailsById?.data?.emailReply?.map(
                    (email: any, index: any) => (
                      <TableRow
                        key={index}
                        sx={{ cursor: "pointer", background: "#ffffff" }}
                        onClick={() =>
                          handleOpen({
                            HtmlBody: email.HtmlBody
                              ? email.HtmlBody
                              : "NA",
                            Subject: email.Subject
                              ? email.Subject
                              : "No Subject",
                          })
                        }
                      >
                        <TableCell
                          className="tablebodycellcomment1"
                          sx={{ maxWidth: "500px" }}
                        >
                          {convertTextToHTML1(
                            email.HtmlBody
                              ? truncateText(email.HtmlBody, 12)
                              : "NA"
                          )}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {email?.senderName ? email?.senderName : "NA"}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {email.SubmittedAt
                            ? moment(email.SubmittedAt).format(
                                "MM/DD/YYYY, h:mm:ss A"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {getLeadsDetailsById?.data?.lead_detail?.lead_fileName?.length >
        0 && (
        <Card
          sx={{
            marginTop: "15px",
            backgroundColor: "rgba(242, 248, 254, 1)",
            boxShadow: "none",
          }}
        >
          <Typography
            className={classes.titletext}
            sx={{ margin: "1% 0 0 2%" }}
          >
            Attachment(s)
          </Typography>
          <CardContent
            sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
          >
            <div>
              {getLeadsDetailsById?.data?.lead_detail?.lead_fileName?.map(
                (fileName: any, index: any) =>
                  fileName && (
                    <IconButton
                      key={index}
                      aria-label="close"
                      onClick={() => handleDownloadClick(fileName)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          backgroundColor: "rgba(0, 0, 0, 0.12)",
                          border: "1px solid black",
                          borderRadius: "20px",
                        }}
                      >
                        <ArrowDownwardOutlinedIcon
                          sx={{ height: "20px" }}
                        />
                        <Typography
                          sx={{
                            color: "#000000",
                            fontFamily: "Montserrat-Regular",
                            fontSize: "12px",
                            margin: "5px 10px 5px 0",
                          }}
                        >{`${fileName}`}</Typography>
                      </div>
                    </IconButton>
                  )
              )}
            </div>
          </CardContent>
        </Card>
      )}
      <Modal
        open={commentModalOpen}
        onClose={handleCommentModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="modalBox">
          <IconButton
            aria-label="close"
            onClick={handleCommentModalClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography className={classes.headingtext}>
              Comment
            </Typography>
          </div>
          <Typography id="modal-description" sx={{ mt: 2 }}>
          </Typography>
          <Typography variant="body1" style={{ fontSize: "12px" }}>
            <div>{convertTextToHTML(selectedComment || "")}</div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="email-modal-title"
        aria-describedby="email-modal-description"
      >
        <Box className="modalBox">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography className={classes.headingtext}>
              {selectedSubject || ""}
            </Typography>
          </div>
          <Typography variant="body1" style={{ fontSize: "12px" }}>
            <div>{convertTextToHTML(selectedEmail || "")}</div>
          </Typography>
        </Box>
      </Modal>
          </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getLeadsDetailsById: leadsDataEntity.getLeads(state).getLeadsDetailsById,
    isGettingLeadsDataById: leadDataUI.getLeads(state).isGettingLeadsDataById,
    DownloadableLeadFile: leadsDataEntity.getLeads(state).getDownloadLeadFile,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getDownloadLeadFileURL: (data: any) => dispatch(getDownloadLeadFile.request(data)),
    resetDownloadLeadFile: () => dispatch(getDownloadLeadFile.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadDetails);
