import { ACCTypes } from './Types'
export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'
export const RESET = 'RESET'

// This function will return Action Type object
export const createRequestTypes = (base: string) => {
  return [REQUEST, SUCCESS, FAILURE, RESET].reduce((acc: ACCTypes, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

export const action = (type: string, payload = {}) => {
  return { type, ...payload }
}

export const LEADS_DATA = createRequestTypes('LEADS_DATA')
export const SET_LEADS_COLUMN_DATA = createRequestTypes('SET_LEADS_COLUMN_DATA')
export const LEADS_STATUS_DATA = createRequestTypes('LEADS_STATUS_DATA')
export const LEADS_DETAILS_BY_ID = createRequestTypes('LEADS_DETAILS_BY_ID')
export const DOWNLOAD_LEAD_FILE= createRequestTypes('DOWNLOAD_LEAD_FILE')
export const SEND_EMAIL = createRequestTypes('SEND_EMAIL')
export const BULK_EMAIL_DATA = createRequestTypes('BULK_EMAIL_DATA')
export const SEND_EMAIL_CAMPAIGN = createRequestTypes('SEND_EMAIL_CAMPAIGN')
export const UPLOAD_FILE = createRequestTypes('UPLOAD_FILE')
export const EVENT_CALENDAR_DATA = createRequestTypes('EVENT_CALENDAR_DATA')

export const CREATE_LEAD_DATA = createRequestTypes('CREATE_LEAD_DATA')
export const UPDATE_LEAD_DATA = createRequestTypes('UPDATE_LEAD_DATA')
export const UPDATE_FOLLOW_UP_DATA = createRequestTypes('UPDATE_FOLLOW_UP_DATA')
export const CREATE_FOLLOW_UP_DATA = createRequestTypes('CREATE_FOLLOW_UP_DATA')
export const GET_FOLLOW_UP_DATA_BY_ID = createRequestTypes('GET_FOLLOW_UP_DATA_BY_ID')

export const GET_EMAIL_TRACK_DATA = createRequestTypes('GET_EMAIL_TRACK_DATA')
export const GET_EMAIL_DETAILS_DATA = createRequestTypes('GET_EMAIL_DETAILS_DATA')
export const GET_EMAIL_DASHBOARD_DATA = createRequestTypes('GET_EMAIL_DASHBOARD_DATA')
export const GET_EMAIL_GRAPH_DATA = createRequestTypes('GET_EMAIL_GRAPH_DATA')

export const ANALYTICS_GRAPH_DATA = createRequestTypes('ANALYTICS_GRAPH_DATA')
export const ANALYTICS_BAR_GRAPH_DATA = createRequestTypes('ANALYTICS_BAR_GRAPH_DATA')
export const ANALYTICS_NAME_DATA = createRequestTypes('ANALYTICS_NAME_DATA')
export const TARGET_GAUGE_DATA = createRequestTypes('TARGET_GAUGE_DATA')
export const ANALYTICS_PIE_CHART_DATA = createRequestTypes('ANALYTICS_PIE_CHART_DATA')

export const ADD_CAMPAIGN_DATA = createRequestTypes('ADD_CAMPAIGN_DATA')
export const GET_CAMPAIGN_DATA = createRequestTypes('GET_CAMPAIGN_DATA')
export const GET_CAMPAIGN_MAIL_LIST_DATA = createRequestTypes('GET_CAMPAIGN_MAIL_LIST_DATA')
export const REMOVE_LEAD_DATA = createRequestTypes('REMOVE_LEAD_DATA')
export const GET_CAMPAIGN_DETAILS_DATA = createRequestTypes('GET_CAMPAIGN_DETAILS_DATA')
export const GET_CAMPAIGN_NAME_DATA = createRequestTypes('GET_CAMPAIGN_NAME_DATA')

export const GET_CONTACTS_DATA = createRequestTypes('GET_CONTACTS_DATA')
export const CREATE_CONTACTS_DATA = createRequestTypes('CREATE_CONTACTS_DATA')
export const GET_CONTACT_DETAILS_DATA = createRequestTypes('GET_CONTACT_DETAILS_DATA')
export const UPLOAD_CONTACTS_FILE = createRequestTypes('UPLOAD_CONTACTS_FILE')

export const CREATE_USER_DATA = createRequestTypes('CREATE_USER_DATA')
export const UPDATE_USER_DATA = createRequestTypes('UPDATE_USER_DATA')
export const CHANGE_PASSWORD_DATA = createRequestTypes('CHANGE_PASSWORD_DATA')
export const SET_TARGET_DATA = createRequestTypes('SET_TARGET_DATA')
export const GET_ADD_USER_DATA = createRequestTypes('GET_ADD_USER_DATA')
export const GET_MANAGER_DATA = createRequestTypes('GET_MANAGER_DATA')

export const GET_USER_DATA = createRequestTypes('GET_USER_DATA')

export const LOGIN = createRequestTypes('LOGIN')

export const LOGOUT = createRequestTypes('LOGOUT')

export const GET_ACCESS_TOKEN = createRequestTypes('GET_ACCESS_TOKEN')

export const LOGIN_USER = createRequestTypes('LOGIN_USER')

export const FETCH_USERS = createRequestTypes('FETCH_USERS')

export const FETCH_BACKINFO = createRequestTypes('FETCH_BACKINFO')

export const SEARCH_STRING = createRequestTypes('SEARCH_STRING')








