import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {
  // CREATE_SERVICE_REQUEST,
  CREATE_USER_DATA,
  UPDATE_USER_DATA,
  CHANGE_PASSWORD_DATA,
  GET_ADD_USER_DATA,
  GET_MANAGER_DATA,
  UPLOAD_FILE,
  SEND_EMAIL,
  SEND_EMAIL_CAMPAIGN,
  BULK_EMAIL_DATA,
  GET_FOLLOW_UP_DATA_BY_ID,
  UPDATE_FOLLOW_UP_DATA,
  CREATE_FOLLOW_UP_DATA,
  CREATE_LEAD_DATA,
  UPDATE_LEAD_DATA,
  GET_EMAIL_TRACK_DATA,
  GET_EMAIL_DETAILS_DATA,
  GET_EMAIL_DASHBOARD_DATA,
  GET_EMAIL_GRAPH_DATA,
  SET_TARGET_DATA,
  EVENT_CALENDAR_DATA,
  
} from '../actions/actiontypes'
const { REQUEST } = actionTypes

//write sagas function

function* handleCreateUserAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createUserData,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_USER_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_USER_DATA)
  }
}

function* handleUpdateUserAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateUserData,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_USER_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_USER_DATA)
  }
}

function* handleChangePasswordAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.changePasswordData,
      data.data,
    )
    yield sendPayload(apiResponse, CHANGE_PASSWORD_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, CHANGE_PASSWORD_DATA)
  }
}

function* handleSetTargetAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.setTargetData,
      data.data,
    )
    yield sendPayload(apiResponse, SET_TARGET_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, SET_TARGET_DATA)
  }
}

function* handleGetAddUserDataAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAddUserDataEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, GET_ADD_USER_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, GET_ADD_USER_DATA)
  }
}

function* handleGetManagerDataAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getManagerDataEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, GET_MANAGER_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, GET_MANAGER_DATA)
  }
}

function* handleEventCalendarDataAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEventCalendarDataEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, EVENT_CALENDAR_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, EVENT_CALENDAR_DATA)
  }
}

function* handleSendEmailAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmail,
      data.data,
    )
    yield sendPayload(apiResponse, SEND_EMAIL)
  } catch (e) {
    yield sendPayloadFailure(e, SEND_EMAIL)
  }
}

function* handleCampaignSendEmailAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.CampaignSendEmail,
      data.data,
    )
    yield sendPayload(apiResponse, SEND_EMAIL_CAMPAIGN)
  } catch (e) {
    yield sendPayloadFailure(e, SEND_EMAIL_CAMPAIGN)
  }
}

function* handleUploadFileAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getUploadFile,
      data.data,
    )
    yield sendPayload(apiResponse, UPLOAD_FILE)
  } catch (e) {
    yield sendPayloadFailure(e, UPLOAD_FILE)
  }
}

function* handleUploadFileAPI11(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createLeadData,
      data.data,
    )
    setTimeout(()=>{
    },5000)
    yield sendPayload(apiResponse, CREATE_LEAD_DATA)    
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_LEAD_DATA)
  }
}

function* handleUpdateLeadDataApi(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateLeadData,
      data.data,
    )
    setTimeout(()=>{
    },5000)
    yield sendPayload(apiResponse, UPDATE_LEAD_DATA)    
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_LEAD_DATA)
  }
}

function* handleFollowUpByIdAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getFollowUpDataById,
      data.data,
    )
    yield sendPayload(apiResponse, GET_FOLLOW_UP_DATA_BY_ID)
  } catch (e) {
    yield sendPayloadFailure(e, GET_FOLLOW_UP_DATA_BY_ID)
  }
}


function* handleBuilkEmailAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.postBulkEmail,
      data.data,
    )
    yield sendPayload(apiResponse, BULK_EMAIL_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, BULK_EMAIL_DATA)
  }
}

function* handleUpdateFollowUpEmailAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateFollowUpData,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_FOLLOW_UP_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_FOLLOW_UP_DATA)
  }
}

function* handleCreateFollowUpDataAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createFollowUpData,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_FOLLOW_UP_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_FOLLOW_UP_DATA)
  }
}

// function* handleCreateLeadDataAPI(data: any) {
//   try {
//     const apiResponse: Generator<string, number, string> = yield call(
//       ApiService.createLeadData,
//       data.data,
//     )
//     console.log('aa gya', apiResponse)
//     // debugger
//     yield sendPayload(apiResponse, CREATE_LEAD_DATA)
//   } catch (e) {
//     // debugger
//     yield sendPayloadFailure(e, CREATE_LEAD_DATA)
//   }
// }

function* handleEmailTrackAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmailTrackData,
      data.data,
    )
    yield sendPayload(apiResponse, GET_EMAIL_TRACK_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, GET_EMAIL_TRACK_DATA)
  }
}

function* handleEmailDetailsAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmailDetailsData,
      data.data,
    )
    yield sendPayload(apiResponse, GET_EMAIL_DETAILS_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, GET_EMAIL_DETAILS_DATA)
  }
}
function* handleEmailDashboardAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmailDashboardData,
      data.data,
    )
    yield sendPayload(apiResponse, GET_EMAIL_DASHBOARD_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, GET_EMAIL_DASHBOARD_DATA)
  }
}

function* handleEmailGraphAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmailGraphData,
      data.data,
    )
    yield sendPayload(apiResponse, GET_EMAIL_GRAPH_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, GET_EMAIL_GRAPH_DATA)
  }
}

export const sagas = {
  //watcher come here
  watchSendEmailAPI: takeLatest(actionTypes.SEND_EMAIL[REQUEST], handleSendEmailAPI),
  watchCampaignSendEmailAPI: takeLatest(actionTypes.SEND_EMAIL_CAMPAIGN[REQUEST], handleCampaignSendEmailAPI),
  watchCreateUserAPI: takeLatest(actionTypes.CREATE_USER_DATA[REQUEST], handleCreateUserAPI),
  watchSetTargetAPI: takeLatest(actionTypes.SET_TARGET_DATA[REQUEST], handleSetTargetAPI),
  watchUpdateUserAPI: takeLatest(actionTypes.UPDATE_USER_DATA[REQUEST], handleUpdateUserAPI),
  watchChangePasswordAPI: takeLatest(actionTypes.CHANGE_PASSWORD_DATA[REQUEST], handleChangePasswordAPI),
  watchGetAddUserDataAPI: takeLatest(actionTypes.GET_ADD_USER_DATA[REQUEST], handleGetAddUserDataAPI),
  watchGetManagerDataAPI: takeLatest(actionTypes.GET_MANAGER_DATA[REQUEST], handleGetManagerDataAPI),
  watchUploadFileAPI: takeLatest(actionTypes.UPLOAD_FILE[REQUEST], handleUploadFileAPI),
  watchEventCalendarDataAPI: takeLatest(actionTypes.EVENT_CALENDAR_DATA[REQUEST], handleEventCalendarDataAPI),

  watchFollowUpDataByIdAPI: takeLatest(
    actionTypes.GET_FOLLOW_UP_DATA_BY_ID[REQUEST],
    handleFollowUpByIdAPI,
  ),
  watchEmailTrackDataAPI: takeLatest(
    actionTypes.GET_EMAIL_TRACK_DATA[REQUEST],
    handleEmailTrackAPI,
  ),
  watchEmailDetailsDataAPI: takeLatest(
    actionTypes.GET_EMAIL_DETAILS_DATA[REQUEST],
    handleEmailDetailsAPI,
  ),
  watchEmailDashboardDataAPI: takeLatest(
    actionTypes.GET_EMAIL_DASHBOARD_DATA[REQUEST],
    handleEmailDashboardAPI,
  ),

  watchEmailGraphDataAPI: takeLatest(
    actionTypes.GET_EMAIL_GRAPH_DATA[REQUEST],
    handleEmailGraphAPI,
  ),

  watchBulkEmailDataAPI: takeLatest(actionTypes.BULK_EMAIL_DATA[REQUEST], handleBuilkEmailAPI),
  watchUpdateFolloUpDataAPI: takeLatest(actionTypes.UPDATE_FOLLOW_UP_DATA[REQUEST], handleUpdateFollowUpEmailAPI),
  watchCreateFolloUpDataAPI: takeLatest(actionTypes.CREATE_FOLLOW_UP_DATA[REQUEST], handleCreateFollowUpDataAPI),
  watchCreateLeadDataAPI: takeLatest(actionTypes.CREATE_LEAD_DATA[REQUEST], handleUploadFileAPI11),
  watchUpdateLeadDataAPI: takeLatest(actionTypes.UPDATE_LEAD_DATA[REQUEST], handleUpdateLeadDataApi),
}
