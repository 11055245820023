import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { searchBar } from '../Contacts/style';
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import { getEmailDashboardAPICall, getEmailGraphAPICall, getLeadsStatusDataAPICall } from "../../actions";
import { dashboardEntity, dashboardUI, leadsDataEntity } from "../../reducers";
import moment from "moment";
import EmailPagination from "./EmailPagination";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from 'primereact/dropdown';
import EmailDetails from "./EmailDetails";

interface TypeOption {
  name: string;
}

const BounceStatus = (props: any) => {
  const {
    getEmailDashboardAPICall,
    getEmailDashboardData,
    isGettingEmailDashboardData,
    getLeadsStatusDataAPICall,
    getLeadsStatusData,
  } = props;

  const [isContactsDetailsDialogOpen, setIsEmailDetailsDialogOpen] =
    useState<boolean>(false);
  const [emailDetails, setEmailDetails] = useState<string>();
  const [limit, setLimit] = useState(100);
  const [pageChange, setPageChange] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelctedDate] = useState(
    moment(new Date()).subtract(2, "days").format("YYYY-MM-DD")
  );
  const [selectedToDate, setToSelctedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [bounceType, setBounceType] = useState<TypeOption[]>([]);
  const [selectedBounceType, setSelectedBounceType] = useState<{ label: string; value: string } | string>({
    label: "Hard Bounce",
    value: "HardBounce",
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const statusData = {
      type: "2"
    };
    getLeadsStatusDataAPICall(statusData);
  }, []);

  useEffect(() => {
    if (getLeadsStatusData) {
      const type = getLeadsStatusData?.result?.map((type: { label: string; value: string }) => ({
        label: type.label,
        value: type.value,
      }));
      setBounceType(type);
      setDataLoaded(true);

      const hardBounce = type?.find((type: any) => type.value === "HardBounce");      
      if (hardBounce) {
        setSelectedBounceType(hardBounce.value);
      }
    }
  }, [getLeadsStatusData]);

  useEffect(() => {
    if (dataLoaded) {
      const emailData = {
        pageChange: pageChange,
        searchString: searchTerm,
        fromdate: selectedDate,
        todate: selectedToDate,
        offset: offset,
        count: "500",
        type: selectedBounceType,
      }
      getEmailDashboardAPICall(emailData)
    }
  }, [limit, selectedBounceType, selectedDate, selectedToDate, offset])

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      datepicker: {
        margin: "0px 0 0px 10px !important",
        width: "170px",
        borderRadius: "20px",
      },
      headerWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
      },
      title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#add8e6",
        margin: "5px",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      titletxt: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "18px!important",
        fontWeight: 500,
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      search: {
        color: '#666666',
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        left: 5,
      },
      upload: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        border: "1px solid rgba(20, 56, 113, 1)",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        width: "20%",
        whiteSpace: "nowrap",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "100% !important",
        wordBreak: "break-word",
      },
      progressBar: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();

  const handleRowClick = (bounce: string) => {
    setIsEmailDetailsDialogOpen(true);
    setEmailDetails(bounce)
  }

  const handleEmailDetailsDialogClose = () => {
    setIsEmailDetailsDialogOpen(false);
  }

  const handleSearch = (event: any) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm === "") {
      const emailData = {
        pageChange: pageChange,
        searchString: newSearchTerm,
        fromdate: selectedDate,
        todate: selectedToDate,
        offset: offset,
        count: "500",
        type: selectedBounceType,
      }
      getEmailDashboardAPICall(emailData)
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      const emailData = {
        pageChange: pageChange,
        searchString: searchTerm,
        fromdate: selectedDate,
        todate: selectedToDate,
        offset: offset,
        count: "500",
        type: selectedBounceType,
      }
      getEmailDashboardAPICall(emailData)
    }
  };

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
  };

  const handleChangePage = (page: number, newOffset: number) => {
    setPageChange(page);
    setOffset(newOffset);
  };

  const today = selectedDate ? new Date(selectedDate) : new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDate = dayjs(formattedDate);

  const today1 = selectedToDate ? new Date(selectedToDate) : new Date();
  const formattedDate1 = today1.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDate = dayjs(formattedDate1);
  const onhandleChangeForFromDate = (e: any) => {
    setSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
  };
  const onhandleChangeForToDate = (e: any) => {
    setToSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleTypeChange = (e: MultiSelectChangeEvent) => {
    setSelectedBounceType(e.target.value);
  };


  return (
    <Paper sx={{ margin: "10px", minHeight: "300px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ marginLeft: "16px", }}>
          <TextField
            id='search'
            name='search'
            type='text'
            placeholder='Search Email or Subject'
            InputLabelProps={{ style: { color: '#666666' } }}
            size='small'
            InputProps={{
              style: { ...{ padding: '0' } },
              type: 'search',
              startAdornment: (
                <Tooltip title='Search' placement='top'>
                  <span>
                    <SearchTwoToneIcon className={classes.search} />
                  </span>
                </Tooltip>
              ),
            }}
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyPress}
            sx={{ ...searchBar }}
          />
        </Box>
        <div className={classes.headerWrapper} style={{ margin: '0 15px 0 auto' }}>
          <Typography
            className={classes.headingtxt}
          >
            Bounce Status
          </Typography>
          <div className="card flex justify-content-center">
            <Dropdown
              value={selectedBounceType}
              onChange={handleTypeChange}
              options={bounceType}
              // optionLabel="name"
              optionLabel="label"
              optionValue="value"
              placeholder="Bounce"
              className="w-full md:w-14rem custom-class"
              // showClear
              style={{
                width: "150px",
                height: "35px",
                color: "#000000",
                borderRadius: "20px",
                fontSize: "10px",
                margin: '0 0 0 10px',
              }}
            />
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{ padding: "0" }} components={["DatePicker"]}>
                <Box className={classes.datepicker}>
                  <DatePicker
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                      },
                      "& .MuiOutlinedInput-input": {
                        paddingRight: "10px !important",
                        height: "18px"
                      },
                      "& .MuiFormLabel-asterisk": {
                        color: "red",
                      },
                    }}
                    label="From Date"
                    slotProps={{
                      textField: {
                        InputLabelProps: {
                          style: { top: '-8px' },
                        },
                      },
                    }}
                    value={fromDate}
                    onChange={onhandleChangeForFromDate}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{ padding: "0" }} components={["DatePicker"]}>
                <Box className={classes.datepicker}>
                  <DatePicker
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                      },
                      "& .MuiOutlinedInput-input": {
                        paddingRight: "10px !important",
                        height: "18px"
                      },
                      "& .MuiFormLabel-asterisk": {
                        color: "red",
                      },
                    }}
                    label="To Date"
                    slotProps={{
                      textField: {
                        InputLabelProps: {
                          style: { top: '-8px' },
                        },
                      },
                    }}
                    value={toDate}
                    onChange={onhandleChangeForToDate}
                    minDate={fromDate}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1",
          minHeight: "360px",
        }}
      >
        <TableContainer sx={{ marginBottom: "10px", width: "100%" }}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
            >
              <TableRow>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Email</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Subject</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Sent From</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Company Name</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Bounced Date</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Type</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isGettingEmailDashboardData ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              ) : getEmailDashboardData?.Bounces?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data Available
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                getEmailDashboardData?.Bounces?.map((bounce: any, index: any) => (
                  <TableRow
                    key={index}
                    sx={{ cursor: "pointer", background: "#ffffff" }}
                    onClick={() => handleRowClick(bounce)}
                  >
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {bounce.Email
                        ? (bounce?.Email?.length > 40 ? bounce?.Email.slice(0, 40) + '...' : bounce?.Email)
                        : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {bounce.Subject
                        ? (bounce?.Subject?.length > 35 ? bounce?.Subject.slice(0, 35) + '...' : bounce?.Subject)
                        : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {bounce.mailFrom ? bounce.mailFrom : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {bounce.company_name ? bounce.company_name : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {bounce?.BouncedAt
                        ? moment(bounce?.BouncedAt).format("MM/DD/YYYY")
                        : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {bounce.Type ? bounce.Type : "NA"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <EmailPagination
            emailTableData={getEmailDashboardData?.TotalCount}
            handleChangePage={handleChangePage}
            handleLimitChange={handleLimitChange}
            pageChange={pageChange}
            limit={limit}
          />
        </TableContainer>
      </div>

      <Dialog
        open={isContactsDetailsDialogOpen}
        onClose={handleEmailDetailsDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: 'auto',
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
          Email Details
          <IconButton
            aria-label="close"
            onClick={handleEmailDetailsDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="campaign-divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <EmailDetails
            emailDetailsData={emailDetails}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getEmailDashboardData: dashboardEntity.getDashboard(state).getEmailDashboardData,
    getLeadsStatusData: leadsDataEntity.getLeads(state).getLeadsStatusData,
    isGettingEmailDashboardData: dashboardUI.getDashboard(state).isGettingEmailDashboardData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getLeadsStatusDataAPICall: (data: any) =>
      dispatch(getLeadsStatusDataAPICall.request(data)),
    getEmailDashboardAPICall: (data: any) => dispatch(getEmailDashboardAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BounceStatus);  