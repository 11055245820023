// import { FormControl, MenuItem, Pagination as MuiPagination, Select } from '@mui/material'
// import Stack from '@mui/material/Stack'

// const EmailPagination = (props: any) => {
//   const { emailTableData, handleChangePage, pageChange, handleLimitChange,limit } = props  

//   const checkPagination = () => {
//     if (emailTableData > 0) {
//       return 'pagination-required'
//     } else {
//       return 'pagination-not-required'
//     }
//   }

//   switch (checkPagination()) {
//     case 'pagination-not-required':
//       return <></>
//     case 'pagination-required':
//       return (
//         <div className='page-cont'>
//           {/* <div className='page-items'>
//             <Stack>
//               <MuiPagination
//               size="small"
//                 count={emailTableData?.totalPages}
//                 variant='outlined'
//                 color='primary'
//                 page={pageChange}
//                 onChange={handleChangePage}
//               />
//             </Stack>
//           </div> */}
//           <FormControl sx={{height:'50%!important',width:'6% !important', marginLeft:'10px !important'}}>
//                 <Select
//                 sx={{borderRadius:'20px',fontSize:'10px'}}
//                   labelId='limit-select-label'
//                   id='limit-select'
//                   value={limit}
//                   onChange={handleLimitChange}
//                   MenuProps={{
//                     PaperProps: {
//                       style: {
//                         maxHeight: "150px",
//                       },
//                     },
//                   }}
//                 >
//                   <MenuItem value={100}>100</MenuItem>
//                   <MenuItem value={200}>200</MenuItem>
//                   <MenuItem value={300}>300</MenuItem>
//                   <MenuItem value={400}>400</MenuItem>
//                   <MenuItem value={500}>500</MenuItem>
//                 </Select>
//               </FormControl>
//         </div>        
//       )
//   }
// }

// export default EmailPagination


import { FormControl, MenuItem, Pagination as MuiPagination, Select } from '@mui/material'
import Stack from '@mui/material/Stack'

const EmailPagination = (props: any) => {
  const { emailTableData, handleChangePage, pageChange, handleLimitChange, limit } = props;  

  const checkPagination = () => {
    if (emailTableData > 0) {
      return 'pagination-required';
    } else {
      return 'pagination-not-required';
    }
  };

  const onPageChange = (event: any, page: number) => {
    // const newCount = page * 500;
    const newOffset = (page - 1) * 500;

    handleChangePage(page, newOffset);
  };

  switch (checkPagination()) {
    case 'pagination-not-required':
      return <></>;
    case 'pagination-required':
      return (
        <div className='page-cont'>
          <div className='page-items'>
            <Stack> 
              <MuiPagination
                size="small"
                count={emailTableData ? Math.ceil(emailTableData / 500) : 1}
                variant='outlined'
                color='primary'
                page={pageChange}
                onChange={onPageChange} 
              />
            </Stack>
          </div>
        </div>
      );
  }
};

export default EmailPagination;

