import React, { useEffect, useState } from "react";
import { getAnalyticsGraphDataAPICall } from "../../actions";
import { analyticsDataEntity } from "../../reducers";
import ApexCharts from "apexcharts";
import { connect } from "react-redux";

const AnalyticsBarGraph = (props: any) => {
  const { getAnalyticsBarGraphData } = props;

  const [chartData, setChartData] = useState<
    { date: string; name: string; emailCount: number; callCount: number }[]
  >([]);

  useEffect(() => {
    if (getAnalyticsBarGraphData && getAnalyticsBarGraphData.analytics) {
      const newData = getAnalyticsBarGraphData.analytics.flatMap((item: any) =>
        item.users.map((user: any) => ({
          date: item.date,
          name: user.name,
          emailCount: user.emailCount || 0,
          callCount: user.callCount || 0,
        }))
      );
      setChartData(newData);
    }
  }, [getAnalyticsBarGraphData]);

  useEffect(() => {
    if (chartData.length > 0) {
      const dates = Array.from(new Set(chartData.map((item) => item.date)));
      const users = Array.from(new Set(chartData.map((item) => item.name)));

      const series = [
        {
          name: "Call Done",
          data: users.flatMap((user) =>
            dates.map((date) => {
              const userData = chartData.find(
                (item) => item.date === date && item.name === user
              );
              return userData ? userData.callCount : 0;
            })
          ),
          color: "#00fe99",
        },
        {
          name: "Email Sent",
          data: users.flatMap((user) =>
            dates.map((date) => {
              const userData = chartData.find(
                (item) => item.date === date && item.name === user
              );
              return userData ? userData.emailCount : 0;
            })
          ),
          color: "#008FFB",
        },
      ];

      const totalBars = dates?.length * users?.length;
      const columnWidth = totalBars > 3 ? "30%" : "5%";

      const options = {
        series: series,
        chart: {
          height: 350,
          width: "100%",
          type: "bar",
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: columnWidth,
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: users.flatMap((user) => dates.map((date) => `${date}`)),
          tickPlacement: "on",
          // Enable scrollbar
          scrollbar: {
            enabled: true,
            height: 20,
            barHeight: 20,
          },
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val;
            },
          },
        },
      };

      const chart = new ApexCharts(
        document.querySelector("#bargraph"),
        options
      );
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  return (
    <div id="bargraph" style={{ display: "flex", justifyContent: "center" }} />
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAnalyticsGraphData:
      analyticsDataEntity.getAnalyticsGraph(state).getAnalyticsGraphData,
    getAnalyticsBarGraphData:
      analyticsDataEntity.getAnalyticsGraph(state).getAnalyticsBarGraphData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAnalyticsGraphDataAPICall: (data: any) =>
      dispatch(getAnalyticsGraphDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsBarGraph);
