import { Box, Button, Grid, Typography, TextField, IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
// import { changePasswordAPICall } from "../../actions"; 
import { dashboardEntity } from "../../reducers";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { changePasswordDataAPICall } from "../../actions";
import { Opacity } from "@mui/icons-material";

const ChangePassword = (props: any) => {
  const { changePasswordDataAPICall, handlePasswordDialogClose } = props;
  const userId = localStorage.getItem('userId');

    const useStyles = makeStyles(() =>
        createStyles({
            button: {
                borderRadius: "20px !important",
                fontFamily: "Montserrat-SemiBold !important",
                fontSize: "14px !important",
                backgroundColor: '#193C6D !important',
                color: '#ffffff !important',
                '&.Mui-disabled': {
                  opacity: 0.5,
                },
            },
            cancelbtn: {
                borderRadius: "20px!important",
                backgroundColor: "#e0e0e0!important",
                color: "#000000!important",
                marginRight: "10px!important",
                fontFamily: "Montserrat-SemiBold!important",
                fontSize: "14px!important",
                width: "100px!important",
            },
            inputfocus: {
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#193C6D",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                    color: "#193C6D",
                },
                marginTop: "10px",
                marginBottom: "15px",
            },
        })
    );

  const classes = useStyles();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current Password is required"),
      newPassword: Yup.string()
        .required("New Password is required")
        .min(8, "Password should be of minimum 8 characters length")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(/[@$!%*?&]/, "Password must contain at least one special character"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values, event: any) => {},
  });

  const handleSubmit = () => {
    let passwordData = {
        currentPassword: formik.values?.currentPassword,
        newPassword: formik.values?.newPassword,
        id: userId,
    };
    if (passwordData) {
      changePasswordDataAPICall(passwordData);
      handlePasswordDialogClose();
    }
  };

  const getErrorText = (error: any): string | undefined => {
    return typeof error === "string" ? error : undefined;
  };

  const handleClickShowPassword = (field: string) => {
    switch (field) {
      case "currentPassword":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "newPassword":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirmPassword":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="currentPassword"
            name="currentPassword"
            label="Current Password"
            autoComplete="true"
            type={showCurrentPassword ? "text" : "password"}
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
            helperText={formik.touched.currentPassword ? getErrorText(formik.errors.currentPassword) : ""}
            sx={{ marginTop: "10px", marginBottom: "15px" }}
            InputLabelProps={{ style: { top: "-6px" } }}
            className={classes.inputfocus}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("currentPassword")}
                    edge="end"
                    >
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: { borderRadius: '20px' },
            }}
          />
          <TextField
            fullWidth
            id="newPassword"
            name="newPassword"
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword ? getErrorText(formik.errors.newPassword) : ""}
            sx={{marginTop: "10px",marginBottom: "15px",}}
            className={classes.inputfocus}
            InputLabelProps={{ style: { top: "-6px" } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("newPassword")}
                    edge="end"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: { borderRadius: 20 },
            }}
          />
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword ? getErrorText(formik.errors.confirmPassword) : ""}
            sx={{ marginTop: "10px", marginBottom: "15px" }}
            className={classes.inputfocus}
            InputLabelProps={{ style: { top: "-6px" } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("confirmPassword")}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: { borderRadius: 20 },
            }}
          />
      <div style={{ display: "flex", justifyContent: "right", marginTop: "10px" }}>
        <Button
          className={classes.cancelbtn}
          color="primary"
          onClick={() => handlePasswordDialogClose()}
        >
          Cancel
        </Button>
        <span style={{ cursor: 'not-allowed' }}>
        <Button
          className={classes.button}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
        >
          Submit
        </Button>
        </span>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    // Add any relevant state mappings if needed
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    changePasswordDataAPICall: (data: any) => dispatch(changePasswordDataAPICall.request(data)),  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

