import { Box, createStyles, makeStyles } from '@material-ui/core'
import EmailStatus from '../components/TrackEmail/EmailStatus'

const useStyles = makeStyles(() => {
  return createStyles({
    mainContainer: {
      height: '95%',
      width: '100%',
    },
  })
})

const TrackEmail = () => {
  const classes = useStyles()
  return (
    <>
      <Box className='pages-container'>
        <EmailStatus />
      </Box>
    </>
  )
}

export default TrackEmail
