import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { createLeadDataAPICall } from '../../actions'
import countryCodes from '../Leads/Countrywithflag.json'
import countriesData from '../Leads/CountriesandStates.json'
import { Paper, createStyles, makeStyles, } from '@material-ui/core'
import * as options from './constant';
import { useNavigate } from 'react-router-dom'
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  Divider,
  Button,
  Box
} from '@mui/material';
import { toast } from 'react-toastify'
import { dashboardUI, leadsDataEntity } from '../../reducers';

interface LeadData {
  first_name: string;
  last_name: string;
  email: string;
  leadPhone?: string;
  leads_country: string;
  description_of_lead: string;
  deal_size: string;
  company: string;
  source: string;
  domain: string;
  technology: string;
  follow_up_status: string;
  company_address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  lead_designation: string;
  lead_linkedIn_url: string;
  leads_instaId: string;
  company_linkedIn_url: string;
  companySize: string;
  website: string;
  secondary_email?: string;
  companyPhone?: string; 
}

const AddLead = (props: any) => {
  const {
    createLeadDataAPICall,
    isCreateLeadDone,
    resetCreateLead,
    getAddLeadDetails,
    // handleOpenEmailDialog,
  } = props

  const navigate = useNavigate()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [secondaryEmail, setSecondaryEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [companyphone, setCompanyPhone] = useState('')
  const [countryCode, setCountryCode] = useState('+91')
  const [companycountryCode, setCompanyCountryCode] = useState('+91')
  const [company, setCompany] = useState('')
  const [source, setSource] = useState('')
  const [domain, setDomain] = useState('')
  const [dealSize, setDealSize] = useState('')
  const [leadDescription, setLeadDescription] = useState('')
  const [technology, setTechnology] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [zip, setZip] = useState('')
  const [companySize, setCompanySize] = useState('')
  const [companyLinkedin, setCompanyLinkedIn] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState('')
  const [leadLinkedin, setLeadLinkedIn] = useState('')
  const [leadInstagram, setLeadInstagram] = useState('')
  const [leadCountry, setLeadCountry] = useState('')
  const [leadDesignation, setLeadDesignation] = useState('')
  const [status, setStatus] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [companyPhoneError, setCompanyPhoneError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [secondaryEmailError, setSecondaryEmailError] = useState(false)
  const [companyError, setCompanyError] = useState(false)
  const [domainError, setDomainError] = useState(false)  

  const useStyles = makeStyles((theme) => {
    return createStyles({
      dottedDivider: {
        width: '100%',
        textAlign: 'center',
        borderTop: '2px dotted' + theme.palette.divider,
        margin: '20px 0',
      },
      headingtxt: {
        color: '#000000',
        fontFamily: 'Montserrat-SemiBold !important',
        fontSize: '22px!important',
        marginTop: '20px !important',
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      updateButton: {
        display: 'block!important',
        textTransform: 'capitalize',
        borderRadius: '20px !important',
        fontFamily: 'Montserrat-Medium !important',
        fontSize: '15px !important',
        backgroundColor: '#193C6D !important',
        '&:disabled': {
          opacity: '.5 !important',
          cursor: 'not-allowed !important',
          color: '#fff !important',
          backgroundColor: '#193C6D !important',
        }
      },
      cancelButton: {
        marginRight: '10px !important',
        display: 'block !important',
        textTransform: 'capitalize',
        borderRadius: '20px !important',
        fontFamily: 'Montserrat-Medium !important',
        fontSize: '15px !important',
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        color: '#000000 !important',
      },
      updateButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
      },
      headerContainer: {
        height: '8% !important',
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      },
      createAccoutContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '50%',
        width: '100%',
        justifyContent: 'center',
        gap: '2px',
      },
      titletxt: {
        fontFamily: 'Montserrat-SemiBold!important',
        fontSize: '18px!important',
        fontWeight: 500,
      },
      input1: {
        '&::placeholder': {
          color: 'red',
        },
      },
    })
  })
  const classes = useStyles()

  const handleResetErrors = () => {
    setFirstName('')
    setLastName('')
    setPhone('')
    setCompanyPhone('')
    setCountryCode('+91')
    setCompanyCountryCode('+91')
    setEmail('')
    setSecondaryEmail('')
    setCompany('')
    setDomain('')
    setSource('')
    setTechnology('')
    setAddress('')
    setCity('')
    setState('')
    setCountry('')
    setZip('')
    setCompanySize('')
    setCompanyLinkedIn('')
    setCompanyWebsite('')
    setLeadLinkedIn('')
    setLeadInstagram('')
    setLeadCountry('')
    setLeadDesignation('')
    setNameError(false)
    setEmailError(false)
    setSecondaryEmailError(false)
    setPhoneError(false)
    setCompanyPhoneError(false)
    setCompanyError(false)
    setDomainError(false)
  }

  const handleAddDialog = (data: any) => {
    const leadData: LeadData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      // leadPhone: `${countryCode} ${phone}`,
      // companyPhone: `${companycountryCode} ${companyphone}`,
      description_of_lead: leadDescription, 
      deal_size: dealSize,
      company: company,
      leads_country: leadCountry,
      source: source,
      domain: domain,
      technology: technology,
      follow_up_status: status,
      company_address: address,
      city: city,
      state: state,
      country: country,
      zip_code: zip,
      lead_designation: leadDesignation,
      lead_linkedIn_url: leadLinkedin,
      leads_instaId: leadInstagram,
      company_linkedIn_url: companyLinkedin,
      companySize: companySize,
      website: companyWebsite,
      secondary_email: secondaryEmail,
    }
    if (companycountryCode && companyphone) {
      leadData.companyPhone = `${companycountryCode} ${companyphone}`;
    }
    if (countryCode && phone) {
      leadData.leadPhone = `${countryCode} ${phone}`;
    }
    if (leadData) {
      createLeadDataAPICall(leadData)
      handleResetErrors();
      navigate(`/home/dashboard`);
    }
  }

  const handleSaveAndNew = (data: any) => {
    const leadData: LeadData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      // leadPhone: `${countryCode} ${phone}`,
      // companyPhone: `${companycountryCode} ${companyphone}`,
      description_of_lead: leadDescription, 
      deal_size: dealSize,
      leads_country: leadCountry,
      company: company,
      source: source,
      domain: domain,
      technology: technology,
      follow_up_status: status,
      company_address: address,
      city: city,
      state: state,
      country: country,
      zip_code: zip,
      lead_designation: leadDesignation,
      lead_linkedIn_url: leadLinkedin,
      company_linkedIn_url: companyLinkedin,
      leads_instaId: leadInstagram,
      companySize: companySize,
      website: companyWebsite,
      secondary_email: secondaryEmail,
    }
    if (companycountryCode && companyphone) {
      leadData.companyPhone = `${companycountryCode} ${companyphone}`;
    }
    if (countryCode && phone) {
      leadData.leadPhone = `${countryCode} ${phone}`;
    }
    if (leadData) {
      createLeadDataAPICall(leadData)
      handleResetErrors();
    }
  }

  useEffect(() => {
    if (isCreateLeadDone) {
      if (getAddLeadDetails?.error?.length) {
        toast.success(getAddLeadDetails?.message)
      }else {
        toast.success(getAddLeadDetails?.message)
      }
      handleResetErrors()
      resetCreateLead()
    }
  }, [isCreateLeadDone])

  const handleAddLeadCancel = () => {
    // setIsEditDialogOpen(false)
    navigate(`/home/dashboard`);
    handleResetErrors()
  }

  const handleChangeForFirstName = (event: { target: { value: any } }) => {
    const value = event.target.value
    setFirstName(value)
    setNameError(value.trim() === '')
  }

  const handleChangeForLastName = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLastName(value)
  }

  const handleChangeForEmail = (event: { target: { value: any } }) => {
    const value = event.target.value
    setEmail(value)
    setEmailError(!isValidEmail(value))
  }

  const handleChangeForSecondaryEmail = (event: { target: { value: any } }) => {
    const value = event.target.value
    setSecondaryEmail(value)
    setSecondaryEmailError(!isValidEmail(value))
  }

  const handleChangeForPhone = (event: { target: { value: any } }) => {
    const value = event.target.value
    setPhone(value)
    setPhoneError(value.trim() === '' || !isValidPhoneNumber(value))
  }

  const handleChangeForCompanyPhone = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanyPhone(value)
    setCompanyPhoneError(value.trim() === '' || !isValidPhoneNumber(value));
  }

  const handleChangeForCountryCode = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCountryCode(value)
  }

  const handleChangeForCompanyCountryCode = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanyCountryCode(value)
  }

  const handleChangeForCompany = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompany(value)
    setCompanyError(value.trim() === '')
  }

  const handleChangeForDomain = (event: { target: { value: any } }) => {
    const value = event.target.value
    setDomain(value)
    setDomainError(value.trim() === '')
  }

  const handleChangeForTechnology = (event: { target: { value: any } }) => {
    const value = event.target.value
    setTechnology(value)
  }

  const handleChangeForAddress = (event: { target: { value: any } }) => {
    const value = event.target.value
    setAddress(value)
  }

  const handleChangeForCity = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCity(value)
  }

  const handleChangeForState = (event: { target: { value: any } }) => {
    const value = event.target.value
    setState(value)
  }

  const handleChangeForCountry = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCountry(value)
    setState('');
  }

  const handleChangeForZip = (event: { target: { value: any } }) => {
    const value = event.target.value
    if (!isNaN(value))  {
      setZip(value)
    }
  }

  const handleChangeForCompanySize = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanySize(value)
  }

  const handleChangeForCompanyLinkedIn = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanyLinkedIn(value)
  }

  const handleChangeForCompanyWebsite = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanyWebsite(value)
  }

  const handleChangeForDealSize = (event: { target: { value: any } }) => {
    const value = event.target.value
    if (!isNaN(value))  {
      setDealSize(value)
    }
  }

  const handleChangeForLeadDescription = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadDescription(value)
  }



  const handleChangeForLeadDesignation = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadDesignation(value)
  }

  const handleChangeForLeadLinkedIn = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadLinkedIn(value)
  }

  const handleChangeForLeadInstagram = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadInstagram(value)
  }

  const handleChangeForLeadCountry = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadCountry(value)
  }

  const isValidEmail = (value: string) => {
    return /\S+@\S+\.\S+/.test(value)
  }

  const isValidPhoneNumber = (value: string) => {
    return /^\d{10}$/.test(value)
  }

  const selectedCountryData = countriesData.find((country1) => country1.name === country);
  const statesOfSelectedCountry = selectedCountryData?.states || [];

  const allFieldsFilleds =
  firstName.trim() !== '' &&
    email.trim() !== '' &&
    company.trim() !== '' &&
    domain.trim() !== '' &&
    source.trim() !== '' &&
    !nameError &&
    !emailError &&
    !phoneError &&
    !companyPhoneError &&
    !companyError &&
    !domainError


  return (
    <>
      <Paper style={{ margin: '16px', padding: '20px', paddingTop: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography className={classes.titletxt} sx={{ marginBottom: '5px' }}>Add Lead</Typography>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center', gap: '10' }}> */}
        <Grid container spacing={2}>
          <Grid item xs={3}>
          <TextField
            id='firstname'
            label="Lead's First Name"
            sx={{ marginTop: '5px', marginBottom: '15px', }}
            value={firstName}
            onChange={handleChangeForFirstName}
            error={nameError}
            helperText={nameError ? 'Name is required' : ''}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          </Grid>
          <Grid item xs={3}>
          <TextField
            id='lastname'
            label="Lead's Last Name"
            required={false}
            sx={{ marginTop: '5px', marginBottom: '15px', }}
            value={lastName}
            onChange={handleChangeForLastName}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          </Grid>
          <Grid item xs={6}>
          <TextField
            id='email'
            label="Lead's Primary Email"
            sx={{ marginTop: '5px', marginBottom: '15px',}}
            value={email}
            onChange={handleChangeForEmail}
            error={emailError}
            helperText={emailError ? 'Invalid email format' : ''}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          </Grid>
        </Grid>
        {/* </div> */}
        <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id='email'
            label="Lead's Secondary Email"
            required={false}
            sx={{ marginTop: '0', marginBottom: '15px' }}
            value={secondaryEmail}
            onChange={handleChangeForSecondaryEmail}
            error={secondaryEmailError}
            helperText={secondaryEmailError ? 'Invalid email format' : ''}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='address'
              label="Lead's Designation"
              required={false}
              sx={{ marginTop: '0', marginBottom: '15px' }}
              value={leadDesignation}
              onChange={handleChangeForLeadDesignation}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10' }}>
              <Select
                value={countryCode}
                onChange={handleChangeForCountryCode}
                // label='Country Code'
                sx={{
                  minWidth: '125px',
                  marginBottom: '15px',
                  marginRight: '5px',
                  borderRadius: '20px',
                }}
                renderValue={(selected) => {
                  const selectedCountry = countryCodes.find(
                    (country) => country.dial_code === selected,
                  )
                  if (selectedCountry) {
                    return `(${selectedCountry.dial_code}) ${selectedCountry.emoji}`
                  }
                  return ''
                }}
              >
                {countryCodes.map((country: any) => (
                  <MenuItem key={country.code} value={country.dial_code}>
                    {`(${country.dial_code}) ${country.emoji} ${country.name}`}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id='phone'
                label="Lead's Phone No."
                sx={{ marginTop: '0', marginBottom: '15px' }}
                required={false}
                value={phone}
                onChange={handleChangeForPhone}
                error={phoneError}
                helperText={phoneError ? 'Invalid phone number' : ''}
                InputLabelProps={{
                  style: { top: '-6px' },
                }}
                InputProps={{ sx: { borderRadius: 20 } }}
              // inputProps={{
              //   maxLength: 10,
              // }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id='address'
              label="Lead's Description"
              multiline
              required={false}
              sx={{ marginTop: '0', marginBottom: '15px',}}
              value={leadDescription}
              onChange={handleChangeForLeadDescription}
              // InputLabelProps={{
              //   style: { top: '-6px',},
              // }}
              InputProps={{ sx: { padding:'0',borderRadius:'20px' } }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              id='leadslinkedIn'
              label="Lead's LinkedIn"
              required={false}
              sx={{ marginTop: '0', marginBottom: '15px' }}
              value={leadLinkedin}
              onChange={handleChangeForLeadLinkedIn}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='leadsInstagram'
              label="Lead's Instagram"
              required={false}
              sx={{ marginTop: '0', marginBottom: '15px' }}
              value={leadInstagram}
              onChange={handleChangeForLeadInstagram}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='leadscountry'
              select
              label="Lead's Country"
              required={false}
              sx={{ marginTop: '0', marginBottom: '15px' }}
              value={leadCountry}
              onChange={handleChangeForLeadCountry}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: '250px', 
                    },
                  },
                },
              }}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            >
              {countriesData
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((country, index) => (
              <MenuItem  key={index} value={country.name}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='dealsize'
              label="Deal Size ($)"
              required={false}
              sx={{ marginTop: '0', 
              marginBottom: '15px',
             }}
              value={dealSize}
              onChange={handleChangeForDealSize}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{ borderColor: 'text.secondary', marginBottom: '15px' }}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id='company'
              label='Company'
              sx={{ marginTop: '0', marginBottom: '15px' }}
              value={company}
              onChange={handleChangeForCompany}
              error={companyError}
              helperText={companyError ? 'Company is required' : ''}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                value={companycountryCode}
                onChange={handleChangeForCompanyCountryCode}
                // label='Country Code'
                sx={{
                  minWidth: '125px',
                  marginBottom: '15px',
                  marginRight: '5px',
                  borderRadius: '20px',
                }}
                renderValue={(selected) => {
                  const selectedCountry = countryCodes.find(
                    (country) => country.dial_code === selected,
                  )
                  if (selectedCountry) {
                    return `(${selectedCountry.dial_code}) ${selectedCountry.emoji}`
                  }
                  return ''
                }}
              >
                {countryCodes.map((country: any) => (
                  <MenuItem key={country.code} value={country.dial_code}>
                    {`(${country.dial_code}) ${country.emoji} ${country.name}`}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id='phone'
                label="Company's Phone No."
                required={false}
                sx={{ marginTop: '0', marginBottom: '15px' }}
                value={companyphone}
                onChange={handleChangeForCompanyPhone}
                error={companyPhoneError}
                helperText={companyPhoneError ? 'Invalid phone number' : ''}
                InputLabelProps={{
                  style: { top: '-6px' },
                }}
                InputProps={{ sx: { borderRadius: 20 } }}
              />
            </div>
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: '0!important' }}>
            <TextField
              id='technology'
              label='Technology'
              required={false}
              sx={{ marginTop: '0', marginBottom: '15px' }}
              value={technology}
              onChange={handleChangeForTechnology}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: '0!important' }}>
            <TextField
              select
              id='domain'
              label='Domain'
              sx={{ marginTop: '0', marginBottom: '15px' }}
              value={domain}
              onChange={handleChangeForDomain}
              error={domainError}
              helperText={domainError ? 'Domain is required' : ''}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            >
              {options?.domainOptions?.map((option:any) => (
              <MenuItem key={option} value={option}>
              {option}
              </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: '0!important' }}>
            <TextField
              select
              id='source'
              value={source}
              label='Source'
              sx={{ marginTop: '0', marginBottom: '15px' }}
              onChange={(e) => setSource(e.target.value)}
              InputLabelProps={{
                style: { top: '-6px' },
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            >
              {options?.sourceOptions?.map((option:any) => (
              <MenuItem key={option} value={option}>
              {option}
              </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <TextField
            id='companyLinkeIn'
            label='Company LinkedIn Profile'
            required={false}
            sx={{ marginTop: '0', marginBottom: '15px' }}
            value={companyLinkedin}
            onChange={handleChangeForCompanyLinkedIn}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          <TextField
          select
            id='companySize'
            label='Company Size'
            required={false}
            sx={{ marginTop: '0', marginBottom: '15px' }}
            value={companySize}
            onChange={handleChangeForCompanySize}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          >
            {options?.companySizeOptions?.map((option:any) => (
              <MenuItem key={option} value={option}>
              {option}
              </MenuItem>
              ))}
            </TextField>
          <TextField
            id='companyWebsite'
            label='Company website'
            required={false}
            sx={{ marginTop: '0', marginBottom: '15px' }}
            value={companyWebsite}
            onChange={handleChangeForCompanyWebsite}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
        </div>
        <TextField
          id='address'
          label='Address'
          required={false}
          sx={{ marginTop: '0', marginBottom: '15px' }}
          value={address}
          onChange={handleChangeForAddress}
          InputLabelProps={{
            style: { top: '-6px' },
          }}
          InputProps={{ sx: { borderRadius: 20 } }}
        />
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <TextField
            id='country'
            select
            label='Country'
            required={false}
            sx={{ marginTop: '0', marginBottom: '15px' }}
            value={country}
            onChange={handleChangeForCountry}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: '250px', 
                  },
                },
              },
            }}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          >
            {countriesData
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((country, index) => (
              <MenuItem  key={index} value={country.name}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id='state'
            select
            label='State'
            required={false}
            sx={{ marginTop: '0', marginBottom: '15px' }}
            value={state}
            onChange={handleChangeForState}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          >
            {statesOfSelectedCountry
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((state, index) => (
              <MenuItem key={index} value={state.name}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <TextField
            id='city'
            label='City'
            required={false}
            sx={{ marginTop: '0', marginBottom: '15px' }}
            value={city}
            onChange={handleChangeForCity}
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          <TextField
            id='zip'
            label='Zip'
            required={false}
            sx={{ marginTop: '0', marginBottom: '15px' }}
            value={zip}
            onChange={handleChangeForZip}

            InputLabelProps={{
              style: { top: '-6px', borderRadius: '20px' },
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '5px' }}>
          <Button
            className={classes.cancelButton}
            variant='contained'
            onClick={() => {
              handleAddLeadCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className={classes.updateButton}
            sx={{marginRight:'10px'}}
            variant='contained'
            color='primary'
            disabled={!allFieldsFilleds}
            onClick={() => handleSaveAndNew('')}
          >
            Save and New
          </Button>
          <Button
            className={classes.updateButton}
            variant='contained'
            color='primary'
            disabled={!allFieldsFilleds}
            onClick={() => handleAddDialog('')}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </>
  )
};

const mapStateToProps = (state: any) => {
  return {
    isCreateLeadDone: dashboardUI.getDashboard(state).isCreateLeadDone,
    getAddLeadDetails: leadsDataEntity.getLeads(state).getAddLeadDetails,

  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    createLeadDataAPICall: (data: any) => dispatch(createLeadDataAPICall.request(data)),
    resetCreateLead: () => dispatch(createLeadDataAPICall.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLead);
