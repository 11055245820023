import { useEffect, useState } from "react";
import "./Campaign.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { searchBar } from './constant';
import CloseIcon from "@mui/icons-material/Close";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { createStyles, makeStyles } from "@material-ui/core";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { connect } from "react-redux";
import moment from "moment";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import {
  getCampaignDetailsDataAPICall,
  getCampaignMailListDataAPICall,
  removeLeadDataAPICall,
} from "../../actions";
import { campaignDataUI } from "../../reducers";
import Pagination from "./pagination";
import clsx from "clsx";

const CampaignDetails = (props: any) => {
  const {
    emailLogs,
    getCampaignDetailsData,
    getCampaignDetailsDataAPICall,
    getCampaignMailListDataAPICall,
    removeLeadDataAPICall,
    gettingCampaignDetailsData,
    isRemovingLeadDataDone,
    onPageSizeChange,
    campaignId1,
    page,
    pageLimit,
    onPageLimitChange,
    setHandleEmailBox,
    dialogClosed,
  } = props;  

  const [pageChange, setPageChange] = useState(1);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpenOpen] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [selectedLeads, setSelectedLeads] = useState<any[]>([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {    
    if (dialogClosed) {
      setSearchTerm("")
    }
  }, [dialogClosed]);

  const handleSearch = (event: any) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm === "") {
      const data = {
        search: newSearchTerm,
        id: campaignId1,
        page: page,
        pageSize: pageLimit,
      };
      getCampaignDetailsDataAPICall(data);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      const data = {
        search: encodeURIComponent(searchTerm.trim()),
        id: campaignId1,
        page: page,
        pageSize: pageLimit,
      }      
      getCampaignDetailsDataAPICall(data);
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allLeadIds =
        getCampaignDetailsData?.data?.Campaignusers?.map(
          (campaign: any) => campaign.users.id
        ) || [];
      setSelectedLeads(allLeadIds);
    } else {
      setSelectedLeads([]);
    }
  };

  const handleSelectLead = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    event.stopPropagation();
    if (event.target.checked) {
      setSelectedLeads([...selectedLeads, id]);
    } else {
      setSelectedLeads(selectedLeads.filter((leadId) => leadId !== id));
    }
  };

  const isSelected = (id: any) => selectedLeads.includes(id);
  const allSelected =
    getCampaignDetailsData?.data?.Campaignusers?.length > 0 &&
    selectedLeads.length ===
    getCampaignDetailsData?.data?.Campaignusers?.length;

  useEffect(() => {
    if (isRemovingLeadDataDone) {
      setOpen(false);
    }
    if (dialogClosed) {
      setSearchTerm("")
    }
  }, [isRemovingLeadDataDone,dialogClosed]);

  const handleOpen = (campaignId: any, userId: any) => {
    setLeadId(userId);
    setCampaignId(campaignId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage);
    onPageSizeChange(newPage);
  };

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    onPageLimitChange(newLimit);
  };
  const useStyles = makeStyles(() => {
    return createStyles({
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      headingtooltip: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "12px!important",
        fontWeight: "bold",
        wordBreak: "break-word",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginLeft: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
      },
      search: {
        color: '#666666',
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        left: 5,
      },
      mail: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "34px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "100%",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const handleEmailOpen = (email: { HtmlBody: any; Subject: any }) => {
    setSelectedEmail(email.HtmlBody);
    setSelectedSubject(email.Subject);
    setEmailDialogOpenOpen(true);
  };

  const handleEmailClose = () => {
    setEmailDialogOpenOpen(false);
    setSelectedEmail("");
  };

  const handleRowClick = (campaignId: string, id: string, campaignName: string, email: any) => {
    const mailData = {
      UsersId: id,
      campaignId: campaignId,
      Name: campaignName,
      email: encodeURIComponent(email),
    }
    getCampaignMailListDataAPICall(mailData);
  };

  function convertTextToHTML1(html: string) {
    const div = document.createElement("div");
    div.innerHTML = html;
    let text = div.textContent || div.innerText || "";

    text = text
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");

    text = text.replace(/\s+/g, " ").trim();

    return <div>{text}</div>;
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, "<br>");

    htmlText = htmlText.replace(/\*([^*]+)\*/g, "<strong>$1</strong>");

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />;
  }

  const truncateText = (text: any, wordLimit: any) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleOpenEmailDialog = (campaignId: any) => {
    const selectedEmailIds = getCampaignDetailsData?.data?.Campaignusers
      ?.filter((campaign: any) => selectedLeads.includes(campaign.users.id))
      .map((campaign: any) => campaign.users.email) || [];
    const selectedNames = getCampaignDetailsData?.data?.Campaignusers
      ?.filter((campaign: any) => selectedLeads.includes(campaign.users.id))
      .map((campaign: any) => campaign.users.first_name) || [];
    const emailData = { campaignId: campaignId, leadNames: selectedNames, emailIds: selectedEmailIds }
    setHandleEmailBox(emailData)
  }

  const handleDelete = (isTrue: any) => {
    const RemoveLeadData = {
      usersIds: [leadId],
      campaignId: campaignId,
      remove: isTrue,
    };
    removeLeadDataAPICall(RemoveLeadData);
  };

  return (
    <>
      {gettingCampaignDetailsData && (
        <CircularProgress color="primary" className={classes.progressBar} />
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography className={classes.titletext}>Campaign Details</Typography>
      </div>
      <Card
        sx={{
          marginBottom: "10px",
          marginTop: "10px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <CardContent
          sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>Name</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>Type</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>Status</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Start Date
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>End Date</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.name || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.type || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.status || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.start_date
                  ? moment(getCampaignDetailsData?.data?.start_date).format(
                    "MM/DD/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.end_date
                  ? moment(getCampaignDetailsData?.data?.end_date).format(
                    "MM/DD/YYYY"
                  )
                  : "NA"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ marginTop: "5px" }}>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Budgeted Cost
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Actual Cost
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Expected Revenue
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Numbers Sent
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Expected Response
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.budgeted_cost ? "$" : ""}
                {getCampaignDetailsData?.data?.budgeted_cost || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.actual_cost ? "$" : ""}
                {getCampaignDetailsData?.data?.actual_cost || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.expected_revenue ? "$" : ""}
                {getCampaignDetailsData?.data?.expected_revenue || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.numbers_sent || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getCampaignDetailsData?.data?.expected_response || "NA"}
              </Typography>
            </Grid>
          </Grid>
          {getCampaignDetailsData?.data?.description && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingtext}
                    sx={{ paddingTop: "0 !important", marginTop: "10px" }}
                  >
                    Description
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subheadingtext}>
                    {getCampaignDetailsData?.data?.description || "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
      {emailLogs?.length > 0 && (
        <Card
          sx={{
            marginTop: "15px",
            backgroundColor: "rgba(242, 248, 254, 1)",
            boxShadow: "none",
          }}
        >
          <Typography
            className={classes.titletext}
            sx={{ margin: "1% 0 0 2% !important" }}
          >
            Email Log
          </Typography>
          <CardContent
            sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
          >
            <TableContainer>
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
                >
                  <TableRow>
                    <TableCell
                      sx={{ padding: "6px 16px", maxWidth: "500px" }}
                    >
                      <span className="mail-info">Email</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Sender</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Date</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emailLogs?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={12} className="tablebodycell">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No Data Available
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    emailLogs?.map(
                      (email: any, index: any) => (
                        <TableRow
                          key={index}
                          sx={{ cursor: "pointer", background: "#ffffff" }}
                          onClick={() =>
                            handleEmailOpen({
                              HtmlBody: email.HtmlBody
                                ? email.HtmlBody
                                : "NA",
                              Subject: email.Subject
                                ? email.Subject
                                : "No Subject",
                            })
                          }
                        >
                          <TableCell
                            className="tablebodycellcomment1"
                            sx={{ maxWidth: "500px" }}
                          >
                            {convertTextToHTML1(
                              email.HtmlBody
                                ? truncateText(email.HtmlBody, 12)
                                : "NA"
                            )}
                          </TableCell>
                          <TableCell className="tablebodycell">
                            {email?.senderName ? email?.senderName : "NA"}
                          </TableCell>
                          <TableCell className="tablebodycell">
                            {email.SubmittedAt
                              ? moment(email.SubmittedAt).format(
                                "MM/DD/YYYY, h:mm:ss A"
                              )
                              : "NA"}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}
      
      <Card
        sx={{
          marginTop: "15px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
          <Box sx={{ marginLeft: "16px", }}>
            <TextField
              id='search'
              name='search'
              // autoFocus
              type='text'
              placeholder='Search'
              InputLabelProps={{ style: { color: '#666666' } }}
              size='small'
              InputProps={{
                style: { ...{ padding: '0' } },
                type: 'search',
                startAdornment: (
                  <Tooltip title='Search' placement='top'>
                    <span>
                      <SearchTwoToneIcon className={classes.search} />
                    </span>
                  </Tooltip>
                ),
              }}
              value={searchTerm}
              onChange={handleSearch}
              onKeyDown={handleKeyPress}
              sx={{ ...searchBar }}
            />
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: "center" }}>
            <Typography className={classes.titletext} sx={{marginRight:'20%'}}>
              Leads
            </Typography>
          </Box>
          <Box sx={{ margin: '0 16px 0 0' }}>
            <span>
              <button
                disabled={selectedLeads.length === 0}
                className={clsx(
                  { [classes.disable]: selectedLeads.length === 0 },
                  classes.mail
                )}
                style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                onClick={() => handleOpenEmailDialog(getCampaignDetailsData?.data?.id)}
              >
                <MailOutlinedIcon sx={{ height: "45%" }} /> Send Email
              </button>
            </span>
          </Box>
        </Box>
        <CardContent
          sx={{ padding: "0px 16px 10px 16px", paddingBottom: "5px !important" }}
        >
          <TableContainer sx={{ backgroundColor: "#ffffff" }}>
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
              >
                <TableRow>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      checked={allSelected}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Lead Name</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Phone</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Email</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Company</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Lead Source</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Lead Status</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getCampaignDetailsData?.data?.Campaignusers?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} className="tablebodycell">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Data Available
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  getCampaignDetailsData?.data?.Campaignusers?.map(
                    (campaign: any, index: any) => (
                      <TableRow
                        key={index}
                        sx={{ background: "#ffffff", cursor: "pointer", }}
                        onClick={() =>
                          handleRowClick(
                            getCampaignDetailsData?.data?.id,
                            campaign.users.id,
                            getCampaignDetailsData?.data?.name,
                            campaign?.users?.email
                          )}
                      >
                        <TableCell 
                        onClick={(event) => event.stopPropagation()} 
                        sx={{ padding: "6px 16px" }}
                        >
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={isSelected(campaign.users.id)}
                            onChange={(event) =>
                              handleSelectLead(event, campaign.users.id)
                            }
                          />
                        </TableCell>
                        <TableCell
                          className="tablebodycellcomment"
                          sx={{
                            maxWidth: "500px",
                            wordBreak: "break-word",
                          }}
                        >
                          {campaign?.users?.first_name
                            ? campaign?.users?.first_name
                            : "NA"}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {campaign?.users?.leadPhone
                            ? campaign?.users?.leadPhone
                            : "-"}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {campaign?.users?.email
                            ? campaign?.users?.email
                            : "NA"}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {/* {campaign?.users?.company
                            ? campaign?.users?.company
                            : "NA"} */}
                          <span>
                            {campaign?.users?.company ?
                              (campaign?.users?.company?.length > 30 ? `${campaign?.users?.company.slice(0, 30)}...` : campaign?.users?.company)
                              :
                              "-"}
                          </span>
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {campaign?.users?.source
                            ? campaign?.users?.source
                            : "NA"}
                        </TableCell>
                        <TableCell className="tablebodycell">
                          {campaign?.users?.follow_up_status
                            ? campaign?.users?.follow_up_status
                            : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycell"
                          sx={{ cursor: "pointer" }}
                        >
                          <Tooltip title="Remove Lead">
                            <IconButton
                              onClick={(e) => {
                                stopPropagation(e);
                                handleOpen(
                                  getCampaignDetailsData?.data?.id,
                                  campaign?.users?.id
                                );
                              }}
                              size="small"
                              sx={{ padding: 0 }}
                            >
                              <ClearOutlinedIcon
                                sx={{ height: "15px", color: "red" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
            <Pagination
              emailTrackData={getCampaignDetailsData}
              handleChangePage={handleChangePage}
              handleLimitChange={handleLimitChange}
              pageChange={pageChange}
              limit={limit}
            />
          </TableContainer>
        </CardContent>
      </Card>

      <Modal
        open={emailDialogOpen}
        aria-labelledby="email-modal-title"
        aria-describedby="email-modal-description"
      >
        <Box className="modalBox">
          <IconButton
            aria-label="close"
            onClick={handleEmailClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography className={classes.headingtext}>
              {selectedSubject || ""}
            </Typography>
          </div>
          <Typography variant="body1" style={{ fontSize: "12px" }}>
            <div>{convertTextToHTML(selectedEmail || "")}</div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="email-modal-title"
        aria-describedby="email-modal-description"
      >
        <Box className="modalBox-removeLead">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute !important",
              right: 6,
              top: 6,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography className={classes.headingtext}>
              Are you sure you want to remove lead?
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              type="submit"
              onClick={() => handleDelete(true)}
            >
              Confirm
            </Button>
            <Button
              className={classes.cancelbtn}
              // variant='contained'
              color="primary"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    gettingCampaignDetailsData:
      campaignDataUI.getCampaign(state).gettingCampaignDetailsData,
    isRemovingLeadDataDone:
      campaignDataUI.getCampaign(state).isRemovingLeadDataDone,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    removeLeadDataAPICall: (data: any) =>
      dispatch(removeLeadDataAPICall.request(data)),
    getCampaignDetailsDataAPICall: (data: any) =>
      dispatch(getCampaignDetailsDataAPICall.request(data)),
    getCampaignMailListDataAPICall: (data: any) =>
      dispatch(getCampaignMailListDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
