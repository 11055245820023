import {
    Button,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
  } from "@mui/material";
  import React, { useState } from "react";
  import { createStyles, makeStyles } from "@material-ui/core";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import {
    TextField,
    MenuItem,
    FormControl,
  } from "@mui/material";
  import { connect } from "react-redux";
  import { getAddUserDataAPICall, getManagerDataAPICall, updateUserDataAPICall } from "../../actions";
  import { dashboardEntity } from "../../reducers";
  
  const EditUser = (props: any) => {
    const { 
        updateUserDataAPICall,
        getManagerNameData,
        user,
        handleEditUserDialogClose, 
    } = props;

    const [selectedRole, setSelectedRole] = useState(user.user_role || "");
    const [userId, setUserId] = useState(user.id || "");
    const role = localStorage.getItem('role');
    
    const useStyles = makeStyles(() => {
      return createStyles({
        addUser: {
          borderRadius: "20px",
          fontFamily: "Montserrat-SemiBold",
          fontSize: "14px",
        },
        actionbtn: {
          height: '20px !important',
          width: '20px !important',
        },
        headingtxt: {
          color: "#000000",
          fontFamily: "Montserrat-SemiBold !important",
          fontSize: "22px!important",
        },
        cancelbtn: {
          borderRadius: "20px!important",
          backgroundColor: "#e0e0e0!important",
          color: "#000000!important",
          marginRight: "10px!important",
          fontFamily: "Montserrat-SemiBold!important",
          fontSize: "14px!important",
          width: "100px!important",
        },
        addLead: {
          cursor: "pointer!important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center!important",
          height: "40px",
          textTransform: "capitalize",
          marginRight: "10px !important",
          border: "0",
          color: "#fff !important",
          background: "rgb(25, 60, 109)",
          borderRadius: "20px !important",
          fontFamily: "Montserrat-Medium !important",
          fontSize: "14px !important",
          width: "10% !important",
          wordBreak: "break-word",
        },
      });
    });
  
    const classes = useStyles();
  
    const formik = useFormik({
      initialValues: {
        first_name: user.first_name || "",
        last_name:  user.last_name || "",
        email:  user.email || "",
        user_role:  user.user_role || "",
        manager:  user.managers_id || "",
        is_active: user.active || false,
      },
      enableReinitialize: true,  
      validationSchema: Yup.object({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string(),
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        user_role: Yup.string().required("Required"),
        manager: Yup.string(),
      }),
      onSubmit: (values, event: any) => {},
    });
  
    const handleSubmit = () => {
      let Userdata = {
        id: userId,
        first_name: formik.values?.first_name,
        last_name: formik.values?.last_name,
        email: formik.values?.email,
        user_role: formik.values?.user_role,
        managers_id: formik.values?.manager,
        active:formik.values?.is_active,
      };
      if (Userdata) {
        updateUserDataAPICall(Userdata);
      }
    };

    const handleRoleChange = (event:any) => {
        const role = event.target.value;
        setSelectedRole(role);
        formik.handleChange(event); 
      };

    const getErrorText = (error: any): string | undefined => {
        return typeof error === "string" ? error : undefined;
      };
  
    return (
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div>
                    <TextField
                      fullWidth
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      sx={{ marginTop: "10px", marginBottom: "15px" }}
                      InputLabelProps={{ style: { top: "-6px" } }}
                      InputProps={{ sx: { borderRadius: 20 } }}
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.first_name &&
                        Boolean(formik.errors.first_name)
                      }
                      helperText={formik.touched.first_name ? getErrorText(formik.errors.first_name) : ""}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <TextField
                      fullWidth
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      required={false}
                      sx={{ marginTop: "10px", marginBottom: "15px" }}
                      InputLabelProps={{ style: { top: "-6px" } }}
                      InputProps={{ sx: { borderRadius: 20 } }}
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      helperText={formik.touched.last_name ? getErrorText(formik.errors.last_name) : ""}
                    />
                  </div>
                </Grid>
              </Grid>
  
              <div>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  sx={{ marginTop: "0", marginBottom: "15px" }}
                  InputLabelProps={{
                    style: { top: "-6px" },
                  }}
                  InputProps={{ sx: { borderRadius: 20 } }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? getErrorText(formik.errors.email) : ""}
                  />
              </div>
              {role === "admin" && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.user_role &&
                        Boolean(formik.errors.user_role)
                      }
                    >
                      <TextField
                        select
                        label="Role"
                        id="user_role"
                        name="user_role"
                        sx={{ marginTop: "0", marginBottom: "15px" }}
                        InputLabelProps={{
                          style: { top: "-6px" },
                        }}
                        InputProps={{ sx: { borderRadius: 20 } }}
                        value={formik.values.user_role}
                        onChange={handleRoleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                        <MenuItem value="Sales Executive">Sales Executive</MenuItem>
                      </TextField>
                      {formik.touched.user_role && getErrorText(formik.errors.user_role) && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {getErrorText(formik.errors.user_role)}
                        </div>
                      )}
                    </FormControl>
                  </div>
                </Grid>
                {/* {selectedRole === "Sales Executive" && (  */}
              <Grid item xs={6}>
                <div>
                <FormControl
                    fullWidth
                    error={
                      formik.touched.user_role &&
                      Boolean(formik.errors.user_role)
                    }
                  >
                    <TextField
                      select
                      label="Select Manager"
                      id="manager"
                      name="manager"
                      disabled={selectedRole === "Sales Manager"}
                      sx={{ marginTop: "0", marginBottom: "15px",}}
                      InputLabelProps={{
                        style: { top: "-6px" },
                      }}
                      InputProps={{ sx: { borderRadius: 20 } }}
                      value={formik.values.manager}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {getManagerNameData?.data?.map((data: any) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.managerName}
                          </MenuItem>
                        ))}
                    </TextField>
                    {formik.touched.manager && getErrorText(formik.errors.manager) && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {getErrorText(formik.errors.manager)}
                        </div>
                      )}
                  </FormControl>
                </div>
              </Grid>
            {/* )} */}
              </Grid>
              )}
              <FormControl component="fieldset" style={{marginLeft: "10px" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel component="legend" style={{marginRight:'10px',color:'#000000'}}>Activate User?</FormLabel>
                <RadioGroup
                    aria-label="is_active"
                    name="is_active"
                    value={formik.values.is_active ? "true" : "false"}
                    onChange={(event:any) => {
                        formik.setFieldValue("is_active", event.target.value === "true");
                    }}
                    row
                >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
                {formik.touched.is_active && getErrorText(formik.errors.is_active) && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                        {getErrorText(formik.errors.is_active)}
                    </div>
                )}
              </div>
            </FormControl>
              <div style={{ display: "flex", justifyContent: "right" }}>
              <Button
                className={classes.cancelbtn}
                // variant='contained'
                color="primary"
                onClick={() => handleEditUserDialogClose()}
              >
                Cancel
              </Button>
                <Button
                  sx={{
                    borderRadius: "20px",
                    fontFamily: "Montserrat-SemiBold",
                    fontSize: "14px",
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
    );
  };
  
  const mapStateToProps = (state: any) => {
    return {
      getAddUserData: dashboardEntity.getDashboard(state).getAddUserData,
      getManagerNameData: dashboardEntity.getDashboard(state).getManagerData,
    };
  };
  
  const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
    return {
        updateUserDataAPICall: (data: any) =>
        dispatch(updateUserDataAPICall.request(data)),
      getManagerNameDataAPICall: (data: any) =>
        dispatch(getManagerDataAPICall.request(data)),
      getAddUserDataAPICall: (data: any) =>
        dispatch(getAddUserDataAPICall.request(data)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
  