import {
    Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Alignment,
  AutoImage,
  AutoLink,
  Autoformat,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  ClassicEditor,
  Code,
  CodeBlock,
  Essentials,
  Font,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  MediaEmbed,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersEssentials,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCellProperties,
  TableProperties,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment-timezone";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { contactDataEntity } from "../../reducers";
import { useState } from "react";
import { createFollowUpDataAPICall } from "../../actions";

const style = {
    containerStyle: {
      marginTop: "0.5rem",
      minHeight: "fit-content",
      maxHeight: "80vh",
      width: "960px",
    },
  };

const Comment = (props: any) => {
  const { 
    leadObjectId,
    leadEmail,
    statusData,
    handleEditDialogClose,
    createFollowUpDataAPICall, 
} = props;

  const [commentError, setCommentError] = useState(false);
  const [commentBody, setCommentBody] = useState("");
  const [selectedFollowUpDate, setSelectedFollowUpDate] = useState<string | null>(null);  
  const useStyles = makeStyles(() => {
    return createStyles({
      datepicker: {
        width: "240px",
      },
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      timepicker: {
        width: "170px",
        marginLeft: "10px!important",
      },
      updateButton: {
        display: "block",
        width: "100px",
      },
      updateButtonContainer: {
        display: "flex",
        justifyContent: "right",
        marginTop: "10px",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();
  const onhandleChange = (e: any) => {
    setSelectedFollowUpDate(moment(e.$d).format("YYYY-MM-DD hh:mm:ss A"));
  };
  const todaysDate = dayjs().startOf("day");
  const today = new Date();
  const tomorrow = new Date(today);
  // tomorrow.setDate(today.getDate() + 1);
  const formattedDate =
    today.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }) +
    ", " +
    today.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  // const dayjsFormattedDate = dayjs(formattedDate).hour(11).minute(0).second(0);
  const dayjsFormattedDate = null;

  const allFieldsFilled = commentBody.trim() !== "" && !commentError;

  const formattednewDate = selectedFollowUpDate ? moment(selectedFollowUpDate).format(
    "YYYY-MM-DD hh:mm:ss A") : null;

  const handleResetError = () => {
    setCommentBody("");
    setSelectedFollowUpDate(null);
    setCommentError(false);
  };

  const handleAddDialog = () => {

    const followUpData = {
      comment: commentBody,
      email: leadEmail,
      leadId: leadObjectId,
      followUpsNextDate: formattednewDate,
      status: statusData,
    };

    if (followUpData) {
      createFollowUpDataAPICall(followUpData);
    //   setIsEditDialogOpen(false);
      handleResetError();
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className={classes.datepicker}>
              <DateTimePicker
                label="Next FollowUp Date"
                // sx={{ borderRadius: '20px!important', }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "20px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "14px",
                    marginTop: "-6px",
                  },
                }}
                slotProps={{
                  textField: {
                    required: false,
                    error: false,
                  },
                }}
                value={selectedFollowUpDate}
                onChange={onhandleChange}
                // minDate={todaysDate}
              />
            </Box>
          </LocalizationProvider>
        </div>
      </div>
      {/* <TextField
                id="outlined-multiline-static"
                label="Comment"
                required={true}
                multiline
                rows={6}
                value={commentData}
                onChange={handleChangeForComment}
                error={commentError}
                helperText={commentError ? "Comment is required" : ""}
                InputProps={{ sx: { borderRadius: 4 } }}
              /> */}
                              <Box 
                sx={{marginTop:'10px',marginBottom:'5px'}}
                className="custom_ck"
                >
                    <CKEditor
                    editor={ClassicEditor}
                    config={{
                      plugins: [
                        Image,
                        ImageCaption,
                        ImageStyle,
                        ImageResize,
                        LinkImage,
                        Bold,
                        Code,
                        Strikethrough,
                        Subscript,
                        Superscript,
                        Underline,
                        Autoformat,
                        TextTransformation,
                        Link,
                        PasteFromOffice,
                        Font,
                        AutoLink,
                        Essentials,
                        Italic,
                        Mention,
                        Paragraph,
                        Undo,
                        Indent,
                        IndentBlock,
                        BlockQuote,
                        CodeBlock,
                        Heading,
                        Highlight,
                        HorizontalLine,
                        List,
                        MediaEmbed,
                        PageBreak,
                        SelectAll,
                        SpecialCharacters,
                        SpecialCharactersEssentials,
                        Table,
                        Alignment,
                        ImageInsert,
                        AutoImage,
                        TableProperties,
                        TableCellProperties,
                        RemoveFormat,
                        Base64UploadAdapter,
                      ],
                      toolbar: [
                        "undo",
                        "redo",
                        "|",
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        "specialCharacters",
                        "|",
                        "alignment",
                        "fontSize",
                        "fontFamily",
                        "fontColor",
                        "highlight",
                        "|",
                        "insertImage",
                        "insertTable",
                        "mediaEmbed",
                        "bulletedList",
                        "numberedList",
                        "outdent",
                        "indent",
                        "subscript",
                        "superscript",
                        "strikethrough",
                        "heading",
                        "codeBlock",
                        "horizontalLine",
                        "toggleImageCaption",
                        "imageTextAlternative",
                        "removeFormat",
                        "mergeTableCells",
                      ],
                      image: {
                        insert: {
                          type: "auto",
                          integrations:['url']
                        },
                      },
                      fontSize: {
                        options: [
                          9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                        ],
                        supportAllValues: true,
                      },
                      highlight: {
                        options: [
                          {
                            model: "yellowMarker",
                            class: "marker-yellow",
                            title: "Yellow marker",
                            color: "var(--ck-highlight-marker-yellow)",
                            type: "marker",
                          },
                        ],
                      },
                    }}
                    data={commentBody}
                    onChange={(event: any, editor: any) => {
                      setCommentBody(editor.getData());
                    }}
                  />
                </Box>
              <div className={classes.updateButtonContainer}>
                <Button
                  className={classes.cancelbtn}
                  // variant='contained'
                  color="primary"
                  onClick={() => handleEditDialogClose()}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.updateButton}
                  variant="contained"
                  sx={{
                    borderRadius: "20px",
                    fontFamily: "Montserrat-SemiBold",
                    fontSize: "14px",
                  }}
                  color="primary"
                  disabled={!allFieldsFilled}
                  onClick={() => handleAddDialog()}
                >
                  + Add
                </Button>
              </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getContactDetailsData:
      contactDataEntity.getContact(state).getContactDetailsData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    createFollowUpDataAPICall: (data: any) =>
        dispatch(createFollowUpDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
