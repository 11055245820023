import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
// import { isLoggedIn } from '../../auth/Auth'
import {
  CenterContainer,
  LeftContainer,
  MainContainer,
  RightContainer,
} from './Styles'
import { connect } from 'react-redux'
import { getUserLoginUser } from '../../actions'
import { LoginAuthData } from '../Types'
import PropTypes from 'prop-types'
import { userAuthenticationEntity, userAuthenticationUI } from '../../reducers'
import { toast } from 'react-toastify'
import Footer from '../Common/Footer'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { GetLoginLeftImage } from '../../utils/StaticData'
import Tudip_CRM_Logo from '../../assets/images/TudipCRMLogo.png'
// import MySVG from '../../assets/images/Tudip Full Logo.png'

function Login({ getLoginUser, loginStatus, getUserLogOut }: any) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [isEmailTouched, setIsEmailTouched] = useState(false)
  const [isPasswordTouched, setIsPasswordTouched] = useState(false)

  const emailInputRef = useRef<HTMLInputElement>(null)

  const leftpartloginpage = {
  marginTop: '50px',
}

  const loginbtn = {
    width:'100%',
    borderRadius:'20px',
  }

  useEffect(() => {

    if (loginStatus) {
      toast.success('Logged in successfully')
      navigate('/home/dashboard')
      getUserLogOut()
    }
  }, [loginStatus])

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value
    setEmail(inputEmail)
    setIsEmailTouched(true)

    if (!validateEmail(inputEmail) && inputEmail.length > 1) {
      setEmailError('Invalid email address!')
    } else if (!validateEmail(inputEmail) && inputEmail.length < 1) {
      setEmailError('Please enter email!')
    } else {
      setEmailError('')
    }
  }

  const handleEmailBlur = (e: any) => {
    setEmail(e.target.value)
    const inputPassword = e.target.value
    setEmail(inputPassword)

    if (!validatePassword(inputPassword) && inputPassword.length !== 0) {
      setEmailError('Please enter email!')
    } else if (!validatePassword(inputPassword) && inputPassword.length < 1) {

      setEmailError('Please enter email!')
    } else {
      setEmailError('')
    }
  }

  const handlePasswordBlur = () => {
    setIsPasswordTouched(true)

    if (password.trim() === '') {
      setPasswordError('Please enter a password!')
    } else if (password.length < 8) {
      setPasswordError('')
    } else {
      setPasswordError('')
    }
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputPassword = e.target.value
    setPassword(inputPassword)
    setIsPasswordTouched(true)

    if (!validatePassword(inputPassword) && inputPassword.length !== 0) {
      setPasswordError('Password must be at least 6 characters long!')
    } else if (!validatePassword(inputPassword) && inputPassword.length < 1) {
      setPasswordError('Please enter password!')
    } else {
      setPasswordError('')
    }
  }

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const validatePassword = (password: string) => {
    return password.length >= 6
  }

  const handleSubmit = () => {
    setIsEmailTouched(true)
    setIsPasswordTouched(true)

    if (!email.trim()) {
      setEmailError('Please enter email!')
    }

    if (!password.trim()) {
      setPasswordError('Please enter password!')
    }

    if (email.trim() && password.trim()) {
      getLoginUser({ email: email, password })
    }
  }

  const handleFormSubmit = (event:any) => {
    event.preventDefault(); 
    handleSubmit(); 
  };

  // useEffect(() => {
  //   emailInputRef.current?.focus()
  // }, [])

  return (
    <MainContainer>
      <LeftContainer sx={leftpartloginpage}>
        <CenterContainer  src={GetLoginLeftImage}></CenterContainer>
       </LeftContainer>
      <RightContainer>
        <div style={{margin:'40px 30px 20px 0'}}>
          <img src={Tudip_CRM_Logo} alt='Tudip Logo' height='120px' />
        </div>
        <form onSubmit={handleFormSubmit}>
        <TextField
          id='email'
          label={t('email_address')}
          name='Newemail'
          autoComplete='off'
          value={email}
          onChange={handleEmailInput}
          onBlur={handleEmailBlur}
          onFocus={() => setIsEmailTouched(true)}
          helperText={isEmailTouched ? emailError : ''}
          error={isEmailTouched && !!emailError}
          sx={{marginBottom:'10px'}}
          InputLabelProps={{
            style: { top: '-6px'},
          }}
          InputProps={{
            sx: { borderRadius: 20 },
            endAdornment: (
              <InputAdornment position='end'>
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
          inputRef={emailInputRef}
        />
        <TextField
          id='password'
          type={showPassword ? 'text' : 'password'}
          label={t('password')}
          name='Newpassword'
          autoComplete='off'
          value={password}
          onChange={handlePasswordInput}
          onBlur={handlePasswordBlur}
          onFocus={() => setIsPasswordTouched(true)}
          sx={{marginTop:'10px'}}
          helperText={isPasswordTouched ? passwordError : ''}
          error={isPasswordTouched && !!passwordError}
          InputLabelProps={{
            style: { top: '-6px'},
          }}
          InputProps={{
            sx: { borderRadius: 20 },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
         <br/>
        <Button type='submit'fullWidth sx={{...loginbtn}}>
          {t('login')}
        </Button>
        </form>
        <br/>
        {/* <RememberMeOuterDiv>
          <FormControlLabel control={<Checkbox />} label={t('remember_me')} />
          <NavLink
            to='/site/forgotPassword'
            style={(isActive) => ({
              color: isActive ? 'red' : 'red',
            })}
          >
            {t('forgot_password')}
          </NavLink>
        </RememberMeOuterDiv> */}
        <Box sx={{height:'100px', marginTop: '5px',}}></Box>
        <Footer />
      </RightContainer>
    </MainContainer>
  )
}

Login.propTypes = {
  loginStatus: PropTypes.bool,
  loginUserData: PropTypes.object,
  getLoginUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state: RootState) => {
  return {
    loginStatus: userAuthenticationUI.getUserAuthentication(state).isUserAuthenticationDone,
    loginUserData: userAuthenticationEntity.getUserAuthentication(state).getUserAuthentication,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getLoginUser: (data: LoginAuthData) => dispatch(getUserLoginUser.request(data)),
    getUserLogOut: () => {
      dispatch(getUserLoginUser.reset())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)


