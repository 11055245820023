import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { 
  ANALYTICS_GRAPH_DATA,
  ANALYTICS_BAR_GRAPH_DATA,
  ANALYTICS_NAME_DATA,
  TARGET_GAUGE_DATA,
  ANALYTICS_PIE_CHART_DATA,

 } from '../actions/actiontypes'

const { REQUEST,  } = actionTypes


//write sagas function

function* handleGetAnalyticsGraphData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAnalyticsDataEndPoint,
      data.data,
    )
      
    yield sendPayload(apiResponse, ANALYTICS_GRAPH_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, ANALYTICS_GRAPH_DATA)
  }
}

function* handleGetAnalyticsBarGraphData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAnalyticsBarDataEndPoint,
      data.data,
    )
      
    yield sendPayload(apiResponse, ANALYTICS_BAR_GRAPH_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, ANALYTICS_BAR_GRAPH_DATA)
  }
}

function* handleGetAnalyticsNameData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAnalyticsNameDataEndPoint,
      data.data,
    )
      
    yield sendPayload(apiResponse, ANALYTICS_NAME_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, ANALYTICS_NAME_DATA)
  }
}

function* handleGetTargetGaugeData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getTargetGaugeDataEndPoint,
      data.data,
    )
      
    yield sendPayload(apiResponse, TARGET_GAUGE_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, TARGET_GAUGE_DATA)
  }
}

function* handleGetTemperaturePieChartData(data: any) {
  try{    
    const apiResponse: Generator<string,number,string> = yield call(
      ApiService.getTemperaturePieChartDataEndPoint,
      data.data
    )
    yield sendPayload(apiResponse,ANALYTICS_PIE_CHART_DATA)
  }catch(e){
    yield sendPayload(e,ANALYTICS_PIE_CHART_DATA)
  }
}



export const sagas = {
  //watcher come here
  watchGetAnalyticsGraphData: takeLatest(actionTypes.ANALYTICS_GRAPH_DATA[REQUEST], handleGetAnalyticsGraphData),
  watchGetAnalyticsNameData: takeLatest(actionTypes.ANALYTICS_NAME_DATA[REQUEST], handleGetAnalyticsNameData),
  watchGetAnalyticsBarGraphData: takeLatest(actionTypes.ANALYTICS_BAR_GRAPH_DATA[REQUEST], handleGetAnalyticsBarGraphData),
  watchGetTargetGaugeData: takeLatest(actionTypes.TARGET_GAUGE_DATA[REQUEST], handleGetTargetGaugeData),
  watchGetTemperaturePieChartData: takeLatest(ANALYTICS_PIE_CHART_DATA[REQUEST], handleGetTemperaturePieChartData),
}
