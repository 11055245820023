import * as React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from "@mui/material";
import {
  AppBarTheme,
  HeaderLogo,
  HeaderProfileName,
  HeaderRightContent,
  HeaderToolbar,
  loaderProps,
} from "./CommonStyles";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import SearchBox from "./SearchBox";
import { CurrentLoginUserProfile, CRMLogo } from "../../utils/StaticData";
import { connect } from "react-redux";
import { changePasswordDataAPICall, getUserLoginUser } from "../../actions";
import { dashboardEntity, dashboardUI, loginUserUI } from "../../reducers";
import { createStyles, makeStyles } from "@material-ui/core";
import { googleLogout } from "@react-oauth/google";
import CloseIcon from "@mui/icons-material/Close";
import ChangePassword from "../AddUser/ChangePassword";
import { toast } from "react-toastify";
import MeetingCalendar from "../Leads/MeetingCalendar";
// import { useHistory } from 'react-router-dom';

const settings = ["Change Password", "Sign Out"];

const Header = (props: any) => {
  const {
    userDetails,
    getUserLogOut,
    onSearchChange,
    changePasswordData,
    resetChangePassword,
    isChangePasswordDataDone,
  } = props;
  // let history = useHistory();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [openCalendarDialog, setCalendarDialog] = React.useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (isChangePasswordDataDone) {
      if (changePasswordData?.error?.length) {
        toast.error(changePasswordData?.message);
      } else {
        toast.success(changePasswordData?.message);
      }
      setIsPasswordDialogOpen(false);
      resetChangePassword();
      setAnchorElUser(null);
    }
  }, [isChangePasswordDataDone]);

  const useStyles = makeStyles(() => {
    return createStyles({
      addLead: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        width: "10%",
      },
      calendar: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        width: "85%",
      },
    });
  });

  const classes = useStyles();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const navigate = useNavigate();

  const handleCloseUserMenu = () => {
    setProcessing(true);
    getUserLogOut();
    googleLogout();
    localStorage.clear();
    sessionStorage.removeItem("selectedDate");
    sessionStorage.removeItem("selectedToDate");
    sessionStorage.removeItem("selectedFilterOptions");
    sessionStorage.removeItem("userSearch");
    sessionStorage.removeItem("selectedDate1");
    sessionStorage.removeItem("selectedToDate1");
    sessionStorage.removeItem("selectedFilterOptions1");
    setTimeout(() => {
      setProcessing(false);
      navigate("/");
    }, 3000);
    setAnchorElUser(null);
  };

  const name = localStorage.getItem("name");

  const handleCreateLead = () => {
    // setIsEditDialogOpen(true)
    navigate(`/home/dashboard/addlead`);
  };

  const handleCalendarClick = () => {
    setCalendarDialog(true);
  };

  const handleSearchChange = (searchValue: string) => {
    const trimmedValue = searchValue.trim();

    if (trimmedValue !== "") {
      onSearchChange(trimmedValue);
      navigate(`/home/dashboard?search=${trimmedValue}`);
    } else {
      navigate("/home/dashboard");
    }
  };

  const handlePasswordDialogOpen = () => {
    setIsPasswordDialogOpen(true);
  };

  const handlePasswordDialogClose = () => {
    setIsPasswordDialogOpen(false);
  };

  const handleCalendarCloseDialog = () => {
    setCalendarDialog(false);
  };

  return (
    <>
      {processing && (
        <Dialog open={processing} PaperProps={{ style: loaderProps }}>
          <CircularProgress color="secondary" />
        </Dialog>
      )}
      <ThemeProvider theme={AppBarTheme}>
        <AppBar position="static">
          <Container maxWidth="xl">
            <HeaderToolbar>
              <Box>
                <a style={{ margin: -10 }} href="/home/dashboard">
                  <HeaderLogo src={CRMLogo} />
                </a>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SearchBox onSearchChange={handleSearchChange} />
              </Box>
              <Tooltip title="Calendar">
                <span>
                  <button
                    className={classes.calendar}
                    onClick={handleCalendarClick}
                  >
                    <CalendarMonthOutlinedIcon sx={{ height: "50%" }} />
                  </button>
                </span>
              </Tooltip>
              {location.pathname !== "/home/dashboard/addlead" && (
                <button
                  className={classes.addLead}
                  onClick={() => handleCreateLead()}
                >
                  <AddOutlinedIcon sx={{ height: "45%" }} />
                  Add Lead
                </button>
              )}
              <HeaderRightContent>
                <span style={HeaderProfileName}> {name} </span>
                <Tooltip title="">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        userDetails?.image_path
                          ? userDetails?.image_path
                          : CurrentLoginUserProfile
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={(e) => setAnchorElUser(null)}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={
                        setting === "Sign Out"
                          ? handleCloseUserMenu
                          : handlePasswordDialogOpen
                      }
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </HeaderRightContent>
            </HeaderToolbar>
            <Dialog
              open={isPasswordDialogOpen}
              onClose={handlePasswordDialogClose}
              fullWidth
              maxWidth={false}
              PaperProps={{
                style: {
                  width: "40%",
                  maxWidth: "40%",
                },
              }}
            >
              <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                Change Password
                <IconButton
                  aria-label="close"
                  onClick={handlePasswordDialogClose}
                  sx={{
                    position: "absolute !important",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Divider className="divider" />
              <DialogContent sx={{ padding: "10px 15px !important" }}>
                <ChangePassword
                  handlePasswordDialogClose={handlePasswordDialogClose}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              open={openCalendarDialog}
              onClose={handleCalendarCloseDialog}
              fullWidth
              maxWidth={false}
              PaperProps={{
                style: {
                  width: "65%",
                  maxWidth: "65%",
                },
              }}
            >
              <DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleCalendarCloseDialog}
                  sx={{
                    position: "absolute !important",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent sx={{ padding: "10px 15px !important" }}>
                <MeetingCalendar />
              </DialogContent>
            </Dialog>
          </Container>
        </AppBar>
      </ThemeProvider>
    </>
  );
};
// export default Header
const mapStateToProps = (state: any) => {
  return {
    loginStatus: loginUserUI.getLoginUserData(state).isUserLoginDone,
    changePasswordData: dashboardEntity.getDashboard(state).changePasswordData,
    isChangePasswordDataDone:
      dashboardUI.getDashboard(state).isChangePasswordDataDone,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    // getUser: () => {
    //   dispatch(getUserDetails.request())
    // },
    getUserLogOut: () => {
      dispatch(getUserLoginUser.reset());
    },
    resetChangePassword: () => dispatch(changePasswordDataAPICall.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
