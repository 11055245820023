import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { 
  DOWNLOAD_LEAD_FILE, 
  LEADS_DETAILS_BY_ID, 
  LEADS_STATUS_DATA,
  SET_LEADS_COLUMN_DATA,
} from '../actions/actiontypes'

const { REQUEST, LEADS_DATA, } = actionTypes


//write sagas function

function* handleGetLeadsData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLeadsDataEndPoint,
      data.data,
    )
      
    yield sendPayload(apiResponse, LEADS_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, LEADS_DATA)
  }
}

function* handleSetLeadsColumnData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.setLeadsColumnDataEndPoint,
      data.data,
    )
      
    yield sendPayload(apiResponse, SET_LEADS_COLUMN_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, SET_LEADS_COLUMN_DATA)
  }
}

function* handleGetLeadsStatusData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLeadsStatusData,
      data.data,
    )

    yield sendPayload(apiResponse, LEADS_STATUS_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, LEADS_STATUS_DATA)
  }
}

// function* handleCreateLeadDataAPI1(data: any) {
//   try {
//     console.log('aa gya ----', data)
//     const apiResponse: Generator<string, number, string> = yield call(
//       ApiService.createLeadData,
//       data.data,
//     )
//     console.log('aa gya', apiResponse)
//     // debugger
//     yield sendPayload(apiResponse, CREATE_LEAD_DATA)
//   } catch (e) {
//     // debugger
//     yield sendPayloadFailure(e, CREATE_LEAD_DATA)
//   }
// }

function* handleGetLeadsDetails(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLeadsDetailsById,
      data.data.leadId,
    )
    yield sendPayload(apiResponse, LEADS_DETAILS_BY_ID)
  } catch (e) {
    yield sendPayloadFailure(e, LEADS_DETAILS_BY_ID)
  }
}

function* handleDownloadLeadFile(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDownloadableLeadFileURL,
      data.data,
    )
    yield sendPayload(apiResponse, DOWNLOAD_LEAD_FILE)
  } catch (e) {
    yield sendPayloadFailure(e, DOWNLOAD_LEAD_FILE)
  }
}



export const sagas = {
  //watcher come here
  watchGetLeadsData: takeLatest(actionTypes.LEADS_DATA[REQUEST], handleGetLeadsData),
  watchSetLeadsColumnData: takeLatest(actionTypes.SET_LEADS_COLUMN_DATA[REQUEST], handleSetLeadsColumnData),
  watchGetLeadsStatusData: takeLatest(actionTypes.LEADS_STATUS_DATA[REQUEST], handleGetLeadsStatusData),
  watchGetLeadsDetails: takeLatest(actionTypes.LEADS_DETAILS_BY_ID[REQUEST], handleGetLeadsDetails),
  watchDownloadLeadFile: takeLatest(actionTypes.DOWNLOAD_LEAD_FILE[REQUEST], handleDownloadLeadFile),
  // watchCreateLeadDataAPI1: takeLatest(actionTypes.CREATE_LEAD_DATA[REQUEST], handleUploadFileAPI11),
}
