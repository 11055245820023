import {
  uploadFileAPICall,
  getLeadsDataAPICall,
  createLeadDataAPICall,
  updateLeadDataAPICall,
  sendBulkEmailAPICall,
  getLeadsStatusDataAPICall,
  setLeadsColumnDataAPICall,
} from "../../actions";
import { CircularProgress, Divider, IconButton,ListItemText,Menu,MenuItem,Tooltip,} from "@mui/material";
import countriesData from '../Leads/CountriesandStates.json';
import * as options from './constant';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { TreeSelect } from 'primereact/treeselect';
import { TreeNode } from 'primereact/treenode';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import OuterTableStatus from "./LeadTableStatus";
import "./eta.css";
import Pagination from "./pagination";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  leadsDataEntity,
  dashboardUI,
  leadDataUI,
  dashboardEntity,
} from "../../reducers";
import { Typography, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import clsx from "clsx";

const moment = require("moment-timezone");

interface SearchData {
  limit: number;
  page: number;
  follow_up_status?: any;
  domain?: any;
  searchString: string;
  fromDate?: string;
  toDate?: string;
  country?: any;
  userIds?: any;
  filter?: any;
  temperature?: any;
  source?:any;
}

const TrackEmail = (props: any) => {
  const {
    getLeadsDataAPICall,
    getLeadsStatusDataAPICall,
    setLeadsColumnDataAPICall,
    isSetLeadsColumnDataDone,
    setLeadsColumnData,
    resetSetLeadColumnData,
    getLeadsStatusData,
    getLeadsData,
    getUploadDetails,
    uploadFileAPICall,
    isUploadFileDone,
    isUploadingData,
    resetUploadingFile,
    resetgetLeadsDataAPICall,
    isBulkEmailSending,
    resetLeadData,
    isCreateLeadDone,
    resetCreateLead,
    resetLeadsData,
    isGetLeadsDataDone,
    isUpdateLeadDone,
    getUpdateLeadDetails,
    resetUpdateLead,
    isBulkEmailSendDone,
    ResetsendBulkEmailAPICall,
    isUpdatingLeadData,
    getSendBulkEmailData,
    // handleOpenEmailDialog,
  } = props;  

  const role = localStorage.getItem("role");
  const columnPreference:any = localStorage.getItem("preference");  
  const [userSearch, setUserSearch] = useState("");
  const [pageChange, setPageChange] = useState(1);
  const [selectedDate, setSelctedDate] = useState(
    sessionStorage.getItem("selectedDate") ||
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [selectedToDate, setToSelctedDate] = useState(
    sessionStorage.getItem("selectedToDate") ||
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [messageId, setMessageId] = useState("");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isEmailErrorDialogOpen, setIsEmailErrorDialogOpen] = useState(false);
  const [status, setStatus] = useState<string[]>([]);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openCampaignDialog, setOpenCamapaignDialog] = useState(false);
  const [openOwnerDialog, setOpenOwnerDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [limit, setLimit] = useState(100);
  const [isChecked, setIsChecked] = useState(false);
  const [apiCallTrigger, setApiCallTrigger] = useState(false);
  const [failedEmails, setFailedEmails] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<any>(
    JSON.parse(sessionStorage.getItem("selectedFilterOptions") || "{}") || {});
  const [filterOptions1, setFilterOptions1] = useState<TreeNode[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempColumnVisibility, setTempColumnVisibility] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);  

  useEffect(() => {
    const statusData = {
     type:"1"
    };
    getLeadsStatusDataAPICall(statusData);
  }, []);

  // useEffect(() => {
  //   if (getLeadsStatusData) {
  //   const countries = countriesData?.map((country: any) => ({
  //     key: country.name,
  //     label: country.name,
  //     data: country.name,
  //   }));

  //   const domains = getLeadsStatusData?.domains?.map((domain: string) => ({
  //     key: domain,
  //     label: domain,
  //     data: domain
  //   }));

  //   const statuses = getLeadsStatusData?.statuses?.map((status: string) => ({
  //     key: status,
  //     label: status,
  //     data: status
  //   }));

  //   const assignee = getLeadsStatusData?.OwnersName?.map((owner: any) => ({
  //     key: owner.id,
  //     label: owner.name,
  //     data: owner.name
  //   }));

  //   const temperature = getLeadsStatusData?.temperature?.map((temperature: any) => ({
  //     key: temperature,
  //     label: temperature,
  //     data: temperature
  //   }));

  //   const source = getLeadsStatusData?.source?.map((source: any) => ({
  //     key: source,
  //     label: source,
  //     data: source
  //   }));
  //     const filterOptions = [
  //       {
  //         key: 'country',
  //         label: 'Country',
  //         children: countries
  //       },
  //       {
  //         key: 'domain',
  //         label: 'Domain',
  //         children: domains
  //       },
  //       {
  //         key: 'status',
  //         label: 'Status',
  //         children: statuses
  //       },
  //       {
  //         key: 'temperature',
  //         label: 'Temperature',
  //         children: temperature
  //       },
  //       {
  //         key: 'source',
  //         label: 'Source',
  //         children: source
  //       },
  //     ];
  
  //     if (role === "sales_manager" || role === "admin") {
  //       filterOptions.splice(filterOptions.length - 1, 0, {
  //           key: 'assignee',
  //           label: 'Owner',
  //           children: assignee
  //       });
  //     }
  //     setFilterOptions1(filterOptions);
  //     setDataLoaded(true);
  //   }
  //   if(columnPreference){
  //     setTempColumnVisibility(JSON.parse(columnPreference))
  //   }
  // }, [getLeadsStatusData,columnPreference]);

  const handleCheckboxChange = (isChecked: boolean) => {
    setIsChecked(isChecked);
  };

  const handleSelectAll = (isCheckedAll: boolean) => {
    setIsChecked(isCheckedAll);
  };

  useEffect(() => {
    if (isGetLeadsDataDone) {
      resetgetLeadsDataAPICall();
    } else if (isSetLeadsColumnDataDone){
      toast.success(setLeadsColumnData?.message);
      resetSetLeadColumnData();
    }
  }, [isGetLeadsDataDone,isSetLeadsColumnDataDone]);

  useEffect(() => {
    setIsChecked(false);
  }, [apiCallTrigger]);

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
  };

  const handleSelectItemsChange = (selectedItems: any) => {
    setSelectedStatus(selectedItems);
  };

  const handleSelectDomainChange = (selectedOptions: any) => {
    setSelectedDomain(selectedOptions);
  };

  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  let searchValue = searchParams.get("search") || "";
  useEffect(() => {
    searchParams = new URLSearchParams(location.search);
    searchValue = searchParams.get("search") || "";
    setUserSearch(searchValue);
  }, [location.search]);

  useEffect(() => {
    sessionStorage.removeItem("selectedDate");
    sessionStorage.removeItem("selectedToDate");
    sessionStorage.removeItem("selectedFilterOptions");
    sessionStorage.removeItem("userSearch");
  }, []);

  
  useEffect(() => {
    if (dataLoaded){
      const safeSelectedFilterOptions1 = selectedFilterOptions && typeof selectedFilterOptions === 'object' ? selectedFilterOptions : {};
    
      const selectedCountries:any = [];
      const selectedDomains:any = [];
      const selectedStatuses:any = [];
      const selectedAssignees:any = [];
      const selectedtemperatures:any = [];
      const selectedsources:any = [];
    
      Object.keys(safeSelectedFilterOptions1).forEach(key => {
        const option = safeSelectedFilterOptions1[key];
        if (option.checked) {
          const parent = filterOptions1.find(parent => parent.children?.some(child => child.key === key));
          if (parent) {
            switch (parent.key) {
              case 'country':
                selectedCountries.push(key);
                break;
              case 'domain':
                selectedDomains.push(key);
                break;
              case 'status':
                selectedStatuses.push(key);
                break;
              case 'assignee':
                selectedAssignees.push(key);
                break;
              case 'temperature':
                selectedtemperatures.push(key);
                break; 
              case 'source':
                selectedsources.push(key);
                break;      
              default:
                break;
            }
          }
        }
      });
    const searchData: SearchData = {
      limit: limit,
      page: pageChange,
      follow_up_status: selectedStatuses.join(','),
      domain: selectedDomains.join(','),
      country: selectedCountries.join(','),
      userIds: selectedAssignees.join(','),
      temperature: selectedtemperatures.join(','),
      source:selectedsources.join(','),
      searchString: userSearch,
    };
    if (userSearch === "") {
      searchData.fromDate =
        selectedDate !== undefined
          ? selectedDate
          : moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
      searchData.toDate =
        selectedToDate !== undefined
          ? selectedToDate
          : moment(new Date()).format("YYYY-MM-DD");
    }

    if (!isUploadFileDone) {
      getLeadsDataAPICall(searchData);
      setApiCallTrigger((prev) => !prev);
    } else {
      if (getUploadDetails?.error?.length) {
        setIsErrorDialogOpen(true);
        toast.success(getUploadDetails?.message);
      } else {
        toast.success("Data uploaded successfully");
      }
      resetUploadingFile();
    }
  }
  }, [
    userSearch,
    pageChange,
    status,
    selectedDomain,
    selectedDate,
    selectedToDate,
    limit,
    isUploadFileDone,
    selectedFilterOptions,
    filterOptions1,
    // dataLoaded,
  ]);

  useEffect(() => {
    if (getLeadsStatusData) {
    const countries = countriesData?.map((country: any) => ({
      key: country.name,
      label: country.name,
      data: country.name,
    }));

    const domains = getLeadsStatusData?.domains?.map((domain: string) => ({
      key: domain,
      label: domain,
      data: domain
    }));

    const statuses = getLeadsStatusData?.statuses?.map((status: string) => ({
      key: status,
      label: status,
      data: status
    }));

    const assignee = getLeadsStatusData?.OwnersName?.map((owner: any) => ({
      key: owner.id,
      label: owner.name,
      data: owner.name
    }));

    const temperature = getLeadsStatusData?.temperature?.map((temperature: any) => ({
      key: temperature,
      label: temperature,
      data: temperature
    }));

    const source = getLeadsStatusData?.source?.map((source: any) => ({
      key: source,
      label: source,
      data: source
    }));
      const filterOptions = [
        {
          key: 'country',
          label: 'Country',
          children: countries
        },
        {
          key: 'domain',
          label: 'Domain',
          children: domains
        },
        {
          key: 'status',
          label: 'Status',
          children: statuses
        },
        {
          key: 'temperature',
          label: 'Temperature',
          children: temperature
        },
        {
          key: 'source',
          label: 'Source',
          children: source
        },
      ];
  
      if (role === "sales_manager" || role === "admin") {
        filterOptions.splice(filterOptions.length - 1, 0, {
            key: 'assignee',
            label: 'Owner',
            children: assignee
        });
      }
      setFilterOptions1(filterOptions);
      setDataLoaded(true);
    }
    if(columnPreference){
      setTempColumnVisibility(JSON.parse(columnPreference))
    }
  }, [getLeadsStatusData,columnPreference]);

  useEffect(() => {
    const searchData: SearchData = {
      limit: limit,
      page: pageChange,
      fromDate:
        selectedDate ||
        moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
      toDate: selectedToDate || moment(new Date()).format("YYYY-MM-DD"),
      searchString: userSearch,
    };
    if (userSearch === "") {
      searchData.fromDate =
        selectedDate ||
        moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
      searchData.toDate =
        selectedToDate || moment(new Date()).format("YYYY-MM-DD");
    }

    if (isCreateLeadDone) {
      // getLeadsDataAPICall(searchData)
      toast.success("Lead Created successfully");
      resetCreateLead();
    }
  }, [isCreateLeadDone]);

  useEffect(() => {
    if (isUpdateLeadDone) {
      if (getUpdateLeadDetails?.error?.length) {
        toast.success(getUpdateLeadDetails?.message);
      } else {
        toast.success(getUpdateLeadDetails?.message);
      }
      setOpenStatusDialog(false);
      setOpenOwnerDialog(false);
      setOpenCamapaignDialog(false);
      setIsChecked(false);
      resetUpdateLead();
    } else if (isBulkEmailSendDone) {
      if (getSendBulkEmailData?.failedEmails?.length) {
        setIsEmailErrorDialogOpen(true);
        setFailedEmails(getSendBulkEmailData?.failedEmails);
        toast.success(getSendBulkEmailData?.message);
      } else {
        toast.success(getSendBulkEmailData?.message);
      }
      setIsChecked(false);
      ResetsendBulkEmailAPICall();
    }
  }, [isUpdateLeadDone, isBulkEmailSendDone]);

  const toggleColumn = (key: any) => {
    setTempColumnVisibility((prev: any) =>
      prev.map((col: any) =>
        col.key === key ? { ...col, value: !col.value } : col
      )
    );
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    localStorage.setItem("preference", JSON.stringify(tempColumnVisibility));
    const columnData = {
      preference: tempColumnVisibility,
    }
    setLeadsColumnDataAPICall(columnData)
    handleClose();
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage);
  };

  const useStyles = makeStyles((theme) => {
    return createStyles({
      datepicker: {
        margin: "10px",
        width: "160px",
        borderRadius: "20px",
      },
      dottedDivider: {
        width: "100%",
        textAlign: "center",
        borderTop: "2px dotted" + theme.palette.divider,
        margin: "20px 0",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      countText: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        color: "rgba(0,0,0,0.38)!important",
        wordBreak: "break-word",
      },
      updateButton: {
        display: "block",
        textTransform: "capitalize",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "15px",
        backgroundColor: "rgb(25, 60, 109)",
      },
      cancelButton: {
        marginRight: "10px",
        display: "block",
        textTransform: "capitalize",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "15px",
      },
      updateButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
      },
      headerContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        marginRight:'12px'
      },
      createAccoutContainer: {
        display: "flex",
        alignItems: "center",
        height: "50%",
        width: "100%",
        justifyContent: "right",
        gap: "2px",
      },
      titletxt: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "18px!important",
        fontWeight: 500,
      },
      addLead: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        marginLeft: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "10%",
      },
      apply: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "35px",
        textTransform: "capitalize",
        marginLeft: "auto",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "40%",
      },
      column: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "10%",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      upload: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        border: "1px solid rgba(20, 56, 113, 1)",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        width: "10%",
        whiteSpace: "nowrap",
      },
      svgCreateAccout: {
        height: "33px",
        display: "flex",
        width: "20%",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        background: "#0279ff",
        boxShadow: "0 -2px 0 0 inset #0061ca!important",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
      },
      progressBarContainer: {
        position: "relative",
      },
      progressBar: {
        position: "absolute",
        top: "10%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();
  const today = selectedDate ? new Date(selectedDate) : new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDate = dayjs(formattedDate);

  const today1 = selectedToDate ? new Date(selectedToDate) : new Date();
  const formattedDate1 = today1.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDate = dayjs(formattedDate1);
  const onhandleChangeForFromDate = (e: any) => {
    setSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
    setUserSearch("");
  };
  const onhandleChangeForToDate = (e: any) => {
    // setToSelctedDate(moment(new Date(e?.$d)).toISOString())
    setToSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
    setUserSearch("");
  };
  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleEmailErrorDialogClose = () => {
    setIsEmailErrorDialogOpen(false);
  };

  const handleRedirect = () => {
    window.open('https://docs.google.com/spreadsheets/d/1_K1taeoocMDTLp-nBGyrHgrFjRyFz-okN4hcVNVl2js/edit#gid=0', '_blank');
  };

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      if (selectedFile.type === "text/csv") {
        setSelectedFile(selectedFile);
        uploadFileAPICall({ File: selectedFile });
      } else {
        toast.error("Please select a CSV file.");
      }
    } else {
      setSelectedFile(null);
    }
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = ".csv";
      fileInputRef.current.click();
    }
  };

  const handleOpenEmailDialog = () => {
    setOpenEmailDialog(true);
  };

  const openSendEmailDialog2 = () => {
    setOpenEmailDialog(false);
  };

  const handleOpenStatusDialog = () => {
    setOpenStatusDialog(true);
  };

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
  };

  const handleOpenCampaignDialog = () => {
    setOpenCamapaignDialog(true);
  };

  const handleCloseCampaignDialog = () => {
    setOpenCamapaignDialog(false);
  };

  const handleOpenOwnerDialog = () => {
    setOpenOwnerDialog(true);
  };

  const handleCloseOwnerDialog = () => {
    setOpenOwnerDialog(false);
  };

  const handleChange = (e: any) => {    
    setSelectedFilterOptions(e.value);
  };

  const selectedValueTemplate = () => {
    // const selectedOptions = Object.keys(selectedFilterOptions).filter(key => selectedFilterOptions[key].checked);

    const selectedOptions = selectedFilterOptions && typeof selectedFilterOptions === 'object'
    ? Object.keys(selectedFilterOptions).filter(key => selectedFilterOptions[key].checked)
    : [];

    if (selectedOptions.length === 0) {

      return 'Select Filter';
    } else if (selectedOptions.length === 1) {
      const selectedKey = selectedOptions[0];
      const selectedOption = findOptionByKey(filterOptions1, selectedKey as string);
      return selectedOption ? selectedOption.label : selectedKey;
    } else {
      return `${selectedOptions.length} Selected`;
    }
  };

  const findOptionByKey = (options: TreeNode[], key: string): TreeNode | null => {
    for (let option of options) {
      if (option.key === key) return option;
      if (option.children) {
        const childOption = findOptionByKey(option.children, key);
        if (childOption) return childOption;
      }
    }
    return null;
  };

  const safeSelectedFilterOptions = selectedFilterOptions && typeof selectedFilterOptions === 'object' ? selectedFilterOptions : {};

  const hasSelectedOptions = Object.keys(safeSelectedFilterOptions).some(
    key => safeSelectedFilterOptions[key]?.checked
  );

  return (
    <>
      <Box>
        {(isUploadingData || isUpdatingLeadData || isBulkEmailSending) && (
          <div className="overlay" />
        )}
        {(isUploadingData || isUpdatingLeadData || isBulkEmailSending) && (
          <CircularProgress color="primary" className={classes.progressBar} />
        )}
        <div
          className="dash"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="container1">
              <div className="date-pickers">
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{padding:"0"}} components={["DatePicker"]}>
                      <Box className={classes.datepicker}>
                        <DatePicker
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "20px",
                            },
                            "& .MuiOutlinedInput-input": {
                              paddingRight: "10px !important",
                            },
                          }}
                          label="From Date"
                          value={fromDate}
                          onChange={onhandleChangeForFromDate}
                          disabled={searchValue.length ? true : false}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{padding:"0"}} components={["DatePicker"]}>
                      <Box className={classes.datepicker}>
                        <DatePicker
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "20px",
                            },
                            "& .MuiOutlinedInput-input": {
                              paddingRight: "10px !important",
                            },
                          }}
                          label="To Date"
                          value={toDate}
                          onChange={onhandleChangeForToDate}
                          minDate={fromDate}
                          disabled={searchValue.length ? true : false}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <Typography className={classes.countText}>Count: {getLeadsData?.data?.total || 0}</Typography>
              </div>
            </div>
            <div
              className={classes.title}
              style={{ position: "relative", right: "4%" }}
            >
              <Typography className={classes.headingtxt}>Leads</Typography>
            </div>
            {/* <div className="container2"> */}
              {/* <div> */}
            <div className={classes.headerContainer}>
            <Tooltip title="View/Hide Columns">
              <button onClick={handleClick} className={classes.column}>
                <ViewWeekIcon sx={{ height: "45%" }} />
                {/* <span style={{marginLeft:"5px"}}>Columns</span> */}
              </button>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {tempColumnVisibility
                  ?.slice()
                  ?.filter(({ key }) => role !== 'sales' || key !== 'owner')
                  ?.sort((a, b) => {
                    const nameA = options?.columnDisplayNames[a.key] || a.key;
                    const nameB = options?.columnDisplayNames[b.key] || b.key;
                    return nameA.localeCompare(nameB);
                  })?.map(({ key, value }) => (
                    <MenuItem key={key} onClick={() => toggleColumn(key)}>
                      <input
                        type="checkbox"
                        style={{ cursor: 'pointer' }}
                        checked={value}
                        onChange={() => toggleColumn(key)}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <ListItemText primary={options?.columnDisplayNames[key] || key} />
                    </MenuItem>
                  ))}
                {tempColumnVisibility?.length === 0 && (  
                <MenuItem disabled>
                  No Preference Set
                </MenuItem>
                )}
                <button
                  className={classes.apply}
                  onClick={handleApply}
                >
                  Apply
                </button>
              </Menu>
                  {/* <div className={classes.createAccoutContainer}> */}
                    <div className={classes.title}>
                      <div className="card flex justify-content-center">
                        <TreeSelect
                          value={selectedFilterOptions}
                          options={filterOptions1}
                          onChange={handleChange}
                          placeholder="Select Filter"
                          valueTemplate={selectedValueTemplate}
                          className="md:w-20rem w-full custom-class" 
                          panelStyle={{ maxHeight: '200px', }}
                          scrollHeight="300px"
                          style={{
                            width: "150px",
                            borderRadius: "20px",
                            fontSize: "14px",
                          }}
                          selectionMode="checkbox"
                          filter
                          showClear={hasSelectedOptions}
                          resetFilterOnHide={true}
                        />
                      </div>
                    </div>
                    {/* {isChecked && ( */}
                    <Tooltip title="Send Email">
                      <button
                        className={clsx(
                          { [classes.disable]: !isChecked },
                          classes.addLead
                        )}
                        disabled={!isChecked}
                        style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                        onClick={handleOpenEmailDialog}
                      >
                        <MailOutlinedIcon sx={{ height: "45%" }} />
                      </button>
                    </Tooltip>
                    {/* )} */}
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="upload-file-input"
                      ref={fileInputRef}
                    />
                    {/* <label htmlFor="upload-file-input"> */}
                    <Tooltip title="Upload csv">
                      <button
                        className={classes.upload}
                        onClick={openFileSelector}
                      >
                        <FileUploadOutlinedIcon sx={{ height: "50%" }} />
                        {/* <span>Upload Excel</span> */}
                      </button>
                    </Tooltip>
                    {/* </label> */}
                    <Tooltip title="Add to Campaign">
                      <button
                        className={clsx(
                          { [classes.disable]: !isChecked },
                          classes.addLead
                        )}
                        disabled={!isChecked}
                        onClick={() => handleOpenCampaignDialog()}
                      >
                        <CampaignOutlinedIcon sx={{ height: "45%" }} />
                      </button>
                    </Tooltip>
                    <Tooltip title="Update Status">
                      <button
                        className={clsx(
                          { [classes.disable]: !isChecked },
                          classes.addLead
                        )}
                        disabled={!isChecked}
                        onClick={() => handleOpenStatusDialog()}
                      >
                        <UpdateOutlinedIcon sx={{ height: "45%" }} />
                        {/* Update Status */}
                      </button>
                    </Tooltip>
                    {(role === "sales_manager" || role === "admin") && (
                      <Tooltip title="Owner">
                        <button
                          className={clsx(
                            { [classes.disable]: !isChecked },
                            classes.addLead
                          )}
                          disabled={!isChecked}
                          onClick={handleOpenOwnerDialog}
                        >
                          <ManageAccountsOutlinedIcon sx={{ height: "50%" }} />
                          {/* Download */}
                        </button>
                      </Tooltip>
                    )}
                    <Tooltip title="View Upload Template">
                      <button
                        className={classes.addLead}
                        onClick={handleRedirect}
                      >
                        <FileCopyOutlinedIcon sx={{ height: "50%" }} />
                        {/* Download */}
                      </button>
                    </Tooltip>
                  </div>
                {/* </div> */}
              {/* </div>
            </div> */}
          </div>
          <div className="mail-receipt">
            <OuterTableStatus
              limit={limit}
              messageId={messageId}
              selectedDate={selectedDate}
              selectedToDate={selectedToDate}
              selectedFilterOptions={selectedFilterOptions}
              filterOptions1={filterOptions1}
              // selectedStatuses={selectedStatuses}
              // selectedDomains={selectedDomains}
              // selectedCountries={selectedCountries}
              // selectedAssignees={selectedAssignees}
              setMessageId={setMessageId}
              rowsData={getLeadsData?.data?.leads}
              userSearch={userSearch}
              openSendEmailDialog1={openEmailDialog}
              openUpdateStatusDialog={openStatusDialog}
              handleCloseStatusDialog={handleCloseStatusDialog}
              openCampaignDialog={openCampaignDialog}
              handleCloseCampaignDialog={handleCloseCampaignDialog}
              openOwnerDialog={openOwnerDialog}
              handleCloseOwnerDialog={handleCloseOwnerDialog}
              apiCallTrigger={apiCallTrigger}
              openSendEmailDialog2={openSendEmailDialog2}
              isChecked={isChecked}
              onSelectStatusChange={handleSelectItemsChange}
              onSelectDomainChange={handleSelectDomainChange}
              onCheckboxChange={handleCheckboxChange}
              onSelectAll={handleSelectAll}
            />
            <Pagination
              emailTrackData={getLeadsData?.data}
              handleChangePage={handleChangePage}
              handleLimitChange={handleLimitChange}
              pageChange={pageChange}
              limit={limit}
            />
          </div>
        </div>
      </Box>

      <Dialog
        open={isErrorDialogOpen}
        onClose={handleErrorDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle
          className={classes.titletxt}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Error while uploading file
          <IconButton
            aria-label="close"
            onClick={handleErrorDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ paddingTop: "10px" }}>
          <div>
            <ol style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              {getUploadDetails?.error?.map((errorMessage: any, index: any) => (
                <li
                  key={index}
                  style={{
                    color: "red",
                    fontFamily: "Montserrat-Regular",
                    fontSize: "14px",
                  }}
                >
                  {errorMessage?.message}
                </li>
              ))}
            </ol>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isEmailErrorDialogOpen}
        onClose={handleEmailErrorDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle
          className={classes.titletxt}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Error while sending emails
          <IconButton
            aria-label="close"
            onClick={handleEmailErrorDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ paddingTop: "10px" }}>
          <div>
            <ol style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              {failedEmails?.map((message: any, index: any) => (
                <li
                  key={index}
                  style={{
                    color: "red",
                    fontFamily: "Montserrat-Regular",
                    fontSize: "14px",
                  }}
                >
                  {message?.message}
                </li>
              ))}
            </ol>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getLeadsData: leadsDataEntity.getLeads(state).getLeadsData,
    setLeadsColumnData: leadsDataEntity.getLeads(state).setLeadsColumnData,
    isSetLeadsColumnDataDone: leadDataUI.getLeads(state).isSetLeadsColumnDataDone,
    getLeadsStatusData: leadsDataEntity.getLeads(state).getLeadsStatusData,
    getUploadDetails: leadsDataEntity.getLeads(state).getUploadDetails,
    isUploadFileDone: dashboardUI.getDashboard(state).isUploadFileDone,
    isCreateLeadDone: dashboardUI.getDashboard(state).isCreateLeadDone,
    isUploadingData: dashboardUI.getDashboard(state).isUploadingData,
    isUpdatingLeadData: dashboardUI.getDashboard(state).isUpdatingData,
    resetLeadsData: leadDataUI.getLeads(state).resetLeadsData,
    isGetLeadsDataDone: leadDataUI.getLeads(state).isGetLeadsDataDone,
    getUpdateLeadDetails: leadsDataEntity.getLeads(state).getUpdateLeadDetails,
    isBulkEmailSending: dashboardUI.getDashboard(state).isBulkEmailSending,
    isBulkEmailSendDone: dashboardUI.getDashboard(state).isBulkEmailSendDone,
    isUpdateLeadDone: dashboardUI.getDashboard(state).isUpdateLeadDone,
    getSendBulkEmailData:
      dashboardEntity.getDashboard(state).getSendBulkEmailData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getLeadsDataAPICall: (data: any) =>
      dispatch(getLeadsDataAPICall.request(data)),
    setLeadsColumnDataAPICall: (data: any) =>
      dispatch(setLeadsColumnDataAPICall.request(data)),
    getLeadsStatusDataAPICall: (data: any) =>
      dispatch(getLeadsStatusDataAPICall.request(data)),
    resetgetLeadsDataAPICall: () => dispatch(getLeadsDataAPICall.reset()),
    createLeadDataAPICall: (data: any) =>
      dispatch(createLeadDataAPICall.request(data)),
    resetCreateLead: () => dispatch(createLeadDataAPICall.reset()),
    uploadFileAPICall: (data: any) => dispatch(uploadFileAPICall.request(data)),
    updateLeadDataAPICall: (data: any) =>
      dispatch(updateLeadDataAPICall.request(data)),
    resetUploadingFile: () => dispatch(uploadFileAPICall.reset()),
    resetUpdateLead: () => dispatch(updateLeadDataAPICall.reset()),
    resetSetLeadColumnData: () => dispatch(setLeadsColumnDataAPICall.reset()),
    ResetsendBulkEmailAPICall: () => dispatch(sendBulkEmailAPICall.reset()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TrackEmail);
