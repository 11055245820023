import React, { useEffect, useState } from "react";
import { getAnalyticsGraphDataAPICall } from "../../actions";
import { analyticsDataEntity } from "../../reducers";
import ApexCharts from "apexcharts";
import { connect } from "react-redux";

const AnalyticsStatusGraph = (props: any) => {
  const { getAnalyticsGraphData } = props;

  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);

  useEffect(() => {
    if (getAnalyticsGraphData) {
      const mappedData = getAnalyticsGraphData?.map(
        (item: { From: any; totalcallCount: any; name: any }) => ({
          name: item?.name,
          y: item?.totalcallCount,
        })
      );
      setChartData(mappedData);
    }
  }, [getAnalyticsGraphData]);

  useEffect(() => {
    if (chartData?.length > 0) {
      const total = chartData.reduce((acc, item) => acc + item.y, 0);
      const options = {
        series: chartData.map((item) => item.y),
        chart: {
          height: 350,
          width: 350,
          type: "pie",
        },
        labels: chartData.map((item) => item.name),
        dataLabels: {
          enabled: true,
          formatter: function (val: any, opts: any) {
            return opts.w.config.series[opts.seriesIndex];
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat-SemiBold",
            fontWeight: "bold",
            colors: ["#ffffff"],
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val: any, opts: any) {
              const count = opts?.config?.series[opts.seriesIndex];
              const percentage = ((count / total) * 100).toFixed(2);
              return `${val} (${percentage}%)`;
            },
            title: {
              formatter: function (seriesName: any) {
                return seriesName + ":";
              },
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat-SemiBold",
            fontWeight: "bold",
            colors: ["#ffffff"],
          },
        },
        legend: {
          position: "bottom",
        },
      };

      const chart = new ApexCharts(
        document.querySelector("#piechart"),
        options
      );
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  return (
    <div id="piechart" style={{ display: "flex", justifyContent: "center" }} />
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAnalyticsGraphData:
      analyticsDataEntity.getAnalyticsGraph(state).getAnalyticsGraphData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAnalyticsGraphDataAPICall: (data: any) =>
      dispatch(getAnalyticsGraphDataAPICall.request(data)),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(AnalyticsStatusGraph);
