import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  RESET,
  ADD_CAMPAIGN_DATA,
  REMOVE_LEAD_DATA,
  GET_CAMPAIGN_DETAILS_DATA,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE,  } = actionTypes

const ui = () => {

  const gettingAddCampaignData = (state = false, action: actions) => {
    switch (action.type) {
      case ADD_CAMPAIGN_DATA[SUCCESS]:
        return false
      case ADD_CAMPAIGN_DATA[FAILURE]:
        return false
      case ADD_CAMPAIGN_DATA[REQUEST]:
        return true
      case ADD_CAMPAIGN_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const gettingCampaignDetailsData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_CAMPAIGN_DETAILS_DATA[SUCCESS]:
        return false
      case GET_CAMPAIGN_DETAILS_DATA[FAILURE]:
        return false
      case GET_CAMPAIGN_DETAILS_DATA[REQUEST]:
        return true
      case GET_CAMPAIGN_DETAILS_DATA[RESET]:  
        return false
      default:
        return state
    }
  }
    const isAddCampaignDataDone = (state = false, action: actions) => {
        switch (action.type) {
          case ADD_CAMPAIGN_DATA[SUCCESS]:
            return true
          case ADD_CAMPAIGN_DATA[FAILURE]:
          case ADD_CAMPAIGN_DATA[REQUEST]:
          case ADD_CAMPAIGN_DATA[RESET]:  
            return false
          default:
            return state
        }
      }
      const isRemovingLeadDataDone = (state = false, action: actions) => {
        switch (action.type) {
          case REMOVE_LEAD_DATA[SUCCESS]:
            return true
          case REMOVE_LEAD_DATA[FAILURE]:
          case REMOVE_LEAD_DATA[REQUEST]:
          case REMOVE_LEAD_DATA[RESET]:  
            return false
          default:
            return state
        }
      }



  return combineReducers({
    gettingAddCampaignData,
    gettingCampaignDetailsData,
    isAddCampaignDataDone,
    isRemovingLeadDataDone,
  })
}

export default ui

export const getCampaign = (state: RootState) => state.ui.campaign
