import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { searchBar } from './style';
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@material-ui/core";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import CreateContact from "./CreateContact";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import { createContactsDataAPICall, getContactDetailsDataAPICall, getContactsDataAPICall, uploadContactsFileAPICall } from "../../actions";
import { contactDataEntity, contactDataUI } from "../../reducers";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { toast } from "react-toastify";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ContactsPagination from "./ContactsPagination";
import ContactsDetails from "./ContactsDetails";

const Contacts = (props: any) => {
  const { 
    getContactsDataAPICall,
    getContactsData,
    getContactDetailsDataAPICall,
    isGettingContactsData,
    isCreatingContactsData, 
    isCreateContactsDataDone,
    createContactsData,
    uploadContactsFileAPICall,
    getUploadContactsData,
    isUploadContactsFileDone,
    isUploadingContactsData,
    resetCreateContacts,
    resetUploadContactsFile,
  } = props;  

  const [isCreateContactDialogOpen, setIsCreateContactDialogOpen] =
  useState<boolean>(false);
  const [isContactsDetailsDialogOpen, setIsContactsDetailsDialogOpen] =
  useState<boolean>(false);
  const [limit, setLimit] = useState(100);
  const [pageChange, setPageChange] = useState(1);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);  

  useEffect(() => {
    const contactsData ={
      limit: limit,
      pageChange: pageChange,
      searchString: searchTerm,
    }
    getContactsDataAPICall(contactsData);
  },[limit,pageChange])

  useEffect(() => {
    if(isCreateContactsDataDone){
      const contactsData ={
        limit: limit,
        pageChange: pageChange,
        searchString: searchTerm,
      }
      getContactsDataAPICall(contactsData);
      toast.success(createContactsData?.message);
      resetCreateContacts();
    }else if(isUploadContactsFileDone){
      const contactsData ={
        limit: limit,
        pageChange: pageChange,
        searchString: searchTerm,
      }
      getContactsDataAPICall(contactsData);
      if (getUploadContactsData?.error?.length) {
        setIsErrorDialogOpen(true);
        toast.success(getUploadContactsData?.message);
      } else {
        toast.success(getUploadContactsData?.message);
      }
      // toast.success(getUploadContactsData?.message);
      resetUploadContactsFile();
    }
  },[isCreateContactsDataDone,isUploadContactsFileDone])
  
  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      datepicker: {
        margin: "0px 0 0px 10px !important",
        width: "170px",
        borderRadius: "20px",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#add8e6",
        margin: "5px",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      titletxt: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "18px!important",
        fontWeight: 500,
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      search: {
        color: '#666666',
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        left: 5,
      },
      upload: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        border: "1px solid rgba(20, 56, 113, 1)",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        width: "20%",
        whiteSpace: "nowrap",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "100% !important",
        wordBreak: "break-word",
      },
      progressBar: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  const classes = useStyles();

  const handleSearch = (event: any) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm === "") {
      const contactsData ={
        limit: limit,
        pageChange: pageChange,
        searchString: newSearchTerm,
      }
      getContactsDataAPICall(contactsData);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      const contactsData ={
        limit: limit,
        pageChange: pageChange,
        searchString: searchTerm,
      }
      getContactsDataAPICall(contactsData);
    }
  };

  const handleRedirect = () => {
    window.open('https://docs.google.com/spreadsheets/d/17hrHzd8cMsBjZMwPu9RhJI6WA4BD4OuMGhR3cwl5sw8/edit?usp=sharing', '_blank');
  };

  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      if (selectedFile.type === "text/csv") {
        setSelectedFile(selectedFile);
        const data={
          role:"contact",
          File: selectedFile
        }
        uploadContactsFileAPICall(data);
      } else {
        toast.error("Please select a CSV file.");
      }
    } else {
      setSelectedFile(null);
    }
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = ".csv";
      fileInputRef.current.click();
    }
  };

  const handleRowClick = (id: any) => {
    setIsContactsDetailsDialogOpen(true);
      let data = {
        id: id,
      };
    getContactDetailsDataAPICall(data);
  };

  const handleContactsDetailsDialogClose = () => {
    setIsContactsDetailsDialogOpen(false); 
  };

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage);
  };

  const handleCreateContactDialogOpen = () => {
    setIsCreateContactDialogOpen(true);
  };

  const handleCreateContactDialogClose = () => {
    setIsCreateContactDialogOpen(false);
  };

  return (
    <Paper sx={{ margin: "10px", minHeight: "300px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {(isUploadingContactsData || isCreatingContactsData) && (
          <div className="overlay" />
        )}
        {(isUploadingContactsData || isCreatingContactsData) && (
          <CircularProgress color="primary" className={classes.progressBar} />
        )}
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Box sx={{ marginLeft: "16px", }}>
            <TextField
              id='search'
              name='search'
              // autoFocus
              type='text'
              placeholder='Search Contacts'
              InputLabelProps={{ style: { color: '#666666' } }}
              size='small'
              InputProps={{
                style: { ...{ padding: '0' } },
                type: 'search',
                startAdornment: (
                  <Tooltip title='Search' placement='top'>
                    <span>
                      <SearchTwoToneIcon className={classes.search} />
                    </span>
                  </Tooltip>
                ),
              }}
              value={searchTerm}
              onChange={handleSearch}
              onKeyDown={handleKeyPress}
              sx={{ ...searchBar }}
            />
          </Box>
          <Typography
            className={classes.headingtxt}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
          >
            Contacts
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end",alignItems:"center" }}>
          <Tooltip title="View Upload Template">
            <button
              className={classes.upload}
              style={{marginRight:'10px'}}
              onClick={handleRedirect}
            >
              <FileCopyOutlinedIcon sx={{ height: "50%" }} />
            </button>
          </Tooltip>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="upload-file-input"
            ref={fileInputRef}
          />
          <Tooltip title="Upload csv">
            <button
              className={classes.upload}
            onClick={openFileSelector}
            >
              <FileUploadOutlinedIcon sx={{ height: "50%" }} />
            </button>
          </Tooltip>
          <Button
            color="primary"
            className={classes.addLead}
            sx={{ borderRadius: "20px", margin: "10px" }}
            onClick={() => handleCreateContactDialogOpen()}
          >
            <AddOutlinedIcon sx={{ height: "60%" }} />
          <span style={{whiteSpace: "nowrap",marginRight: "10px"}}>
            Create Contact
            </span>
          </Button>
        </div>
      </div>
      <div
        style={{
          margin: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1",
          minHeight: "360px",
        }}
      >
        <TableContainer sx={{ marginBottom: "10px", width: "100%" }}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
            >
              <TableRow>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Name</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Phone no</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Email</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                <span className="mail-info">Assistant</span>
              </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Contact Owner</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isGettingContactsData ? (
        <TableRow>
          <TableCell colSpan={12} className="tablebodycell">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "120px",
              }}
            >
              <CircularProgress />
            </div>
          </TableCell>
        </TableRow>
      ) : getContactsData?.data?.contacts?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data Available
                    </div>
                </TableCell>
              </TableRow>
            ) : (
              getContactsData?.data?.contacts?.map((contacts: any, index: any) => (
                <TableRow
                  key={index}
                  sx={{ cursor: "pointer", background: "#ffffff" }}
                  onClick={() => handleRowClick(contacts.id)}
                >
                  <TableCell
                    className="tablebodycell"
                    sx={{ padding: "6px 16px !important" }}
                  >
                    {contacts.name ? contacts.name : "NA"}
                  </TableCell>
                  <TableCell
                    className="tablebodycell"
                    sx={{ padding: "6px 16px !important" }}
                  >
                    {contacts.leadPhone ? contacts.leadPhone : "NA"}
                  </TableCell>
                  <TableCell
                    className="tablebodycell"
                    sx={{ padding: "6px 16px !important" }}
                  >
                    {contacts.email ? contacts.email : "NA"}
                  </TableCell>
                  <TableCell
                    className="tablebodycell"
                    sx={{ padding: "6px 16px !important" }}
                  >
                    {contacts.assistant ? contacts.assistant : "NA"}
                  </TableCell>
                  <TableCell
                    className="tablebodycell"
                    sx={{ padding: "6px 16px !important" }}
                  >
                    {contacts?.ownerName ? contacts?.ownerName : "NA"}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          </Table>
          <ContactsPagination
              contactsTableData={getContactsData?.data}
              handleChangePage={handleChangePage}
              handleLimitChange={handleLimitChange}
              pageChange={pageChange}
              limit={limit}
            />
        </TableContainer>
      </div>

      <Dialog
        open={isContactsDetailsDialogOpen}
        onClose={handleContactsDetailsDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: 'auto',
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", padding:'5px 24px' }}>
          Contacts Details
          <IconButton
            aria-label="close"
            onClick={handleContactsDetailsDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="campaign-divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <ContactsDetails/>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCreateContactDialogOpen}
        onClose={handleCreateContactDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "85%",
            maxWidth: "85%",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          Create Contact
          <IconButton
            aria-label="close"
            onClick={handleCreateContactDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <CreateContact
            handleCreateContactDialogClose={handleCreateContactDialogClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isErrorDialogOpen}
        onClose={handleErrorDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle
          className={classes.titletxt}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Error while uploading file
          <IconButton
            aria-label="close"
            onClick={handleErrorDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ paddingTop: "10px" }}>
          <div>
            <ol style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              {getUploadContactsData?.error?.map((errorMessage: any, index: any) => (
                <li
                  key={index}
                  style={{
                    color: "red",
                    fontFamily: "Montserrat-Regular",
                    fontSize: "14px",
                  }}
                >
                  {errorMessage?.message}
                </li>
              ))}
            </ol>
          </div>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getContactsData: contactDataEntity.getContact(state).getContactsData,
    isGettingContactsData: contactDataUI.getContacts(state).isGettingContactsData,
    createContactsData: contactDataEntity.getContact(state).createContactsData,
    getUploadContactsData: contactDataEntity.getContact(state).getUploadContactsData,
    isCreatingContactsData: contactDataUI.getContacts(state).isCreatingContactsData,
    isCreateContactsDataDone: contactDataUI.getContacts(state).isCreateContactsDataDone,
    isUploadContactsFileDone: contactDataUI.getContacts(state).isUploadContactsFileDone,
    isUploadingContactsData: contactDataUI.getContacts(state).isUploadingContactsData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getContactsDataAPICall: (data: any) =>
      dispatch(getContactsDataAPICall.request(data)),
    getContactDetailsDataAPICall: (data: any) =>
      dispatch(getContactDetailsDataAPICall.request(data)),
    uploadContactsFileAPICall: (data: any) =>
      dispatch(uploadContactsFileAPICall.request(data)),
    resetUploadContactsFile: () => dispatch(uploadContactsFileAPICall.reset()),
    resetCreateContacts: () => dispatch(createContactsDataAPICall.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
