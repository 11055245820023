import "./Contacts.css";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { contactDataEntity, contactDataUI } from "../../reducers";
import InstaLogo from "../../assets/images/instagram.png";
import LinkedInLogo from "../../assets/images/linkedin.png";

const ContactsDetails = (props: any) => {
  const {
    getContactDetailsData,
    isGettingContactsDetailsData,
  } = props;  
  
  const useStyles = makeStyles(() => {
    return createStyles({
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      headingtooltip: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "12px!important",
        fontWeight: "bold",
        wordBreak: "break-word",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginLeft: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
      },
      search: {
        color: '#666666',
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        left: 5,
      },
      mail: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "34px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "100%",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();

  const handleInstagramClick = (instaId: any) => {
    if (instaId) {
      window.open(`${instaId}`, "_blank");
    }
  };

  const handleLinkedInClick = (linkedInId: any) => {
    if (linkedInId) {
      window.open(`${linkedInId}`, "_blank");
    }
  };

  return (
    <>
      {isGettingContactsDetailsData && (
        <CircularProgress color="primary" className={classes.progressBar} />
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography className={classes.titletext}>Contacts Details</Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          {getContactDetailsData?.data?.leads_instaId && (
            <img
              src={InstaLogo}
              alt="Instagram Icon"
              style={{
                height: "20px",
                width: "20px",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleInstagramClick(
                  getContactDetailsData?.data?.leads_instaId
                )
              }
            />
          )}
          {getContactDetailsData?.data?.lead_linkedIn_url && (
            <img
              src={LinkedInLogo}
              alt="LinkedIn Icon"
              style={{
                height: "20px",
                width: "20px",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkedInClick(
                  getContactDetailsData?.data?.lead_linkedIn_url
                )
              }
            />
          )}
        </div>
      </div>
      <Card
        sx={{
          marginBottom: "10px",
          marginTop: "10px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <CardContent
          sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>Name</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>Phone</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>Mobile</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Email
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>Secondary Email</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.name || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.leadPhone || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.mobile || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.email
                ? getContactDetailsData?.data?.email
                : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
              {getContactDetailsData?.data?.email
                ? getContactDetailsData?.data?.email
                : "NA"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ marginTop: "5px" }}>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Assistant
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
              Assistant Phone
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Title
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Department
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.headingtext}>
                Address
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.assistant || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.assistant_phone || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.title || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.department || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography className={classes.subheadingtext}>
                {getContactDetailsData?.data?.company_address || "NA"}
              </Typography>
            </Grid>
          </Grid>
          {getContactDetailsData?.data?.description_of_lead && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingtext}
                    sx={{ paddingTop: "0 !important", marginTop: "10px" }}
                  >
                    Description
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subheadingtext}>
                    {getContactDetailsData?.data?.description_of_lead || "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getContactDetailsData: contactDataEntity.getContact(state).getContactDetailsData,
    isGettingContactsDetailsData: contactDataUI.getContacts(state).isGettingContactsDetailsData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsDetails);
