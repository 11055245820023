import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE,RESET, LOGIN_USER } = actionTypes

const ui = () => {
  const isUserAuthenticationDone = (state = false, action: actions) => {
    switch (action.type) {
      case LOGIN_USER[SUCCESS]:
        return true
      case LOGIN_USER[FAILURE]:
      case LOGIN_USER[REQUEST]:
      case LOGIN_USER[RESET]:
        return false
      default:
        return state
    }
  }

  const isUserAuthenticationFailed = (state = false, action: actions) => {
    switch (action.type) {
      case LOGIN_USER[SUCCESS]:
      case LOGIN_USER[REQUEST]:
        return false
      case LOGIN_USER[FAILURE]:
        return true
      default:
        return state
    }
  }

  return combineReducers({
    isUserAuthenticationDone,
    isUserAuthenticationFailed,
  })
}

export default ui

export const getUserAuthentication = (state: RootState) => state.ui.userAuthentication
