import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { 
  LEADS_DETAILS_BY_ID,
  DOWNLOAD_LEAD_FILE, 
  UPLOAD_FILE,
  CREATE_LEAD_DATA,
  SET_LEADS_COLUMN_DATA,
  UPDATE_LEAD_DATA, 
  LEADS_STATUS_DATA 
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE, LEADS_DATA } = actionTypes
const entity = () => {
  const getLeadsData = (state = [], action: actions) => {
    switch (action.type) {
      case LEADS_DATA[SUCCESS]:
        return action.payload
      case LEADS_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const setLeadsColumnData = (state = [], action: actions) => {
    switch (action.type) {
      case SET_LEADS_COLUMN_DATA[SUCCESS]:
        return action.payload
      case SET_LEADS_COLUMN_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getLeadsStatusData = (state = [], action: actions) => {
    switch (action.type) {
      case LEADS_STATUS_DATA[SUCCESS]:
        return action.payload
      case LEADS_STATUS_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getLeadsDetailsById = (state = [], action: actions) => {
    switch (action.type) {
      case LEADS_DETAILS_BY_ID[SUCCESS]:
        return action.payload
      case LEADS_DETAILS_BY_ID[REQUEST]:
        return []
      default:
        return state
    }
    
  }

  const getDownloadLeadFile = (state = '', action: actions) => {
    switch (action.type) {
      case DOWNLOAD_LEAD_FILE[SUCCESS]:
        return action.payload
      case DOWNLOAD_LEAD_FILE[REQUEST]:
        return ''
      default:
        return state
    }
    
  }

  const getUploadDetails = (state = [], action: actions) => {
    switch (action.type) {
      case UPLOAD_FILE[SUCCESS]:
        return action.payload
      case UPLOAD_FILE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getAddLeadDetails = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_LEAD_DATA[SUCCESS]:
        return action.payload
      case CREATE_LEAD_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getUpdateLeadDetails = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_LEAD_DATA[SUCCESS]:
        return action.payload
      case UPDATE_LEAD_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

//   const getValidatedData = (state = [], action: actions) => {
//     switch (action.type) {
//       case VALIDATE_DATA[SUCCESS]:
//         return action.payload
//       case VALIDATE_DATA[REQUEST]:
//         return []
//       default:
//         return state
//     }
//   }

  return combineReducers({
    getLeadsData,
    setLeadsColumnData,
    getLeadsStatusData,
    getLeadsDetailsById,
    getDownloadLeadFile,
    getUploadDetails,
    getAddLeadDetails,
    getUpdateLeadDetails,
  })
}

export default entity
export const getLeads = (state: RootState) => state.entities.leads
