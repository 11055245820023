import { all } from 'redux-saga/effects'
import { sagas as dashboardSagas } from './dashboard'
import { sagas as userAuthentication } from './userAuthentication'
import { sagas as loginUser } from './loginUser'
import { sagas as leadsSagas } from './Leads'
import { sagas as AnalyticsSagas } from './analytics'
import { sagas as CampaignSagas } from './campaign'
import { sagas as ContactSagas } from './contact'

export default function* rootSaga() {
  yield all({
    ...leadsSagas,
    ...AnalyticsSagas,
    ...CampaignSagas,
    ...ContactSagas,
    ...dashboardSagas,
    ...userAuthentication,
    ...loginUser,


    
  })
}
