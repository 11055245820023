import * as React from 'react'
import { Autocomplete, Grid, TextField, Tooltip, useMediaQuery } from '@mui/material'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { leadDataUI } from '../../reducers'

const SearchBox = (props: any) => {
  const { employeeRecords, fetchEmployeeRecords,onSearchChange, resetLeadsData } = props

  const location = useLocation()
  let searchParams = new URLSearchParams(location.search)
  let searchData = searchParams.get('search') || ''
  const [searchLeadDetails, setSearchLeadDetails] = React.useState(searchData || '')

  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  // const [employee, setEmployee] = React.useState('')
  useEffect(()=>{
    if(resetLeadsData) {
      // setSearchLeadDetails('')
      // navigate('/home/dashboard');
    }
  },[resetLeadsData])

  const navigate = useNavigate()

  /**
   * onSelect
   * @param value
   */
  const onSelect = (value: string) => {
    const employee = employeeRecords?.find((emp: any) => emp?.info === value)
    if (employee && employee?.userId) {
      navigate(`/home/basic-info-on-search?id=${employee?.userId}`)
      // searchLeadDetails('')
    }
  }

  const handleInputChange = (event:any) => {
    const searchValue = event.target.value;
    setSearchLeadDetails(searchValue);
    
    // onSearchChange(searchValue);
    if(searchValue===''){
      onSearchChange(searchValue);
      navigate('/home/dashboard');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && searchLeadDetails.trim() !== '') {
      onSearchChange(searchLeadDetails.trim())
    }
  }  

  const handleClearField = () => {
    setSearchLeadDetails('') // Clear the search input
  }

  return (
    <Grid item xs={10} my={2}>
          <TextField
            // {...params}
            id='searchEmployee'
            name='searchEmployee'
            autoFocus
            type='text'
            placeholder='Search Leads'
            InputLabelProps={{ style: { color: '#666666' } }}
            size='small'
            fullWidth
            InputProps={{
              // ...params.InputProps,
              style:{...{padding:'0'}},
              type: 'search',
              startAdornment: (
                <Tooltip title='Search Leads' placement='top'>
                  <span>
                    <SearchTwoToneIcon
                      sx={{ color: '#666666', cursor: 'pointer', position: 'absolute', top: 10,left: 5 }}
                    />
                  </span>
                </Tooltip>
              ),
            }}
            value={searchLeadDetails}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            sx={{
              '& label.Mui-focused': {
                color: '#666666',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                  borderRadius: '15px',
                },
                '& fieldset': {
                  borderColor: '#fff !important',
                  borderRadius: '15px',
                  boxShadow: 2,
                },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '& .MuiInputBase-input': {
                  borderRadius: '15px',
                  width: isSmallScreen ? '250px' : '400px',
                  fontFamily: 'Montserrat-Medium',
                  marginLeft: 3,
                  letterSpacing: '0',
                },
              },
              svg: {
                color: '#666666',
              },
            }}
          />
        {/* }
      /> */}
    </Grid>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    resetLeadsData: leadDataUI.getLeads(state).resetLeadsData,
    // employeeRecords: employeeDataRequest.getAllEmployeesList(state).getAllEmployeesDetails,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // fetchEmployeeRecords: () => dispatch(getAllEmployees.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox)
