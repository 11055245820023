import "./CampaignEmail.css";
import "react-quill/dist/quill.snow.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Alignment,
  AutoImage,
  AutoLink,
  Autoformat,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  ClassicEditor,
  Code,
  CodeBlock,
  Essentials,
  Font,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  MediaEmbed,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersEssentials,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCellProperties,
  TableProperties,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";import { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { useFormik } from "formik";
import { emailformSchema } from "../../Schema/index";
import { sendCampaignEmailAPICall, sendEmailAPICall } from "../../actions";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { dashboardEntity, dashboardUI } from "../../reducers";
import { Container, TextField,} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const style = {
  containerStyle: {
    marginTop: "0.5rem",
    minHeight: "fit-content",
    maxHeight: "80vh",
    width: "960px",
  },
};

const CampaignSendEmail = (props: any) => {
  const {
    handleCloseCampaign,
    isEmailSendDone,
    ResetsendCampaignEmailAPICall,
    emailResponse,
    sendCampaignEmailAPICall,
    emailIds,
    leadNames,
    campaignId,
    selectedNames,
    selectedEmails,
    selectedEmailNames,
    selectedMailEmails,
  } = props;

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [emailBody, setEmailBody] = useState("Hi {{NAME}}");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const filteredSelectedNames = selectedNames?.filter(
    (name: string) => name !== null && name !== undefined && name !== ""
  );
  const filteredSelectedEmails = selectedEmails?.filter(
    (email: string) => email !== null && email !== undefined && email !== ""
  );

  const data = {
    Name:
      filteredSelectedNames?.length > 0
        ? filteredSelectedNames?.join(", ")
        : selectedEmailNames?.join(", ") || leadNames?.toString(),
    To:
      filteredSelectedEmails?.length > 0
        ? filteredSelectedEmails?.join(", ")
        : selectedMailEmails?.join(", ") || emailIds?.toString(),
    Subject: "",
    Cc: "",
    Bcc: "",
    HtmlBody: "Hi {{NAME}}",
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    touched,
    resetForm,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: data,
    validationSchema: emailformSchema,
    onSubmit: (event: any) => {},
  });

  const handleProceed = () => {
    let sendEmail = {
      Name: values?.Name,
      To: values?.To,
      Subject: values?.Subject,
      Cc: values?.Cc,
      Bcc: values?.Bcc,
      HtmlBody: emailBody,
      attachment: selectedFiles,
      campaignId: campaignId,
    };

    const formattedData = emailBody.replaceAll(
      "<a",
      `<a style="text-decoration:none;"`
    );

    sendEmail.HtmlBody = formattedData;
    if (
      Object.keys(errors).length === 0 &&
      values?.HtmlBody &&
      values?.Subject &&
      values?.To
    ) {
      sendCampaignEmailAPICall({ sendEmail });
      handleCloseCampaign();
    }
  };
  const handleBlurEmailBody = () => {
    setFieldTouched("HtmlBody", true);
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const removeSelectedFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      setSelectedFiles([...selectedFiles, ...filesArray]);
    }
  };

  const urlInput = document.getElementsByClassName(
    "ck ck-balloon-panel ck-balloon-panel_arrow_nw ck-balloon-panel_visible ck-balloon-panel_with-arrow"
  )[0] as HTMLElement;
  if (urlInput) {
    urlInput.style.zIndex = "10001";
  }

  const fontTable = document.getElementsByClassName("ck ck-reset ck-dropdown__panel ck-dropdown__panel_se ck-dropdown__panel-visible")[0] as HTMLElement
  if(fontTable){
    fontTable.style.height = "150px";
    fontTable.style.overflow = "scroll";
  }

  return (
    <Container sx={style.containerStyle}>
      <Box className="formbody">
        <Box className="body" paddingBottom='0.5rem'>
        <form onSubmit={handleSubmit} noValidate>
              <TextField
                id="To"
                name="To"
                label="To"
                multiline
                rows={1}
                size="small"
                sx={{
                  "& .MuiFormLabel-asterisk": {
                    color: "red"
                  },
                  marginTop:'5px',
                  marginBottom:'8px',
                }}
                InputProps={{
                  style: {
                      padding:'0',
                      minHeight: '20px',
                      borderRadius:'8px'
                  },
                }}
                value={values?.To}
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Box className="error-height">
                {touched.To && errors.To && (
                  <Box className="error-text">
                    {errors.To as React.ReactNode}
                  </Box>
                )}
              </Box>
            <Box className="lbc-r">
              <TextField
                name="Cc"
                label="Cc"
                InputLabelProps={{
                  style: { top: '-6px' },
                }}
                InputProps={{
                  style: {
                      borderRadius:'8px'
                  },
                }}
                sx={{marginTop:'5px',marginBottom:'8px'}}
                required={false}
                value={values?.Cc}
                onChange={handleChange}
                onBlur={handleBlur}
                spellCheck={false}
              />

              <Box className="error-height">
                {touched.Cc && errors.Cc && (
                  <Box className="error-text">
                    {errors.Cc as React.ReactNode}
                  </Box>
                )}
              </Box>
            </Box>
          <TextField
            id="Subject"
            name="Subject"
            label="Subject"
            InputLabelProps={{
              style: { top: '-6px' },
            }}
            InputProps={{
              style: {
                  borderRadius:'8px'
              },
            }}
            sx={{
              "& .MuiFormLabel-asterisk": {
                color: "red"
              },
              marginTop:'5px',
              marginBottom:'8px'
            }}
            value={values?.Subject}
            onChange={handleChange}
            onBlur={handleBlur}
            spellCheck={false}
          />

          <Box className="error-height">
            {touched.Subject && errors.Subject && (
              <Box className="error-text">
                {errors.Subject as React.ReactNode}
              </Box>
            )}
          </Box>
          <Box className="body-right">
            <Box className="brc">
              <Box 
                onBlur={handleBlurEmailBody} 
                sx={{marginTop:'10px',marginBottom:'5px'}}
                className="custom_ck"
                >
                    <CKEditor
                    editor={ClassicEditor}
                    config={{
                      plugins: [
                        Image,
                        ImageCaption,
                        ImageStyle,
                        ImageResize,
                        LinkImage,
                        Bold,
                        Code,
                        Strikethrough,
                        Subscript,
                        Superscript,
                        Underline,
                        Autoformat,
                        TextTransformation,
                        Link,
                        PasteFromOffice,
                        Font,
                        AutoLink,
                        Essentials,
                        Italic,
                        Mention,
                        Paragraph,
                        Undo,
                        Indent,
                        IndentBlock,
                        BlockQuote,
                        CodeBlock,
                        Heading,
                        Highlight,
                        HorizontalLine,
                        List,
                        MediaEmbed,
                        PageBreak,
                        SelectAll,
                        SpecialCharacters,
                        SpecialCharactersEssentials,
                        Table,
                        Alignment,
                        ImageInsert,
                        AutoImage,
                        TableProperties,
                        TableCellProperties,
                        RemoveFormat,
                        Base64UploadAdapter,
                      ],
                      toolbar: [
                        "undo",
                        "redo",
                        "|",
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        "specialCharacters",
                        "|",
                        "alignment",
                        "fontSize",
                        "fontFamily",
                        "fontColor",
                        "highlight",
                        "|",
                        "insertImage",
                        "insertTable",
                        "mediaEmbed",
                        "bulletedList",
                        "numberedList",
                        "outdent",
                        "indent",
                        "subscript",
                        "superscript",
                        "strikethrough",
                        "heading",
                        "pageBreak",
                        "codeBlock",
                        "horizontalLine",
                        "toggleImageCaption",
                        "imageTextAlternative",
                        "removeFormat",
                        "mergeTableCells",
                      ],
                      image: {
                        insert: {
                          type: "auto",
                          integrations:['url']
                        },
                      },
                      fontSize: {
                        options: [
                          9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                        ],
                        supportAllValues: true,
                      },
                      highlight: {
                        options: [
                          {
                            model: "yellowMarker",
                            class: "marker-yellow",
                            title: "Yellow marker",
                            color: "var(--ck-highlight-marker-yellow)",
                            type: "marker",
                          },
                        ],
                      },
                    }}
                    data={emailBody}
                    onChange={(event: any, editor: any) => {
                      setEmailBody(editor.getData());
                    }}
                  />
                </Box>

              <Box className="mailerror-height">
                {touched.HtmlBody && errors.HtmlBody && (
                  <Box className="error-text">
                    {errors.HtmlBody as React.ReactNode}
                  </Box>
                )}
              </Box>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {selectedFiles.map((file, index) => (
                  <div key={index} className="attachfilebox">
                    <span
                      style={{
                        fontFamily: "Montserrat-Medium",
                        fontSize: "12px",
                        color: "#000000",
                        marginLeft: "10px",
                      }}
                    >
                      {file.name}
                    </span>
                    <button
                      onClick={() => removeSelectedFile(index)}
                      style={{
                        marginLeft: "10px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <ClearOutlinedIcon sx={{ height: "15px" }} />
                    </button>
                  </div>
                ))}
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="upload-file-input"
                  ref={fileInputRef}
                  multiple 
                />
                <button
                  className="Submit"
                  style={{ width: "20%" }}
                  onClick={openFileSelector}
                  type="button"
                >
                  <span>+ Attach File</span>
                </button>
              </div>

                <Box className="SubmitMain">
                  <button onClick={handleCloseCampaign} className="Cancel">
                    Cancel
                  </button>
                  <span style={{ cursor: "not-allowed" }}>
                  <button
                    onClick={handleProceed}
                    type="submit"
                    className="Submit"
                    disabled={!dirty || !isValid || isSubmitting}
                    style={{
                      cursor: !dirty || !isValid || isSubmitting ? 'not-allowed' : 'pointer',
                      opacity: !dirty || !isValid || isSubmitting ? 0.5 : 1,
                    }}
                  >
                    Submit
                  </button>
                  </span>
                </Box>
              </Box>
            </Box>
            <ToastContainer position="top-right" />
          </form>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isEmailSendDone: dashboardUI.getDashboard(state).isEmailSendDone,
    isBulkEmailSendDone: dashboardUI.getDashboard(state).isBulkEmailSendDone,
    emailResponse: dashboardEntity.getDashboard(state).sendEmailData,
  };
};
const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    sendEmailAPICall: (data: any) => dispatch(sendEmailAPICall.request(data)),
    sendCampaignEmailAPICall: (data: any) =>
      dispatch(sendCampaignEmailAPICall.request(data)),
    ResetsendCampaignEmailAPICall: () => dispatch(sendCampaignEmailAPICall.reset()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CampaignSendEmail);
