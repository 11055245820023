import { uploadFileAPICall, getLeadsDataAPICall, createLeadDataAPICall, updateLeadDataAPICall, sendBulkEmailAPICall, getLeadsStatusDataAPICall } from '../../actions'
import { CircularProgress, Divider, IconButton, Tooltip } from '@mui/material'
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import countriesData from '../Leads/CountriesandStates.json'
import { connect } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import DealsClosedTable from './DealsClosedTable'
import { TreeSelect } from 'primereact/treeselect';
import { TreeNode } from 'primereact/treenode';
import './eta.css'
import Pagination from './pagination'
import CloseIcon from '@mui/icons-material/Close'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { leadsDataEntity, dashboardUI, leadDataUI, dashboardEntity } from '../../reducers'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import clsx from "clsx"

const moment = require('moment-timezone')

interface SearchData {
  limit: number
  page: number
  follow_up_status?: any
  domain?: any
  searchString: string
  fromDate?: string
  toDate?: string
  filter?: any
  country?: any
  userIds?:any
}

const DealsClosed = (props: any) => {
  const {
    getLeadsDataAPICall,
    getLeadsData,
    getLeadsStatusDataAPICall,
    getLeadsStatusData,
    getUploadDetails,
    createLeadDataAPICall,
    uploadFileAPICall,
    isUploadFileDone,
    isUploadingData,
    resetUploadingFile,
    resetgetLeadsDataAPICall,
    isBulkEmailSending,
    resetLeadData,
    isCreateLeadDone,
    resetCreateLead,
    resetLeadsData,
    isGetLeadsDataDone,
    isUpdateLeadDone,
    getUpdateLeadDetails,
    resetUpdateLead,
    isBulkEmailSendDone,
    ResetsendBulkEmailAPICall,
    isUpdatingLeadData,
    getSendBulkEmailData,
    // handleOpenEmailDialog,
  } = props

  const navigate = useNavigate()
  const role = localStorage.getItem("role");
  const [userSearch, setUserSearch] = useState('')
  const [pageChange, setPageChange] = useState(1)
  const [selectedDate, setSelctedDate] = useState(
    sessionStorage.getItem('selectedDate1') || moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [selectedToDate, setToSelctedDate] = useState(
    sessionStorage.getItem('selectedToDate') || moment(new Date()).format('YYYY-MM-DD')
  );
  const [type, setType] = useState('')
  const [messageId, setMessageId] = useState('')
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [status, setStatus] = useState<string[]>(["Won"]);
  const [clicked, setClicked] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false)
  const [openEmailDialog, setOpenEmailDialog] = useState(false)
  const [openStatusDialog, setOpenStatusDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [limit, setLimit] = useState(100)
  const [isChecked, setIsChecked] = useState(false)
  const [apiCallTrigger, setApiCallTrigger] = useState(false);
  const [selectedDomain1, setselectedDomain1] = useState<string[]>(
    JSON.parse(sessionStorage.getItem('selectedDomain1') || "[]"));
  const [leadDomain, setLeadDomain] = useState<string[]>([]);  
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<any>(
    JSON.parse(sessionStorage.getItem("selectedFilterOptions1") || "{}") || {});
  const [filterOptions1, setFilterOptions1] = useState<TreeNode[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
 
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const statusData = {
      type:"1"
    }
    getLeadsStatusDataAPICall(statusData);
  }, [selectedDate,selectedToDate]);

  useEffect(() => {
    if (getLeadsStatusData) {
      setLeadDomain(
        getLeadsStatusData?.domains?.map((domain: string) => ({
          name: domain,
        }))
      );
    }
  }, [getLeadsStatusData]);

  useEffect(() => {
    if (getLeadsStatusData) {
      const countries = countriesData?.map((country: any) => ({
        key: country.name,
        label: country.name,
        data: country.name,
      }));

      const domains = getLeadsStatusData?.domains?.map((domain: string) => ({
        key: domain,
        label: domain,
        data: domain
      }));

        setFilterOptions1([
          {
            key: 'country',
            label: 'Country',
            children: countries
          },
        {
          key: 'domain',
          label: 'Domain',
          children: domains
        },
      ]);
      setDataLoaded(true);
    }
  }, [getLeadsStatusData]);

  const handleCheckboxChange = (isChecked: boolean) => {
    setIsChecked(isChecked)
  }

  const handleSelectAll = (isCheckedAll: boolean) => {
    setIsChecked(isCheckedAll)
  }

  useEffect(()=>{
    if(isGetLeadsDataDone) {
      resetgetLeadsDataAPICall()
    }
  },[isGetLeadsDataDone])

  useEffect(() => {
    setIsChecked(false);
  }, [apiCallTrigger]); 

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10)
    setLimit(newLimit)
    // setPageChange(1); // Reset page to 1 when limit changes
  }

  const handleSelectItemsChange = (selectedItems: any) => {
    setSelectedStatus(selectedItems);
  };

  const handleSelectDomainChange = (selectedOptions: any) => {
    setSelectedDomain(selectedOptions);
  };

  const location = useLocation()
  let searchParams = new URLSearchParams(location.search)
  let searchValue = searchParams.get('search') || ''
  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search)
    // const searchValue = searchParams.get('search') || ''
    searchParams = new URLSearchParams(location.search)
    searchValue = searchParams.get('search') || ''
    setUserSearch(searchValue)
  }, [location.search])

  useEffect(() => {
    sessionStorage.removeItem('selectedDate1');
    sessionStorage.removeItem('selectedToDate1');
    sessionStorage.removeItem('selectedFilterOptions1');
  }, []);

  useEffect(() => {
    if(dataLoaded){
  const safeSelectedFilterOptions1 = selectedFilterOptions && typeof selectedFilterOptions === 'object' ? selectedFilterOptions : {};

  const selectedCountries:any = [];
  const selectedDomains:any = [];

  Object.keys(safeSelectedFilterOptions1).forEach(key => {
    const option = safeSelectedFilterOptions1[key];
    if (option.checked) {
      const parent = filterOptions1.find(parent => parent.children?.some(child => child.key === key));
      if (parent) {
        switch (parent.key) {
          case 'country':
            selectedCountries.push(key);
            break;
          case 'domain':
            selectedDomains.push(key);
            break;
          default:
            break;
        }
      }
    }
  });
    const searchData: SearchData = {
      limit: limit,
      page: pageChange,
      follow_up_status: status,
      domain: selectedDomains.join(','),
      country: selectedCountries.join(','),
      userIds: "",
      // fromDate: selectedDate != undefined ? selectedDate : moment(new Date()).toISOString(),
      // toDate: selectedToDate != undefined ? selectedToDate : moment(new Date()).toISOString(),
      searchString: userSearch,
    }    

    if (userSearch === '') {
      searchData.fromDate =
        selectedDate !== undefined ? selectedDate : moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD')
      searchData.toDate =
        selectedToDate !== undefined ? selectedToDate : moment(new Date()).format('YYYY-MM-DD')
    }

    if (!isUploadFileDone) {
      getLeadsDataAPICall(searchData)
      setApiCallTrigger((prev) => !prev);
    } else {
      if (getUploadDetails?.error?.length) {
        setIsErrorDialogOpen(true)
        toast.success(getUploadDetails?.message)
      } else {
        toast.success('Data uploaded successfully')
      }
      resetUploadingFile()
    }
  }
  }, [userSearch,dataLoaded, pageChange, selectedFilterOptions,filterOptions1,status,selectedDomain1, selectedDate, selectedToDate, limit, isUploadFileDone])

  useEffect(() => {
    const searchData: SearchData = {
      limit: limit,
      page: pageChange,
      fromDate: selectedDate || moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: selectedToDate || moment(new Date()).format('YYYY-MM-DD'),
      searchString: userSearch,
    }
    if (userSearch === '') {
      searchData.fromDate =
        selectedDate || moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD')
      searchData.toDate =
        selectedToDate|| moment(new Date()).format('YYYY-MM-DD')
    }

    if (isCreateLeadDone) {
      // getLeadsDataAPICall(searchData)
      toast.success('Lead Created successfully')
      resetCreateLead()
    }
  }, [isCreateLeadDone])

  useEffect(() => {
    if (isUpdateLeadDone) {
      if (getUpdateLeadDetails?.error?.length) {
        toast.success(getUpdateLeadDetails?.message)
      }else {
        toast.success(getUpdateLeadDetails?.message)
      }
      setOpenStatusDialog(false)
      setIsChecked(false)
      resetUpdateLead()
    }else if(isBulkEmailSendDone){
      if (getSendBulkEmailData?.error?.length) {
        toast.success(getSendBulkEmailData?.message)
      }else {
        toast.success(getSendBulkEmailData?.message)
      }
      ResetsendBulkEmailAPICall()
    }
  }, [isUpdateLeadDone,isBulkEmailSendDone])

  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage)
  }

  const useStyles = makeStyles((theme) => {
    return createStyles({
      datepicker: {
        margin: '10px',
        width: '170px',
        borderRadius:'20px',
      },
      dottedDivider: {
        width: '100%',
        textAlign: 'center',
        borderTop: '2px dotted' + theme.palette.divider,
        margin: '20px 0',
      },
      headingtxt: {
        color: '#000000',
        fontFamily: 'Montserrat-SemiBold !important',
        fontSize: '22px!important',
        whiteSpace: 'nowrap',
        
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      updateButton: {
        display: 'block',
        textTransform: 'capitalize',
        borderRadius: '20px',
        fontFamily: 'Montserrat-Medium',
        fontSize: '15px',
        backgroundColor: 'rgb(25, 60, 109)',
      },
      cancelButton: {
        marginRight: '10px',
        display: 'block',
        textTransform: 'capitalize',
        borderRadius: '20px',
        fontFamily: 'Montserrat-Medium',
        fontSize: '15px',
      },
      updateButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
      },
      headerContainer: {
        height: '8% !important',
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      },
      createAccoutContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '50%',
        width: '100%',
        justifyContent: 'right',
        gap: '2px',
      },
      titletxt: {
        fontFamily: 'Montserrat-SemiBold!important',
        fontSize: '18px!important',
        whiteSpace: 'nowrap',
        fontWeight: 500,
      },
      dealsize: {
        fontFamily: 'Montserrat-SemiBold!important',
        fontSize: '16px!important',
        whiteSpace: 'nowrap',
        fontWeight: 500,
      },
      addLead: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '42px',
        textTransform: 'capitalize',
        marginLeft: '10px',
        border: '0',
        color: '#fff',
        background: 'rgb(25, 60, 109)',
        borderRadius: '20px',
        fontFamily: 'Montserrat-Medium',
        fontSize: '14px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        width: '15%',
      },
      disable: {
        opacity: .5,
        cursor: 'not-allowed'
      },
      upload: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '42px',
        textTransform: 'capitalize',
        border: '1px solid rgba(20, 56, 113, 1)',
        color: '#fff',
        background: 'rgb(25, 60, 109)',
        borderRadius: '20px',
        fontFamily: 'Montserrat-Medium',
        fontSize: '14px',
        fontWeight: 'bold',
        width: '15%',
        whiteSpace: 'nowrap',
      },
      svgCreateAccout: {
        height: '33px',
        display: 'flex',
        width: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        background: '#0279ff',
        boxShadow: '0 -2px 0 0 inset #0061ca!important',
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      progressBarContainer: {
        position: 'relative',
      },
      progressBar: {
        position: 'absolute',
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    })
  })
  const classes = useStyles()
  const today = selectedDate ? new Date(selectedDate) : new Date()
  const formattedDate = today.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
  const fromDate = dayjs(formattedDate)

  const today1 = selectedToDate ? new Date(selectedToDate) : new Date()
  const formattedDate1 = today1.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
  const toDate = dayjs(formattedDate1)
  const onhandleChangeForFromDate = (e: any) => {
    setSelctedDate(moment(e.$d).format('YYYY-MM-DD'))
    setUserSearch('')
  }
  const onhandleChangeForToDate = (e: any) => {
    // setToSelctedDate(moment(new Date(e?.$d)).toISOString())
    setToSelctedDate(moment(e.$d).format('YYYY-MM-DD'))
    setUserSearch('')
  }
  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false)
  }

  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false)
  }

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      // setSelectedFile(event.target.files[0])
      // uploadFileAPICall({ File: event.target.files[0] })
      const selectedFile = event.target.files[0];
    if (selectedFile.type === "text/csv") {
      setSelectedFile(selectedFile);
      uploadFileAPICall({ File: selectedFile });
    } else {
      toast.error("Please select a CSV file.");
    }
    } else {
      setSelectedFile(null)
    }
  }

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = ".csv";
      fileInputRef.current.click()
    }
  }

  const handleOpenEmailDialog = () => {
    setOpenEmailDialog(true)
  }

  const openSendEmailDialog2 = () => {
    setOpenEmailDialog(false)
  }

  const handleOpenStatusDialog = () => {
    setOpenStatusDialog(true)
  }

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false)
  }

  const handleChange = (e: any) => {    
    setSelectedFilterOptions(e.value);
  };

  const selectedValueTemplate = () => {
    // const selectedOptions = Object.keys(selectedFilterOptions).filter(key => selectedFilterOptions[key].checked);

    const selectedOptions = selectedFilterOptions && typeof selectedFilterOptions === 'object'
    ? Object.keys(selectedFilterOptions).filter(key => selectedFilterOptions[key].checked)
    : [];

    if (selectedOptions.length === 0) {

      return 'Select Filter';
    } else if (selectedOptions.length === 1) {
      const selectedKey = selectedOptions[0];
      const selectedOption = findOptionByKey(filterOptions1, selectedKey as string);
      return selectedOption ? selectedOption.label : selectedKey;
    } else {
      return `${selectedOptions.length} Selected`;
    }
  };

  const findOptionByKey = (options: TreeNode[], key: string): TreeNode | null => {
    for (let option of options) {
      if (option.key === key) return option;
      if (option.children) {
        const childOption = findOptionByKey(option.children, key);
        if (childOption) return childOption;
      }
    }
    return null;
  };

  const safeSelectedFilterOptions = selectedFilterOptions && typeof selectedFilterOptions === 'object' ? selectedFilterOptions : {};

  const hasSelectedOptions = Object.keys(safeSelectedFilterOptions).some(
    key => safeSelectedFilterOptions[key]?.checked
  );

  return (
    <>
      <Box>
        {/* <Box className={classes.progressBarContainer}> */}
        {(isUploadingData || isUpdatingLeadData || isBulkEmailSending) && <div className="overlay" />}
        {(isUploadingData || isUpdatingLeadData || isBulkEmailSending) && <CircularProgress color='primary' className={classes.progressBar} />}
        {/* {isUploadingData && <CircularProgress color='primary' className={classes.progressBar} />} */}
        {/* </Box> */}
        <div className='dash' style={{ display: 'flex', flexDirection: 'column',margin:'12px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='container1'>
              <div className='date-pickers'>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <Box className={classes.datepicker}>
                        <DatePicker
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline":{borderRadius:'20px'}
                        }}
                        // sx={{borderRadius:'20px'}}
                          label='From Date'
                          value={fromDate}
                          onChange={onhandleChangeForFromDate}
                          disabled={searchValue.length ? true : false}
                          
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <Box className={classes.datepicker}>
                        <DatePicker
                         sx={{
                          "& .MuiOutlinedInput-notchedOutline":{borderRadius:'20px'}
                        }}
                          label='To Date'
                          value={toDate}
                          onChange={onhandleChangeForToDate}
                          minDate={fromDate}
                          disabled={searchValue.length ? true : false}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                </div>
              </div>
            <div className={classes.title} style={{position:'relative',right:'6%'}}>
              <Typography className={classes.headingtxt}>Deals Closed</Typography>
            </div>
            <div className={classes.title}>
            <Typography className={classes.dealsize}>Total Deal: <span style={{fontFamily: 'Montserrat-SemiBold',
        fontSize: '20px', color:'green'}}>${getLeadsData?.data?.dealSizeSum || 0}</span></Typography>
            </div>
            <div className='container2'style={{width:'45%'}}>
              <div>
                <Box className={classes.headerContainer}>
                  <Box className={classes.createAccoutContainer}>
                  <div className="card flex justify-content-center">
                        <TreeSelect
                          value={selectedFilterOptions}
                          options={filterOptions1}
                          onChange={handleChange}
                          placeholder="Select Filter"
                          valueTemplate={selectedValueTemplate}
                          className="md:w-20rem w-full custom-class" 
                          panelStyle={{ maxHeight: '200px', }}
                          scrollHeight="300px"
                          style={{
                            width: "150px",
                            borderRadius: "20px",
                            fontSize: "14px",
                          }}
                          selectionMode="checkbox"
                          filter
                          showClear={hasSelectedOptions}
                          resetFilterOnHide={true}
                        />
                      </div>
                    {/* {isChecked && ( */}
                    <Tooltip title="Send Email">
                    <button
                      className={clsx({ [classes.disable]: !isChecked }, classes.addLead)}
                      disabled={!isChecked}
                      style={{ marginRight: '10px', whiteSpace: 'nowrap' }}
                      onClick={handleOpenEmailDialog}
                    >
                      <MailOutlinedIcon sx={{height:'45%'}}/>
                    </button>
                    </Tooltip>
                    {/* )} */}
                    <input
                      type='file'
                      accept='.csv'
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      id='upload-file-input'
                      ref={fileInputRef}
                    />
                    {/* <label htmlFor="upload-file-input"> */}
                    {/* <button
                    className={classes.upload}
                    onClick={openFileSelector}>
                      <FileUploadOutlinedIcon sx={{ height: '50%' }} />
                      <span>Upload Excel</span>
                    </button> */}
                    {/* </label> */}
                    {(role === "admin") && (
                    <Tooltip title="Update Status">
                      <button
                        className={clsx(
                          { [classes.disable]: !isChecked },
                          classes.addLead
                        )}
                        style={{marginLeft:'0px'}}
                        disabled={!isChecked}
                        onClick={() => handleOpenStatusDialog()}
                      >
                        <UpdateOutlinedIcon sx={{ height: "45%" }} />
                        {/* Update Status */}
                      </button>
                    </Tooltip>
                    )}
                  </Box>
                </Box>
              </div>
            </div>
          </div>
          <div className='mail-receipt'>
            <DealsClosedTable
              limit={limit}
              messageId={messageId}
              selectedDate={selectedDate}
              selectedToDate={selectedToDate}
              filterOptions1={filterOptions1}
              selectedFilterOptions={selectedFilterOptions}
              selectedDomain1={selectedDomain1}
              // selectedCountries={selectedCountries}
              // selectedDomains={selectedDomains}
              setMessageId={setMessageId}
              rowsData={getLeadsData?.data?.leads}
              userSearch={userSearch}
              setType={setType}
              type={type}
              openSendEmailDialog1={openEmailDialog}
              openUpdateStatusDialog={openStatusDialog}
              apiCallTrigger={apiCallTrigger}
              openSendEmailDialog2={openSendEmailDialog2}
              handleCloseStatusDialog={handleCloseStatusDialog}
              isChecked={isChecked}
              onSelectStatusChange={handleSelectItemsChange}
              onSelectDomainChange={handleSelectDomainChange}
              onCheckboxChange={handleCheckboxChange}
              onSelectAll={handleSelectAll}
            // handleOpenEmailDialog={handleOpenEmailDialog}
            />
            <Pagination
              emailTrackData={getLeadsData?.data}
              handleChangePage={handleChangePage}
              handleLimitChange={handleLimitChange}
              pageChange={pageChange}
              limit={limit}
            />
          </div>
        </div>
      </Box>

      <Dialog
        open={isErrorDialogOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: '80%',
            maxWidth: '80%',
            margin: 'auto',
          },
        }}
      >
        <DialogTitle className={classes.titletxt} sx={{ display: 'flex', alignItems: 'center' }}>
          Error while uploading file
          <IconButton
            aria-label='close'
            onClick={handleErrorDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className='divider' />
        <DialogContent sx={{ paddingTop: '10px' }}>
          <div>
            <ol style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              {getUploadDetails?.error?.map((errorMessage: any, index: any) => (
                <li
                  key={index}
                  style={{ color: 'red', fontFamily: 'Montserrat-Regular', fontSize: '14px' }}
                >
                  {errorMessage?.message}
                </li>
              ))}
            </ol>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    getLeadsData: leadsDataEntity.getLeads(state).getLeadsData,
    getLeadsStatusData: leadsDataEntity.getLeads(state).getLeadsStatusData,
    getUploadDetails: leadsDataEntity.getLeads(state).getUploadDetails,
    isUploadFileDone: dashboardUI.getDashboard(state).isUploadFileDone,
    isCreateLeadDone: dashboardUI.getDashboard(state).isCreateLeadDone,
    isUploadingData: dashboardUI.getDashboard(state).isUploadingData,
    isUpdatingLeadData: dashboardUI.getDashboard(state).isUpdatingData,
    resetLeadsData: leadDataUI.getLeads(state).resetLeadsData,
    isGetLeadsDataDone: leadDataUI.getLeads(state).isGetLeadsDataDone,
    getUpdateLeadDetails: leadsDataEntity.getLeads(state).getUpdateLeadDetails,
    isBulkEmailSending: dashboardUI.getDashboard(state).isBulkEmailSending,
    isBulkEmailSendDone: dashboardUI.getDashboard(state).isBulkEmailSendDone,
    isUpdateLeadDone: dashboardUI.getDashboard(state).isUpdateLeadDone,
    getSendBulkEmailData: dashboardEntity.getDashboard(state).getSendBulkEmailData,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getLeadsDataAPICall: (data: any) => dispatch(getLeadsDataAPICall.request(data)),
    getLeadsStatusDataAPICall: (data: any) => dispatch(getLeadsStatusDataAPICall.request(data)),
    resetgetLeadsDataAPICall: () => dispatch(getLeadsDataAPICall.reset()),
    createLeadDataAPICall: (data: any) => dispatch(createLeadDataAPICall.request(data)),
    resetCreateLead: () => dispatch(createLeadDataAPICall.reset()),
    uploadFileAPICall: (data: any) => dispatch(uploadFileAPICall.request(data)),
    updateLeadDataAPICall: (data: any) => dispatch(updateLeadDataAPICall.request(data)),
    resetUploadingFile: () => dispatch(uploadFileAPICall.reset()),
    resetUpdateLead: () => dispatch(updateLeadDataAPICall.reset()),
    ResetsendBulkEmailAPICall: () => dispatch(sendBulkEmailAPICall.reset()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DealsClosed)
