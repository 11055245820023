import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { 
    GET_CONTACTS_DATA,
    CREATE_CONTACTS_DATA,
    UPLOAD_CONTACTS_FILE,
    GET_CONTACT_DETAILS_DATA,
 } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, } = actionTypes
const entity = () => {
    const getContactsData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_CONTACTS_DATA[SUCCESS]:
            return action.payload
          case GET_CONTACTS_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }

      const createContactsData = (state = [], action: actions) => {
        switch (action.type) {
          case CREATE_CONTACTS_DATA[SUCCESS]:
            return action.payload
          case CREATE_CONTACTS_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }

      const getUploadContactsData = (state = [], action: actions) => {
        switch (action.type) {
          case UPLOAD_CONTACTS_FILE[SUCCESS]:
            return action.payload
          case UPLOAD_CONTACTS_FILE[REQUEST]:
            return []
          default:
            return state
        }
      }
      const getContactDetailsData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_CONTACT_DETAILS_DATA[SUCCESS]:
            return action.payload
          case GET_CONTACT_DETAILS_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }

  return combineReducers({
    getContactsData,
    createContactsData,
    getUploadContactsData,
    getContactDetailsData,
  })
}

export default entity
export const getContact = (state: RootState) => state.entities.contact
