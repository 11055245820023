import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import { createLeadDataAPICall, getLeadsdetailsById, updateLeadDataAPICall } from '../../actions'
import { CircularProgress, Paper, createStyles, makeStyles, } from '@material-ui/core'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import countryCodes from '../Leads/Countrywithflag.json'
import * as options from './constant';
import countriesData from '../Leads/CountriesandStates.json'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  Divider,
  Button,
  Box
} from '@mui/material';
import { dashboardUI,leadDataUI, leadsDataEntity } from '../../reducers';

interface LeadData {
  id: any;
  first_name: string;
  leads_instaId: string,
  last_name: string;
  temperature: string;
  email: string;
  leadPhone: string;
  leads_country: string;
  company: string;
  source: string;
  domain: string;
  technology: string;
  description_of_lead: string;
  deal_size: string;
  follow_up_status: string;
  company_address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  lead_designation: string;
  lead_linkedIn_url: string;
  company_linkedIn_url: string;
  companySize: string;
  website: string;
  File: any;
  secondary_email?: string;
  companyPhone?: string; 
}

const EditLead = (props: any) => {
    let { id } = useParams();

  const {
    updateLeadDataAPICall,
    isGettingLeadsDataById,
    resetUpdateLead,
    getUpdateLeadDetails,
    getLeadsDetailsById,
    getLeadsDetailsByIdAPICall,
    // handleOpenEmailDialog,
  } = props  
    
  const navigate = useNavigate()
  const [leadId, setLeadId] = useState([id])
  const [firstName, setFirstName] = useState(getLeadsDetailsById?.data?.lead_detail?.first_name)
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [secondaryEmail, setSecondaryEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [leadTemperature, setLeadTemperature] = useState('')
  const [companyphone, setCompanyPhone] = useState('')
  const [statusData, setStatusData] = useState('')
  const [leadInstagram, setLeadInstagram] = useState('')
  const [countryCode, setCountryCode] = useState('+91')
  const [companycountryCode, setCompanyCountryCode] = useState('+91')
  const [dealSize, setDealSize] = useState('')
  const [leadDescription, setLeadDescription] = useState('')
  const [company, setCompany] = useState('')
  const [source, setSource] = useState('')
  const [domain, setDomain] = useState('')
  const [technology, setTechnology] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [zip, setZip] = useState('')
  const [companySize, setCompanySize] = useState('')
  const [companyLinkedin, setCompanyLinkedIn] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState('')
  const [leadLinkedin, setLeadLinkedIn] = useState('')
  const [leadCountry, setLeadCountry] = useState('')
  const [leadDesignation, setLeadDesignation] = useState('')
  const [status, setStatus] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [companyPhoneError, setCompanyPhoneError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [secondaryEmailError, setSecondaryEmailError] = useState(false)
  const [companyError, setCompanyError] = useState(false)
  const [domainError, setDomainError] = useState(false)
  // const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);  

  const fileInputRef = useRef<HTMLInputElement>(null)

  const useStyles = makeStyles((theme) => {
    return createStyles({
      dottedDivider: {
        width: '100%',
        textAlign: 'center',
        borderTop: '2px dotted' + theme.palette.divider,
        margin: '20px 0',
      },
      attachfilebox:{
        backgroundColor: '#f5f5f5',
        border: '1px solid transparent',
        fontWeight: 'bold',
        margin: '0 0 9px',
        overflowY: 'hidden',
        padding: '4px 4px 4px 8px',
        maxWidth: '448px',
        display: 'flex', 
      },
      headingtxt: {
        color: '#000000',
        fontFamily: 'Montserrat-SemiBold !important',
        fontSize: '22px!important',
        marginTop: '20px !important',
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      updateButton: {
        display: 'block!important',
        textTransform: 'capitalize',
        borderRadius: '20px !important',
        fontFamily: 'Montserrat-Medium !important',
        fontSize: '15px !important',
        backgroundColor: '#193C6D !important',
        '&:disabled': {
          opacity: '.5 !important',
          cursor: 'not-allowed !important',
          color: '#fff !important',
          backgroundColor: '#193C6D !important',
        }
      },
      cancelButton: {
        marginRight: '10px !important',
        display: 'block !important',
        textTransform: 'capitalize',
        borderRadius: '20px !important',
        fontFamily: 'Montserrat-Medium !important',
        fontSize: '15px !important',
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        color: '#000000 !important',
      },
      updateButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
      },
      headerContainer: {
        height: '8% !important',
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      },
      createAccoutContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '50%',
        width: '100%',
        justifyContent: 'center',
        gap: '2px',
      },
      titletxt: {
        fontFamily: 'Montserrat-SemiBold!important',
        fontSize: '18px!important',
        fontWeight: 500,
      },
      progressBar: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      input1: {
        '&::placeholder': {
          color: 'red',
        },
      },
    })
  })
  const classes = useStyles()

  useEffect(() => {
    let data = {
        leadId: id,
      };
    getLeadsDetailsByIdAPICall(data);
  }, []);

  useEffect(() => {
    const leadDetail = getLeadsDetailsById?.data;
    if (leadDetail) {
      setFirstName(leadDetail?.lead_detail?.first_name|| '');
      setLastName(leadDetail?.lead_detail?.last_name|| '')
      setPhone(leadDetail?.lead_detail?.leadPhone || '')
      setStatusData(leadDetail?.lead_detail?.status || '')
      setCompanyPhone(leadDetail?.company_detail?.companyPhone|| '')
      setCountryCode("+" + leadDetail?.lead_detail?.leadCountryCode|| '')
      setCompanyCountryCode("+" + leadDetail?.company_detail?.companyCountryCode|| '')
      setLeadTemperature(leadDetail?.lead_detail?.lead_temperature|| '')
      setEmail(leadDetail?.lead_detail?.lead_email|| '')
      setSecondaryEmail(leadDetail?.lead_detail?.secondary_email|| '')
      setCompany(leadDetail?.company_detail?.company|| '')
      setDomain(leadDetail?.company_detail?.domain|| '')
      setSource(leadDetail?.lead_detail?.source|| '')
      setTechnology(leadDetail?.company_detail?.technology|| '')
      setAddress(leadDetail?.company_detail?.address|| '')
      setCity(leadDetail?.company_detail?.city|| '')
      setState(leadDetail?.company_detail?.state|| '')
      setCountry(leadDetail?.company_detail?.country|| '')
      setZip(leadDetail?.company_detail?.zipcode|| '')
      setCompanySize(leadDetail?.company_detail?.size|| '')
      setCompanyLinkedIn(leadDetail?.company_detail?.linkedIn_url|| '')
      setDealSize(leadDetail?.lead_detail?.lead_deal_size|| '')
      setLeadDescription(leadDetail?.lead_detail?.lead_description|| '')
      setCompanyWebsite(leadDetail?.company_detail?.website|| '')
      setLeadLinkedIn(leadDetail?.lead_detail?.linkedIn_url|| '')
      setDealSize(leadDetail?.lead_detail?.lead_deal_size|| '')
      setLeadDescription(leadDetail?.lead_detail?.lead_description|| '')
      setLeadInstagram(leadDetail?.lead_detail?.leads_instaId|| '')
      setLeadCountry(leadDetail?.lead_detail?.lead_country|| '')
      setLeadDesignation(leadDetail?.lead_detail?.lead_designation|| '')
    }
  }, [getLeadsDetailsById]);

  const handleResetErrors = () => {
    setFirstName('')
    setLastName('')
    setPhone('')
    setCompanyPhone('')
    setCountryCode('+91')
    setCompanyCountryCode('+91')
    setEmail('')
    setSecondaryEmail('')
    setLeadTemperature('')
    setCompany('')
    setDomain('')
    setSource('')
    setTechnology('')
    setAddress('')
    setCity('')
    setState('')
    setCountry('')
    setZip('')
    setCompanySize('')
    setCompanyLinkedIn('')
    setCompanyWebsite('')
    setLeadLinkedIn('')
    setDealSize('')
    setLeadDescription('')
    setLeadInstagram('')
    setLeadCountry('')
    setDealSize('')
    setLeadDescription('')
    setLeadDesignation('')
    setNameError(false)
    setEmailError(false)
    setSecondaryEmailError(false)
    setPhoneError(false)
    setCompanyPhoneError(false)
    setCompanyError(false)
    setDomainError(false)
  }

  const handleAddDialog = (data: any) => {
    const leadData: LeadData = {
      id : leadId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      secondary_email: secondaryEmail,
      leadPhone: phone,
      temperature: leadTemperature,
      description_of_lead: leadDescription, 
      deal_size: dealSize,
      leads_instaId: leadInstagram,
      companyPhone: companyphone,
      company: company,
      leads_country: leadCountry,
      source: source,
      domain: domain,
      technology: technology,
      follow_up_status: statusData,
      company_address: address,
      city: city,
      state: state,
      country: country,
      zip_code: zip,
      lead_designation: leadDesignation,
      lead_linkedIn_url: leadLinkedin,
      company_linkedIn_url: companyLinkedin,
      companySize: companySize,
      website: companyWebsite,
      File: selectedFiles,
    }
    if (leadData) {
      updateLeadDataAPICall(leadData)
      handleResetErrors();
      const previousPath = localStorage.getItem('previousPath');
      const isSearched: any = sessionStorage.getItem('userSearch')
      const searchedData = JSON.parse(isSearched)
      if (previousPath) {
          window.location.href = previousPath;
          localStorage.removeItem('previousPath');
      } else if (searchedData){
          navigate(`/home/dashboard?search=${searchedData}`);
          // ?search=sudeep
      } else {
          navigate(`/home/dashboard`);
      }
    }
  }

  const handleAddLeadCancel = () => {
    // setIsEditDialogOpen(false)
    const previousPath = localStorage.getItem('previousPath');
    const isSearched: any = sessionStorage.getItem('userSearch')
    const searchedData = JSON.parse(isSearched)
    if (previousPath) {
        window.location.href = previousPath;
        localStorage.removeItem('previousPath');
    } else if (searchedData){
        navigate(`/home/dashboard?search=${searchedData}`);
        // ?search=sudeep
    } else {
      navigate(`/home/dashboard`);
      // ?search=sudeep
  }
    handleResetErrors()
  }

  const handleChangeForFirstName = (event: { target: { value: any } }) => {
    const value = event.target.value
    setFirstName(value)
    setNameError(value.trim() === '')
  }

  const handleChangeForLastName = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLastName(value)
  }

  const handleChangeForEmail = (event: { target: { value: any } }) => {
    const value = event.target.value
    setEmail(value)
    setEmailError(!isValidEmail(value))
  }

  const handleChangeForSecondaryEmail = (event: { target: { value: any } }) => {
    const value = event.target.value
    setSecondaryEmail(value)
    setSecondaryEmailError(!isValidEmail(value))
  }

  const handleChangeForPhone = (event: { target: { value: any } }) => {
    const value = event.target.value
    setPhone(value)
    setPhoneError(value.trim() === '')
  }

  const handleChangeForLeadInstagram = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadInstagram(value)
  }

  const handleChangeForCompanyPhone = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanyPhone(value)
    setCompanyPhoneError(value.trim() === '');
  }

  const handleChangeForCountryCode = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCountryCode(value)
  }

  const handleChangeForCompanyCountryCode = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanyCountryCode(value)
  }

  const handleChangeForCompany = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompany(value)
    setCompanyError(value.trim() === '')
  };

  const handleChangeForTemperature = (event: any) => {
    const newValue = event.target.value;
    setLeadTemperature(newValue);
  };

  const handleChangeForStatus = (event: any) => {
    const newValue = event.target.value;
    setStatusData(newValue);
  };

  const handleChangeForDomain = (event: { target: { value: any } }) => {
    const value = event.target.value
    setDomain(value)
    setDomainError(value.trim() === '')
  }

  const handleChangeForTechnology = (event: { target: { value: any } }) => {
    const value = event.target.value
    setTechnology(value)
  }

  const handleChangeForAddress = (event: { target: { value: any } }) => {
    const value = event.target.value
    setAddress(value)
  }

  const handleChangeForCity = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCity(value)
  }

  const handleChangeForState = (event: { target: { value: any } }) => {
    const value = event.target.value
    setState(value)
  }

  const handleChangeForCountry = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCountry(value)
  }

  const handleChangeForZip = (event: { target: { value: any } }) => {
    const value = event.target.value
    if (!isNaN(value))  {
      setZip(value)
    }
  }

  const handleChangeForCompanySize = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanySize(value)
  }

  const handleChangeForCompanyLinkedIn = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanyLinkedIn(value)
  }

  const handleChangeForDealSize = (event: { target: { value: any } }) => {
    const value = event.target.value
    setDealSize(value)
  }

  const handleChangeForLeadDescription = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadDescription(value)
  }


  const handleChangeForCompanyWebsite = (event: { target: { value: any } }) => {
    const value = event.target.value
    setCompanyWebsite(value)
  }

  const handleChangeForLeadDesignation = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadDesignation(value)
  }

  const handleChangeForLeadLinkedIn = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadLinkedIn(value)
  }

  const handleChangeForLeadCountry = (event: { target: { value: any } }) => {
    const value = event.target.value
    setLeadCountry(value)
  }

  const isValidEmail = (value: string) => {
    return /\S+@\S+\.\S+/.test(value)
  }

  const isValidPhoneNumber = (value: string) => {
    return /^\d{10}$/.test(value)
  }

  const selectedCountryData = countriesData.find((country1) => country1.name === country);
  const statesOfSelectedCountry = selectedCountryData?.states || [];

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const removeSelectedFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      setSelectedFiles([...selectedFiles, ...filesArray]);
    }
  };

  const allFieldsFilleds =
  firstName !== '' &&
    email!== '' &&
    company !== '' &&
    domain !== '' &&
    source !== '' &&
    !nameError &&
    !emailError &&
    // !phoneError &&
    // !companyPhoneError &&
    !companyError &&
    !domainError


  return (
    <>
    {isGettingLeadsDataById && <CircularProgress color='primary' className={classes.progressBar} />}
      <Paper style={{ margin: '16px', padding: '20px', paddingTop: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography className={classes.titletxt} sx={{ marginBottom: '5px' }}>Edit Lead</Typography>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center', gap: '10' }}> */}
        <Grid container spacing={2}>
          <Grid item xs={3}>
          <TextField
            id='firstname'
            label="Lead's First Name"
            sx={{ 
              marginTop: '6px', 
              marginBottom: '18px',
              '& input': {
                fontSize: '12px',
            }, 
            }}
            value={firstName}
            onChange={handleChangeForFirstName}
            error={nameError}
            helperText={nameError ? 'Name is required' : ''}
            InputLabelProps={{
              style: { top: '-10px' },
              shrink: true,
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          </Grid>
          <Grid item xs={3}>
          <TextField
            id='lastname'
            label="Lead's Last Name"
            required={false}
            sx={{ marginTop: '6px', 
            marginBottom: '18px',
            '& input': {
              fontSize: '12px',
          },
           }}
            value={lastName}
            onChange={handleChangeForLastName}
            InputLabelProps={{
              style: { top: '-10px' },
              shrink: true,
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          </Grid>
          <Grid item xs={6}>
          <TextField
            id='email'
            label="Lead's Email"
            disabled 
            sx={{ marginTop: '6px', 
            marginBottom: '18px',
            '& input': {
              fontSize: '12px',
          },
          }}
            value={email}
            onChange={handleChangeForEmail}
            error={emailError}
            helperText={emailError ? 'Invalid email format' : ''}
            InputLabelProps={{
              style: { top: '-10px' },
              shrink: true,
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          </Grid>
        </Grid>
        {/* </div> */}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              id='leadsdesignation'
              label="Lead's Designation"
              required={false}
              sx={{
                marginTop: '0',
                marginBottom: '18px',
                '& input': {
                  fontSize: '12px',
                },
              }}
              value={leadDesignation}
              onChange={handleChangeForLeadDesignation}
              InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={3}>
              <TextField
                select
                id='leadtemperature'
                value={leadTemperature}
                required={false}
                label='Lead Temperature'
                variant='outlined'
                sx={{ marginTop: '0', 
                marginBottom: '18px',
                '& input': {
                  fontSize: '12px',
                },
               }}
                onChange={handleChangeForTemperature}
                InputLabelProps={{
                  style: { top: '-10px' },
                  shrink: true,
                }}
                InputProps={{ sx: { borderRadius: 20,fontSize: '12px', } }}
              >
                <MenuItem value='Cold'>Cold</MenuItem>
                <MenuItem value='Warm'>Warm</MenuItem>
                <MenuItem value='Hot'>Hot</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
          <TextField
            id='email'
            label="Lead's Secondary Email"
            sx={{ marginTop: '0', 
                marginBottom: '18px',
                '& input': {
                  fontSize: '12px',
                },
              }}
            value={secondaryEmail}
            onChange={handleChangeForSecondaryEmail}
            error={secondaryEmailError}
            helperText={secondaryEmailError ? 'Invalid email format' : ''}
            InputLabelProps={{
              style: { top: '-10px' },
              shrink: true,
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
          </Grid> 
          <Grid item xs={3}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10' }}>
              {/* <Select
                value={countryCode}
                onChange={handleChangeForCountryCode}
                // label='Country Code'
                sx={{
                  minWidth: '125px',
                  marginBottom: '18px',
                  marginRight: '5px',
                  borderRadius: '20px',
                }}
                renderValue={(selected) => {
                  const selectedCountry = countryCodes.find(
                    (country) => country.dial_code === selected,
                  )
                  if (selectedCountry) {
                    return `(${selectedCountry.dial_code}) ${selectedCountry.emoji}`
                  }
                  return ''
                }}
              >
                {countryCodes.map((country: any) => (
                  <MenuItem key={country.code} value={country.dial_code}>
                    {`(${country.dial_code}) ${country.emoji} ${country.name}`}
                  </MenuItem>
                ))}
              </Select> */}
              <TextField
                id='phone'
                label="Lead's Phone No."
                sx={{ marginTop: '0', 
                marginBottom: '18px',
                '& input': {
                  fontSize: '12px',
                },
              }}
                required={false}
                value={phone}
                onChange={handleChangeForPhone}
                error={phoneError}
                helperText={phoneError ? 'Invalid phone number' : ''}
                InputLabelProps={{
                  style: { top: '-10px' },
                  shrink: true,
                }}
                InputProps={{ sx: { borderRadius: 20 } }}
              // inputProps={{
              //   maxLength: 10,
              // }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id='address'
              label="Lead's Description"
              multiline
              required={false}
              sx={{ marginTop: '0', 
              marginBottom: '18px',
              '& input': {
                fontSize: '12px',
              },
            }}
              value={leadDescription}
              onChange={handleChangeForLeadDescription}
              inputProps={{style: {fontSize: '12px'}}}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ sx: { padding:'0',borderRadius:'20px' } }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              id='leadslinkedIn'
              label="Lead's LinkedIn"
              required={false}
              sx={{ marginTop: '0', 
              marginBottom: '18px',
              '& input': {
                fontSize: '12px',
              },
             }}
              value={leadLinkedin}
              onChange={handleChangeForLeadLinkedIn}
              InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='leadsInstagram'
              label="Lead's Instagram"
              required={false}
              sx={{ marginTop: '0', 
              marginBottom: '18px',
              '& input': {
                fontSize: '12px',
              },
            }}
              value={leadInstagram}
              onChange={handleChangeForLeadInstagram}
              InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
                id='leadscountry'
                select
                label="Lead's Country"
                required={false}
                sx={{
                  marginTop: '0', marginBottom: '18px',
                  '& .MuiSelect-select': {
                    fontSize: '12px',  
                  },
                }}
                value={leadCountry}
                onChange={handleChangeForLeadCountry}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: '250px', 
                      },
                    },
                  },
                }}
                InputLabelProps={{
                  style: { top: '-8px' },
                  shrink: true,
                }}
                InputProps={{ sx: { borderRadius: 20, fontSize: '12px', } }}
              >
                {countriesData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((country, index) => (
                  <MenuItem key={index} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='dealsize'
              label="Deal Size ($)"
              required={false}
              sx={{ marginTop: '0', 
              marginBottom: '18px',
              '& input': {
                fontSize: '12px',
              },
             }}
              value={dealSize}
              onChange={handleChangeForDealSize}
              InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{ borderColor: 'text.secondary', marginBottom: '18px' }}
        />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id='company'
              label='Company'
              sx={{ marginTop: '0', 
              marginBottom: '18px',
              '& input': {
                fontSize: '12px',
              },
             }}
              value={company}
              onChange={handleChangeForCompany}
              error={companyError}
              helperText={companyError ? 'Company is required' : ''}
              InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={4}>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
              {/* <Select
                value={companycountryCode}
                onChange={handleChangeForCompanyCountryCode}
                // label='Country Code'
                sx={{
                  minWidth: '125px',
                  marginBottom: '18px',
                  marginRight: '5px',
                  borderRadius: '20px',
                }}
                renderValue={(selected) => {
                  const selectedCountry = countryCodes.find(
                    (country) => country.dial_code === selected,
                  )
                  if (selectedCountry) {
                    return `(${selectedCountry.dial_code}) ${selectedCountry.emoji}`
                  }
                  return ''
                }}
              >
                {countryCodes.map((country: any) => (
                  <MenuItem key={country.code} value={country.dial_code}>
                    {`(${country.dial_code}) ${country.emoji} ${country.name}`}
                  </MenuItem>
                ))}
              </Select> */}
              <TextField
                id='phone'
                label="Company's Phone No."
                required={false}
                sx={{ marginTop: '0', 
                marginBottom: '18px',
                '& input': {
                  fontSize: '12px',
                },              
              }}
                value={companyphone}
                onChange={handleChangeForCompanyPhone}
                error={companyPhoneError}
                helperText={companyPhoneError ? 'Invalid phone number' : ''}
                InputLabelProps={{
                  style: { top: '-10px' },
                  shrink: true,
                }}
                InputProps={{ sx: { borderRadius: 20 } }}
              />
              {/* </div> */}
              </Grid>              
            <Grid item xs={4}>
              <TextField
                select
                id='demo-simple-select'
                value={statusData}
                label='Status'
                variant='outlined'
                sx={{ marginTop: '0', 
                marginBottom: '18px',
                '& input': {
                  fontSize: '12px',
                },
               }}
               SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: '250px', 
                    },
                  },
                },
              }}
               InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
              }}
                onChange={handleChangeForStatus}
                InputProps={{ sx: { borderRadius: 20,fontSize: '12px', } }}
              >
             {options.statusOptions.map((option:any) => (
              <MenuItem key={option} value={option}>
              {option}
              </MenuItem>
              ))}
              </TextField>
            </Grid> 
          <Grid item xs={4} sx={{ paddingTop: '0!important' }}>
            <TextField
              id='technology'
              label='Technology'
              required={false}
              sx={{ marginTop: '0', 
              marginBottom: '18px',
              '& input': {
                fontSize: '12px',
              },
               }}
              value={technology}
              onChange={handleChangeForTechnology}
              InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
              }}
              InputProps={{ sx: { borderRadius: 20 } }}
            />
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: '0!important' }}>
            <TextField
              select
              id='domain'
              label='Domain'
              sx={{ marginTop: '0', marginBottom: '18px',
              '& input': {
                fontSize: '12px',
              },
               }}
              value={domain}
              onChange={handleChangeForDomain}
              error={domainError}
              helperText={domainError ? 'Domain is required' : ''}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: '250px', 
                    },
                  },
                },
              }}
              InputLabelProps={{
                style: { top: '-10px', },
                shrink: true,
              }}
              InputProps={{ sx: { borderRadius: 20,fontSize: '12px',  } }}
            >
              {options.domainOptions.map((option:any) => (
              <MenuItem key={option} value={option}>
              {option}
              </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: '0!important' }}>
            <TextField
              select
              id='source'
              value={source}
              label='Source'
              sx={{ marginTop: '0', marginBottom: '18px',
              '& input': {
                fontSize: '14px',
              }, }}
              onChange={(e) => setSource(e.target.value)}
              InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
                
              }}
              InputProps={{ sx: { borderRadius: 20, fontSize: '12px', } }}
            >
              {options.sourceOptions.map((option:any) => (
              <MenuItem key={option} value={option}>
              {option}
              </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <TextField
            id='companyLinkeIn'
            label='Company LinkedIn Profile'
            required={false}
            sx={{ marginTop: '0', marginBottom: '18px',
            '& input': {
              fontSize: '12px',
            },
             }}
            value={companyLinkedin}
            onChange={handleChangeForCompanyLinkedIn}
            InputLabelProps={{
              style: { top: '-10px' },
              shrink: true,
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
           <TextField
              select
              id='companySize'
              required={false}
              value={companySize}
              label='Company Size'
              sx={{ marginTop: '0', marginBottom: '18px',
              '& input': {
                fontSize: '14px',
              }, }}
              onChange={handleChangeForCompanySize}
              InputLabelProps={{
                style: { top: '-10px' },
                shrink: true,
                
              }}
              InputProps={{ sx: { borderRadius: 20, fontSize: '12px', } }}
            >
              {options.companySizeOptions.map((option:any) => (
              <MenuItem key={option} value={option}>
              {option}
              </MenuItem>
              ))}
            </TextField>
          <TextField
            id='companyWebsite'
            label='Company website'
            required={false}
            sx={{ marginTop: '0', marginBottom: '18px',
            '& input': {
              fontSize: '12px',
            },
             }}
            value={companyWebsite}
            onChange={handleChangeForCompanyWebsite}
            InputLabelProps={{
              style: { top: '-10px' },
              shrink: true,
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
        </div>
        <TextField
          id='address'
          label='Address'
          required={false}
          sx={{ marginTop: '0', marginBottom: '18px',
          '& input': {
            fontSize: '12px',
          },
           }}
          value={address}
          onChange={handleChangeForAddress}
          InputLabelProps={{
            style: { top: '-10px' },
            shrink: true,
          }}
          InputProps={{ sx: { borderRadius: 20 } }}
        />
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <TextField
                id='country'
                select
                label='Country'
                required={false}
                sx={{
                  marginTop: '0', marginBottom: '18px',
                  '& .MuiSelect-select': {
                    fontSize: '12px',  
                  },
                }}
                value={country}
                onChange={handleChangeForCountry}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: '250px', 
                      },
                    },
                  },
                }}
                InputLabelProps={{
                  style: { top: '-8px' },
                  shrink: true,
                }}
                InputProps={{ sx: { borderRadius: 20,fontSize: '12px', } }}
              >
                {countriesData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((country, index) => (
                  <MenuItem key={index} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </TextField>
            <TextField
              select
              id='state'
              label='State'
              required={false}
              sx={{ marginTop: '0', marginBottom: '18px',
              '& .MuiSelect-select': {
                fontSize: '12px', 
              },
               }}
              value={state}
              onChange={handleChangeForState}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: '250px', 
                    },
                  },
                },
              }}
              InputLabelProps={{
                style: { top: '-8px' },
                shrink: true,
              }}
              InputProps={{ sx: { borderRadius: 20,fontSize: '12px', } }}
            >
            {statesOfSelectedCountry
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((state, index) => (
                <MenuItem key={index} value={state.name}>
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          <TextField
            id='city'
            label='City'
            required={false}
            sx={{ marginTop: '0', marginBottom: '18px',
            '& input': {
              fontSize: '12px',
            },
             }}
            value={city}
            onChange={handleChangeForCity}
            InputLabelProps={{
              style: { top: '-10px' },
              shrink: true,
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
        {/* </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> */}
          <TextField
            id='zip'
            label='Zip'
            required={false}
            sx={{ marginTop: '0', marginBottom: '18px',
            '& input': {
              fontSize: '12px',
            },
             }}
            value={zip}
            onChange={handleChangeForZip}

            InputLabelProps={{
              style: { top: '-10px', borderRadius: '20px' },
              shrink: true,
            }}
            InputProps={{ sx: { borderRadius: 20 } }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {selectedFiles.map((file, index) => (
            <div key={index} className='attachfilebox'>
              <span style={{ fontFamily: 'Montserrat-Medium', fontSize: '12px', color: '#000000', marginLeft: '10px' }}>
                {file.name}
              </span>
              <button onClick={() => removeSelectedFile(index)} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
                <ClearOutlinedIcon sx={{ height: '15px' }} />
              </button>
            </div>
          ))}
          <input
            type='file'
            accept='.csv'
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id='upload-file-input'
            ref={fileInputRef}
            multiple 
          />
          <button className='Submit' style={{ width: '20%' }} onClick={openFileSelector}>
            <span>+ Attach File</span>
          </button>
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '5px' }}>
          <Button
            className={classes.cancelButton}
            variant='contained'
            onClick={() => {
              handleAddLeadCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className={classes.updateButton}
            variant='contained'
            color='primary'
            disabled={!allFieldsFilleds}
            onClick={() => handleAddDialog('')}
          >
            Update
          </Button>
        </Box>
      </Paper>
    </>
  )
};

const mapStateToProps = (state: any) => {
  return {
    isUpdateLeadDone: dashboardUI.getDashboard(state).isUpdateLeadDone,
    getUpdateLeadDetails: leadsDataEntity.getLeads(state).getUpdateLeadDetails,
    getLeadsDetailsById: leadsDataEntity.getLeads(state).getLeadsDetailsById,
    isGettingLeadsDataById: leadDataUI.getLeads(state).isGettingLeadsDataById,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getLeadsDetailsByIdAPICall: (data: any) => dispatch(getLeadsdetailsById.request(data)),
    createLeadDataAPICall: (data: any) => dispatch(createLeadDataAPICall.request(data)),
    updateLeadDataAPICall: (data: any) => dispatch(updateLeadDataAPICall.request(data)),
    resetCreateLead: () => dispatch(createLeadDataAPICall.reset()),
    resetUpdateLead: () => dispatch(updateLeadDataAPICall.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLead);



