import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { createStyles, makeStyles } from "@material-ui/core";
import "./Campaign.css";
import { connect } from "react-redux";
import { addCampaignDataAPICall, getCampaignDataAPICall, getCampaignDetailsDataAPICall, removeLeadDataAPICall, sendCampaignEmailAPICall } from "../../actions";
import {
  campaignDataEntity,
  campaignDataUI,
  dashboardEntity,
  dashboardUI,
} from "../../reducers";
import AddCampaign from "./AddCampaign";
import { toast } from "react-toastify";
import moment from "moment";
import CampaignDetails from "./CampaignDetails";
import CampaignPagination from "./campaignPagination";
import SendEmailCampaign from "./SendEmailCampaign";

const style = {
  dialogStyle: {
    padding:"0.5rem 0",
    position: "fixed",
    top: "40px",
    left: "calc(50% - 480px)",
    height: "fit-content",
    maxHeight: '100vh',
    zIndex: "10000",
    bgcolor: "#fff",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    overflow: "scroll",
    borderRadius: "3px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  bialogBg: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "max(100%,100vh)",
    zIndex: "9999",
    bgcolor: "black",
    opacity: "0.5",
  },
};

const Campaign = (props: any) => {
  const {
    getCampaignDataAPICall,
    isCampaignSendEmailDone,
    getCampaignDetailsDataAPICall,
    sendEmailCampaignData,
    resetsendCampaignEmailAPICall,
    getCampaignData,
    getCampaignMailListData,
    getRemoveLeadData,
    isRemovingLeadDataDone,
    gettingAddCampaignData,
    getCampaignDetailsData,
    getAddCampaignData,
    resetAddCampaign,
    resetRemoveLead,
    isAddCampaignDataDone,
  } = props;

  const [isCreateCampaignDialogOpen, setIsCreateCampaignDialogOpen] =
    useState(false);
  const [isCampaignDetailsDialogOpen, setIsCampaignDetailsDialogOpen] =
    useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [handleEmailBox, setHandleEmailBox] = useState({campaignId:'',leadNames:[],emailIds:[]})
  const [campaignId, setcampaignId] = useState(" ");
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [emailLogs,setEmailLogs] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [pageChange, setPageChange] = useState(1);
  const [dialogClosed, setDialogClosed] = useState(false); 

  
  useEffect(() => {
    const campaignData = {
      page: pageChange,
      limit: limit,
    }
    getCampaignDataAPICall(campaignData);
  }, [limit,pageChange]);
  
  useEffect(() => {
    if (getCampaignMailListData) {
      setEmailLogs(getCampaignMailListData?.emailLogs);
    }
  }, [getCampaignMailListData]);
  
  useEffect(() => {
    if (campaignId !== " ") {
      let data = {
        id: campaignId,
        page: page,
        pageSize: pageLimit,
      };
      getCampaignDetailsDataAPICall(data);
    }
  }, [page, pageLimit]);
  
  useEffect(() => {
    if (isAddCampaignDataDone) {
      if (getAddCampaignData?.error?.length) {
        toast.error(getAddCampaignData?.message);
      } else {
        toast.success(getAddCampaignData?.message);
      }
      const campaignData = {
        page: pageChange,
        limit: limit,
      }
      getCampaignDataAPICall(campaignData);
      setIsCreateCampaignDialogOpen(false);
      resetAddCampaign();
    } else if (isCampaignSendEmailDone) {
      if (sendEmailCampaignData?.error?.length) {
        toast.error(sendEmailCampaignData?.message);
      } else {
        toast.success(sendEmailCampaignData?.message);
      }
      resetsendCampaignEmailAPICall();
    } else if (isRemovingLeadDataDone) {
      let data = {
        id: campaignId,
      };
      getCampaignDetailsDataAPICall(data);
      toast.success(getRemoveLeadData?.message);
      resetRemoveLead();
    }
  }, [isAddCampaignDataDone, isRemovingLeadDataDone, isCampaignSendEmailDone]);
  
  const handlePageSizeChange = (newPageSize: any) => {
    setPage(newPageSize);
  };

  const handlePageLimitChange = (newPageLimit: any) => {
    setPageLimit(newPageLimit);
  };
  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      datepicker: {
        margin: "0px 0 0px 10px !important",
        width: "170px",
        borderRadius: "20px",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#add8e6",
        margin: "5px",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "100% !important",
        wordBreak: "break-word",
      },
      progressBar: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  const classes = useStyles();

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage);
  };

  const handleRowClick = (id: any) => {
    setcampaignId(id);
    setIsCampaignDetailsDialogOpen(true);
      let data = {
        id: id,
        page: page,
        pageSize: pageLimit,
      };
      getCampaignDetailsDataAPICall(data);
  };

  const handleCanpaignDetailsDialogClose = () => {
    setIsCampaignDetailsDialogOpen(false);
    setPageLimit(10)
    setEmailLogs([])
    setDialogClosed(true); 
  };

  const handleCreateCanpaignDialogOpen = () => {
    setIsCreateCampaignDialogOpen(true);
  };

  const handleCreateCanpaignDialogClose = () => {
    setIsCreateCampaignDialogOpen(false);
  };

  const handleSendEmailCloseDialog = () => {
    setOpenSendEmailDialog(false);
    setHandleEmailBox({campaignId:'',leadNames:[],emailIds:[]})
  };

  return (
    <Paper sx={{ margin: "10px", minHeight: "300px" }}>
      {gettingAddCampaignData && <div className="overlay" />}
      {gettingAddCampaignData && (
        <CircularProgress color="primary" className={classes.progressBar} />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <Typography
            className={classes.headingtxt}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
          >
            Campaigns
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="primary"
            className={classes.addLead}
            sx={{ borderRadius: "20px", margin: "10px" }}
            onClick={() => handleCreateCanpaignDialogOpen()}
          >
            <AddOutlinedIcon sx={{ height: "60%" }} />
            Create Campaign
          </Button>
        </div>
      </div>
      <div
        style={{
          margin: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1",
          minHeight: "360px",
        }}
      >
        <TableContainer sx={{ marginBottom: "10px", width: "100%" }}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
            >
              <TableRow>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Campaign Name</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Type</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Status</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Start Date</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">End Date</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Owner</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getCampaignData?.data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    {gettingAddCampaignData ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "120px",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Data Available
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                getCampaignData?.data?.map((campaign: any, index: any) => (
                  <TableRow
                    key={index}
                    sx={{ cursor: "pointer", background: "#ffffff" }}
                    onClick={() => handleRowClick(campaign.id)}
                  >
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {campaign.name ? campaign.name : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {campaign.type ? campaign.type : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {campaign.status ? campaign.status : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {campaign.start_date
                        ? moment(campaign.start_date).format("MM/DD/YYYY")
                        : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {campaign.end_date
                        ? moment(campaign.end_date).format("MM/DD/YYYY")
                        : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {campaign?.created_by ? campaign?.created_by : "NA"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <CampaignPagination
              campaignTableData={getCampaignData}
              handleChangePage={handleChangePage}
              handleLimitChange={handleLimitChange}
              pageChange={pageChange}
              limit={limit}
            />
        </TableContainer>
      </div>
      <Dialog
        open={isCreateCampaignDialogOpen}
        onClose={handleCreateCanpaignDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "70%",
            maxWidth: "70%",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          Add Campaign
          <IconButton
            aria-label="close"
            onClick={handleCreateCanpaignDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <AddCampaign
            handleCreateCanpaignDialogClose={handleCreateCanpaignDialogClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCampaignDetailsDialogOpen && handleEmailBox?.campaignId === ''}
        onClose={handleCanpaignDetailsDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: 'auto',
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", padding:'5px 24px' }}>
          Campaign Details
          <IconButton
            aria-label="close"
            onClick={handleCanpaignDetailsDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="campaign-divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <CampaignDetails
            getCampaignDetailsData={getCampaignDetailsData}
            handleCampaignDetailsDialogClose={handleCanpaignDetailsDialogClose}
            onPageSizeChange={handlePageSizeChange}
            onPageLimitChange={handlePageLimitChange}
            setHandleEmailBox={setHandleEmailBox}
            campaignId1={campaignId}
            page={page}
            pageLimit={pageLimit}
            emailLogs={emailLogs}
            dialogClosed={dialogClosed}
          />
        </DialogContent>
      </Dialog>

      {handleEmailBox?.campaignId !== '' && (
        <>
          <Box
            sx={{
              ...style.bialogBg,
              display: handleEmailBox?.campaignId !== '' ? "block" : "none",
            }}
          ></Box>
          <Box
            sx={{
              ...style.dialogStyle,
              display: handleEmailBox?.campaignId !== '' ? "block" : "none",
            }}
          >
            <DialogTitle
              sx={{ padding: "0 15px 10px !important" }}
              className={classes.addcomment}
            >
              Send Email
              <IconButton
                aria-label="close"
                onClick={handleSendEmailCloseDialog}
                sx={{
                  position: "absolute !important",
                  right: '15px',
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="divider" />
            <SendEmailCampaign
              leadNames={handleEmailBox?.leadNames}
              emailIds={handleEmailBox?.emailIds}
              campaignId={handleEmailBox?.campaignId}
              handleCloseCampaign={handleSendEmailCloseDialog}
              setHandleEmailBox={setHandleEmailBox}
            />
          </Box>
          </>
      )}
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAddCampaignData:
      campaignDataEntity.getCampaign(state).getAddCampaignData,
    isRemovingLeadDataDone: campaignDataUI.getCampaign(state).isRemovingLeadDataDone,
    getCampaignMailListData: campaignDataEntity.getCampaign(state).getCampaignMailListData,
    getRemoveLeadData: campaignDataEntity.getCampaign(state).getRemoveLeadData,
    getCampaignData: campaignDataEntity.getCampaign(state).getCampaignData,
    getCampaignDetailsData: campaignDataEntity.getCampaign(state).getCampaignDetailsData,
    gettingAddCampaignData: campaignDataUI.getCampaign(state).gettingAddCampaignData,
    isAddCampaignDataDone: campaignDataUI.getCampaign(state).isAddCampaignDataDone,
    isCampaignSendEmailDone: dashboardUI.getDashboard(state).isCampaignSendEmailDone,
    sendEmailCampaignData: dashboardEntity.getDashboard(state).sendEmailCampaignData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getCampaignDataAPICall: (data: any) =>
      dispatch(getCampaignDataAPICall.request(data)),
    getCampaignDetailsDataAPICall: (data: any) => dispatch(getCampaignDetailsDataAPICall.request(data)),
    resetRemoveLead: () => dispatch(removeLeadDataAPICall.reset()),
    resetAddCampaign: () => dispatch(addCampaignDataAPICall.reset()),
    resetsendCampaignEmailAPICall: () => dispatch(sendCampaignEmailAPICall.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
