import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { DOWNLOAD_LEAD_FILE,SET_LEADS_COLUMN_DATA, RESET, } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE, LEADS_DATA,LEADS_DETAILS_BY_ID } = actionTypes

const ui = () => {
  const isGetLeadsDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case LEADS_DATA[SUCCESS]:
        return true
      case LEADS_DATA[FAILURE]:
      case LEADS_DATA[RESET]:
      case LEADS_DATA[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isSetLeadsColumnDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case SET_LEADS_COLUMN_DATA[SUCCESS]:
        return true
      case SET_LEADS_COLUMN_DATA[FAILURE]:
      case SET_LEADS_COLUMN_DATA[RESET]:
      case SET_LEADS_COLUMN_DATA[REQUEST]:
        return false
      default:
        return state
    }
  }

  const resetLeadsData = (state = false, action: actions) => {
    switch (action.type) {
      case LEADS_DATA[SUCCESS]:
        return false
      case LEADS_DATA[REQUEST]:
        return false
      case LEADS_DATA[FAILURE]:
        return false
      case LEADS_DATA[RESET]:
        return true
      default:
        return state
    }
  }

  const isGettingLeadsData = (state = false, action: actions) => {
    switch (action.type) {
      case LEADS_DATA[SUCCESS]:
        return false
      case LEADS_DATA[REQUEST]:
        return true
      case LEADS_DATA[FAILURE]:
        return false
      case LEADS_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingLeadsDataById = (state = false, action: actions) => {
    switch (action.type) {
      case LEADS_DETAILS_BY_ID[SUCCESS]:
        return false
      case LEADS_DETAILS_BY_ID[REQUEST]:
        return true
      case LEADS_DETAILS_BY_ID[FAILURE]:
        return false
      case LEADS_DETAILS_BY_ID[RESET]:
        return false
      default:
        return state
    }
  }

  const isDownloadableLeadFile = (state = false, action: actions) => {
    switch (action.type) {
      case DOWNLOAD_LEAD_FILE[SUCCESS]:
        return true
      case DOWNLOAD_LEAD_FILE[REQUEST]:
      case DOWNLOAD_LEAD_FILE[FAILURE]:
      case DOWNLOAD_LEAD_FILE[RESET]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isGetLeadsDataDone,
    isSetLeadsColumnDataDone,
    resetLeadsData,
    isGettingLeadsDataById,
    isGettingLeadsData,
    isDownloadableLeadFile,
    // isLodingLeadData,
    // isDataValidated,
    // resetValidation,
    // isValidatingData
  })
}

export default ui
export const getLeads = (state: RootState) => state.ui.leads
