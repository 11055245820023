// import React, { useEffect, useState } from "react";
// import { dashboardEntity } from "../../reducers";
// import ApexCharts from "apexcharts";
// import { connect } from "react-redux";

// const TrackEmailChart = (props: any) => {
//   const { getEmailGraphData } = props;

//   const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);

//   useEffect(() => {
//     if (getEmailGraphData) {
//       const mappedData = getEmailGraphData?.map(
//         (item: { From: any; totalemailCount: any; name: any }) => ({
//           name: item?.name,
//           y: item?.totalemailCount,
//         })
//       );
//       setChartData(mappedData);
//     }
//   }, [getEmailGraphData]);

//   useEffect(() => {
//     if (chartData?.length > 0) {
//       const total = chartData.reduce((acc, item) => acc + item.y, 0);
//       const options = {
//         series: chartData.map((item) => item.y),
//         chart: {
//           height: 350,
//           width: 350,
//           type: "pie",
//         },
//         labels: chartData.map((item) => item.name),
//         dataLabels: {
//           enabled: true,
//           formatter: function (val: any, opts: any) {
//             return opts.w.config.series[opts.seriesIndex];
//           },
//           style: {
//             fontSize: "12px",
//             fontFamily: "Montserrat-SemiBold",
//             fontWeight: "bold",
//             colors: ["#ffffff"],
//           },
//         },
//         tooltip: {
//           enabled: true,
//           y: {
//             formatter: function (val: any, opts: any) {
//               const count = opts?.config?.series[opts.seriesIndex];
//               const percentage = ((count / total) * 100).toFixed(2);
//               return `${val} (${percentage}%)`;
//             },
//             title: {
//               formatter: function (seriesName: any) {
//                 return seriesName + ":";
//               },
//             },
//           },
//           style: {
//             fontSize: "12px",
//             fontFamily: "Montserrat-SemiBold",
//             fontWeight: "bold",
//             colors: ["#ffffff"],
//           },
//         },
//         legend: {
//           position: "bottom",
//         },
//       };

//       const chart = new ApexCharts(document.querySelector("#chart"), options);
//       chart.render();

//       return () => {
//         chart.destroy();
//       };
//     }
//   }, [chartData]);

//   return (
//     <div id="chart" style={{ display: "flex", justifyContent: "center" }} />
//   );
// };

// const mapStateToProps = (state: any) => {
//   return {
//     getEmailGraphData: dashboardEntity.getDashboard(state).getEmailGraphData,
//   };
// };

// const mapDispatchToProps = (dispatch: any) => {
//   return {

//   };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(TrackEmailChart);

import React, { useEffect, useState } from "react";
import { getAnalyticsGraphDataAPICall } from "../../actions";
import { analyticsDataEntity, dashboardEntity } from "../../reducers";
import ApexCharts from "apexcharts";
import { connect } from "react-redux";

const TrackEmailChart = (props: any) => {
  const { getEmailGraphData } = props;

  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);

  useEffect(() => {
    if (getEmailGraphData) {
      const mappedData = getEmailGraphData?.map(
        (item: { count: any; Name: any }) => ({
          name: item?.Name,
          y: item?.count,
        }))
        .filter((item:any) => item.y > 0);
      setChartData(mappedData);
    }
  }, [getEmailGraphData]);

  useEffect(() => {
    if (chartData?.length > 0) {
      const total = chartData.reduce((acc, item) => acc + item.y, 0);
      const options = {
        series: chartData.map((item) => item.y),
        chart: {
          height: 350,
          width: 350,
          type: "pie",
        },
        labels: chartData.map((item) => item.name),
        dataLabels: {
          enabled: true,
          formatter: function (val: any, opts: any) {
            return opts.w.config.series[opts.seriesIndex];
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat-SemiBold",
            fontWeight: "bold",
            colors: ["#ffffff"],
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val: any, opts: any) {
              const count = opts?.config?.series[opts.seriesIndex];
              const percentage = ((count / total) * 100).toFixed(2);
              return `${val} (${percentage}%)`;
            },
            title: {
              formatter: function (seriesName: any) {
                return seriesName + ":";
              },
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat-SemiBold",
            fontWeight: "bold",
            colors: ["#ffffff"],
          },
        },
        legend: {
          position: "bottom",
        },
      };

      const chart = new ApexCharts(
        document.querySelector("#emailChart"),
        options
      );
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  return (
    <div id="emailChart" style={{ display: "flex", justifyContent: "center" }} />
  );
};

const mapStateToProps = (state: any) => {
  return {
    getEmailGraphData: dashboardEntity.getDashboard(state).getEmailGraphData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
   
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(TrackEmailChart);
