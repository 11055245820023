import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { 
    ADD_CAMPAIGN_DATA,
    GET_CAMPAIGN_DATA,
    GET_CAMPAIGN_MAIL_LIST_DATA,
    GET_CAMPAIGN_NAME_DATA,
    GET_CAMPAIGN_DETAILS_DATA,
    REMOVE_LEAD_DATA,
 } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, } = actionTypes
const entity = () => {
    const getAddCampaignData = (state = [], action: actions) => {
        switch (action.type) {
          case ADD_CAMPAIGN_DATA[SUCCESS]:
            return action.payload
          case ADD_CAMPAIGN_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }

      const getRemoveLeadData = (state = [], action: actions) => {
        switch (action.type) {
          case REMOVE_LEAD_DATA[SUCCESS]:
            return action.payload
          case REMOVE_LEAD_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }
    
    const getCampaignData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_CAMPAIGN_DATA[SUCCESS]:
            return action.payload
          case GET_CAMPAIGN_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }

      const getCampaignMailListData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_CAMPAIGN_MAIL_LIST_DATA[SUCCESS]:
            return action.payload
          case GET_CAMPAIGN_MAIL_LIST_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }

      const getCampaignDetailsData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_CAMPAIGN_DETAILS_DATA[SUCCESS]:
            return action.payload
          case GET_CAMPAIGN_DETAILS_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }

      const getCampaignNameData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_CAMPAIGN_NAME_DATA[SUCCESS]:
            return action.payload
          case GET_CAMPAIGN_NAME_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }
  

  return combineReducers({
    getAddCampaignData,
    getCampaignData,
    getCampaignMailListData,
    getRemoveLeadData,
    getCampaignDetailsData,
    getCampaignNameData,
  })
}

export default entity
export const getCampaign = (state: RootState) => state.entities.campaign
